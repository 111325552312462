import { useEffect, useState } from "react";

export enum LayoutSize {
  Desktop = "desktop",
  Mobile = "mobile",
}

const useWindowSize = () => {
  const [layoutSize, setLayoutSize] = useState(LayoutSize.Desktop);
  useEffect(() => {
    const handleResize = () => {
      setLayoutSize(window.innerWidth < 1024 ? LayoutSize.Mobile : LayoutSize.Desktop);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, [setLayoutSize]);

  return layoutSize;
};

export default useWindowSize;
