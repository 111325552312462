/* tslint:disable */
/* eslint-disable */
/**
 * Milliman.PullThroughPortal.WebAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import { District, DistrictFromJSON, DistrictFromJSONTyped, DistrictToJSON } from "./";

/**
 *
 * @export
 * @interface Region
 */
export interface Region {
  /**
   *
   * @type {string}
   * @memberof Region
   */
  name?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof Region
   */
  hasAccess?: boolean;
  /**
   *
   * @type {Array<District>}
   * @memberof Region
   */
  districts?: Array<District> | null;
}

export function RegionFromJSON(json: any): Region {
  return RegionFromJSONTyped(json, false);
}

export function RegionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Region {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: !exists(json, "name") ? undefined : json["name"],
    hasAccess: !exists(json, "hasAccess") ? undefined : json["hasAccess"],
    districts: !exists(json, "districts")
      ? undefined
      : json["districts"] === null
      ? null
      : (json["districts"] as Array<any>).map(DistrictFromJSON),
  };
}

export function RegionToJSON(value?: Region | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    hasAccess: value.hasAccess,
    districts:
      value.districts === undefined
        ? undefined
        : value.districts === null
        ? null
        : (value.districts as Array<any>).map(DistrictToJSON),
  };
}
