/* eslint-disable react-hooks/exhaustive-deps */
import AutorenewIcon from "@material-ui/icons/Autorenew";
import GetAppIcon from "@material-ui/icons/GetApp";
import WarningIcon from "@material-ui/icons/Warning";
import classNames from "classnames";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { usePrintZippedJobDownload, usePrintZippedJobStatus } from "../../api/usePrintZippedDocs";
import { useQueueDispatch, useQueueState } from "../../data/queue/hooks";
import { PrintJobStatus } from "../../data/queue/types";
import SnackBar, { SnackVariant } from "../SnackBar/SnackBar";

const DownloadIndicator: React.FC = () => {
  const [t] = useTranslation();
  const rootRef = useRef<null | HTMLDivElement>(null);
  const { enqueueSnackbar } = useSnackbar();
  const { printJob } = useQueueState();
  const { updatePrintJob } = useQueueDispatch();
  const [prevStatus, setPrevStatus] = useState("");
  const statusQuery = usePrintZippedJobStatus(printJob.jobID, printJob.status);
  const downloadQuery = usePrintZippedJobDownload(printJob.packageID, printJob.status);

  //Monitor jobStatus and keep redux updated
  useEffect(() => {
    const status = statusQuery.data?.state;
    const errors = statusQuery.data?.errors;
    if (prevStatus !== status && status) {
      setPrevStatus(status);
      if (status === "Succeeded") {
        updatePrintJob({
          status: PrintJobStatus.Finished,
          errorMessage: errors ? t("queue.finishedWithErrors", { errors: errors }) : null,
        });
      }
      if (status === "Failed") {
        updatePrintJob({
          status: PrintJobStatus.Error,
          errorMessage: t("queue.errorProcessing"),
        });
      }
    }
  }, [statusQuery.data, t, updatePrintJob]);

  //Once finished, get the download URL and display toast
  useEffect(() => {
    const downloadURL = downloadQuery.data;
    if (downloadURL && printJob.status === PrintJobStatus.Finished) {
      updatePrintJob({ downloadURL });
      if (printJob.errorMessage) {
        enqueueSnackbar(
          <SnackBar message={printJob.errorMessage} variant={SnackVariant.DOWNLOAD} downloadURL={downloadURL} />,
        );
      } else {
        enqueueSnackbar(
          <SnackBar message={t("queue.readyToDownload")} variant={SnackVariant.DOWNLOAD} downloadURL={downloadURL} />,
        );
      }
    }
  }, [downloadQuery.data, enqueueSnackbar, printJob.status, t, updatePrintJob, printJob.errorMessage]);

  const download = () => {
    if (!printJob.downloadURL || !rootRef.current) {
      return;
    }
    const element = document.createElement("a");
    element.setAttribute("href", printJob.downloadURL);
    element.style.display = "none";
    rootRef.current.appendChild(element);
    element.click();
    rootRef.current.removeChild(element);
    updatePrintJob({
      status: PrintJobStatus.Idle,
      jobID: undefined,
      packageID: undefined,
      errorMessage: undefined,
      downloadURL: undefined,
      printDocs: [],
    });
  };

  const rootClasses = classNames("nav-container", {
    hidden: printJob.status === PrintJobStatus.Idle,
  });

  return (
    <div ref={rootRef} className={rootClasses}>
      <div className=" print-indicator-container">
        {printJob.status === PrintJobStatus.Finished && (
          <div className="nav-container animate-pulse">
            <GetAppIcon />
          </div>
        )}
        {printJob.status === PrintJobStatus.Processing && (
          <div className="nav-container animate-spin">
            <AutorenewIcon />
          </div>
        )}
        {printJob.status === PrintJobStatus.Error && (
          <div className="nav-container">
            <WarningIcon />
          </div>
        )}
      </div>
      <div className="print-sub-nav w-full left-0 ">
        <div className="p-6 float-right inline-block bg-light-gray shadow-lg lg:ml-32 lg:mr-32">
          <p>
            {t("queue.status")} <span className="font-bold">{t(`queue.${printJob.status}`)}</span>
          </p>
          {printJob.downloadURL && (
            <button className="text-primary font-bold hover:underline pt-2 block" onClick={download}>
              {t("queue.downloadDoc")} <GetAppIcon />
            </button>
          )}
          {printJob.errorMessage && <p className="text-error font-bold pt-2 block text-xs">{printJob.errorMessage}</p>}
        </div>
      </div>
    </div>
  );
};

export default DownloadIndicator;
