/* tslint:disable */
/* eslint-disable */
/**
 * Milliman.PullThroughPortal.WebAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import { Region, RegionFromJSON, RegionFromJSONTyped, RegionToJSON } from "./";

/**
 *
 * @export
 * @interface GeographyTreeview
 */
export interface GeographyTreeview {
  /**
   *
   * @type {Array<Region>}
   * @memberof GeographyTreeview
   */
  regions?: Array<Region> | null;
}

export function GeographyTreeviewFromJSON(json: any): GeographyTreeview {
  return GeographyTreeviewFromJSONTyped(json, false);
}

export function GeographyTreeviewFromJSONTyped(json: any, ignoreDiscriminator: boolean): GeographyTreeview {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    regions: !exists(json, "regions")
      ? undefined
      : json["regions"] === null
      ? null
      : (json["regions"] as Array<any>).map(RegionFromJSON),
  };
}

export function GeographyTreeviewToJSON(value?: GeographyTreeview | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    regions:
      value.regions === undefined
        ? undefined
        : value.regions === null
        ? null
        : (value.regions as Array<any>).map(RegionToJSON),
  };
}
