import InfoOutlined from "@material-ui/icons/InfoOutlined";
import React from "react";
import { useTranslation } from "react-i18next";
import NumberFormat, { NumberFormatProps } from "react-number-format";
import ErrorDisplayTableRow from "../../components/ErrorDisplay/ErrorDisplayTableRow";
import TableHeader from "../../components/Headers/TableHeader";
import NoResultsTableRow from "../../components/NoResults/NoResultsTableRow";
import SkeletonTableBody from "../../components/Skeletons/SkeletonTableBody";
import SkeletonTableHeader from "../../components/Skeletons/SkeletonTableHeader";
import Tooltip from "../../components/Tooltip/Tooltip";
import { usePayerLandscapeState } from "../../data/payerLandscape/hooks";
import { MarketSummary } from "../../generate";

type DataKey = keyof MarketSummary;

interface Props {
  client: string;
  loading: boolean;
  marketData?: MarketSummary[] | null;
  error?: null | Error;
  displayKeys?: Array<keyof MarketSummary>;
}

const MarketSummaryTable: React.FC<Props> = ({
  client,
  marketData,
  displayKeys = ["coverageStatus", "relativeStatus", "tRx", "marketShare"],
  loading,
  error,
}) => {
  const { t } = useTranslation();
  const {
    sharedFilters: { timePeriod },
  } = usePayerLandscapeState();
  const rowHeight = 38;
  const rowCount = 4;
  const tbodyHeight = Math.ceil(rowHeight * rowCount);

  //HEADER
  const MarketSummaryHeaders = ({ marketData }: { marketData?: MarketSummary[] | null }) => {
    return (
      <thead className="bg-rules">
        <tr>
          <th className="p-3"></th>
          {marketData &&
            marketData.map((item) => (
              <th key={item.product} className="p-3">
                {item.product}
              </th>
            ))}
        </tr>
      </thead>
    );
  };

  //ROW
  const MarketSummaryRow = ({
    dataKey,
    label,
    format,
    align = "left",
  }: {
    dataKey: DataKey;
    label: string;
    format?: NumberFormatProps;
    align?: string;
  }) => (
    <tr>
      <td>
        <div className="flex-grow font-bold sm:font-normal text-center sm:text-left">{label}</div>
      </td>
      {marketData &&
        marketData.map((item) => (
          <td className={`text-${align}`} key={item.product} data-label={item.product}>
            {format && <NumberFormat value={item[dataKey]} displayType={"text"} {...format} />}
            {!format && item[dataKey]}
          </td>
        ))}
    </tr>
  );

  const THead = () => {
    if (loading) {
      return <SkeletonTableHeader columnCount={6} />;
    }
    if (error || (marketData && marketData.length === 0)) {
      return (
        <thead>
          <tr>
            <th className="p-3">
              <div className="h-5"></div>
            </th>
          </tr>
        </thead>
      );
    }
    return <MarketSummaryHeaders marketData={marketData} />;
  };

  const TBody = () => {
    if (loading) {
      return <SkeletonTableBody columnCount={6} rowCount={4} />;
    }
    if (error) {
      return <ErrorDisplayTableRow columnCount={1} height={tbodyHeight} error={error} />;
    }
    if (marketData && marketData.length === 0) {
      return <NoResultsTableRow columnCount={1} height={tbodyHeight} />;
    }
    return (
      <tbody>
        {displayKeys.includes("coverageStatus") && (
          <MarketSummaryRow dataKey={"coverageStatus"} label={t("planProfile.coverageStatus")} />
        )}
        {displayKeys.includes("relativeStatus") && (
          <MarketSummaryRow
            dataKey={"relativeStatus"}
            label={t([`planProfile.relativeStatus.${client}`, `planProfile.relativeStatus.default`])}
          />
        )}
        {displayKeys.includes("tRx") && (
          <MarketSummaryRow
            dataKey={"tRx"}
            label={t([
              `planProfile.productTrx.${timePeriod}.${client}`,
              `planProfile.productTrx.${timePeriod}.default`,
            ])}
            format={{ thousandSeparator: true }}
          />
        )}
      </tbody>
    );
  };

  return (
    <div className="pb-6">
      <TableHeader title={t("planProfile.marketSummary")}>
        <Tooltip
          placement="bottom-end"
          tip={t([`planProfile.marketSummaryTooltip.${client}`, `planProfile.marketSummaryTooltip.default`]) as string}
        >
          <InfoOutlined className="text-primary" />
        </Tooltip>
      </TableHeader>
      <table className="w-full  border-rules sm:border-2">
        <THead />
        <TBody />
      </table>
    </div>
  );
};

export default MarketSummaryTable;
