/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import ErrorDisplay from "./ErrorDisplay";

interface Props {
  height: number;
  columnCount: number;
  error: Error;
}

const ErrorDisplayTableRow: React.FC<Props> = ({ height, columnCount, error }) => {
  return (
    <tbody>
      <tr>
        <td colSpan={columnCount}>
          <div style={{ height: height }} className="flex items-center h-48 justify-center w-full">
            <ErrorDisplay error={error} />
          </div>
        </td>
      </tr>
    </tbody>
  );
};

export default ErrorDisplayTableRow;
