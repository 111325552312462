import React from "react";

interface Props {
  title: string;
  subtitle?: string;
  children?: React.ReactNode;
}

const PageHeader: React.FC<Props> = ({ title, subtitle, children }) => {
  return (
    <div className="mb-1 border-b-2 border-rules sm:flex justify-between items-center py-6 sm:py-0 sm:h-32 text-brand">
      <div>
        <h1 className="flex-grow font-bold text-2xl lg:text-4xl pt-2 pb-6 sm:pb-0">{title}</h1>
        {subtitle && <p className="pt-2 text-xl text-slate-gray">{subtitle}</p>}
      </div>
      <div>{children}</div>
    </div>
  );
};

export default PageHeader;
