import React from "react";
import { useTranslation } from "react-i18next";
import { HCPSpecificMessage } from "../../generate";
import MessagesSellSheetRow from "./MessagesSellSheetRow";
import EmailSellSheetRow from "../Email/EmailSellSheetRow";

interface Props {
  client: string;
  hcp: HCPSpecificMessage;
  isPrint: boolean;
}

const ExpandedActions: React.FC<Props> = ({ client, hcp, isPrint }) => {
  const messages = hcp.messages || [];

  const [t] = useTranslation();

  return (
    <div className="sm:p-4">
      <div className="flex">
        <div className="hidden sm:block w-56 text-xs">{t("specificMessages.actionItems")}</div>
        <div className="hidden sm:block text-xs">{t("specificMessages.sellSheets")}</div>
      </div>
      {messages.map((sellSheet, index) =>
        isPrint ? (
          <MessagesSellSheetRow key={index} client={client} hcp={hcp} sellSheet={sellSheet} />
        ) : (
          <EmailSellSheetRow key={index} client={client} hcp={hcp} sellSheet={sellSheet} />
        ),
      )}
    </div>
  );
};

export default ExpandedActions;
