/* eslint-disable  @typescript-eslint/no-explicit-any */
import { get } from "lodash";
import React from "react";
import NumberFormat from "react-number-format";
import HcpLink from "./components/HcpLink";
import HcpMessageLink from "./components/HcpMessageLink";
import HcpText from "./components/HcpText";
import HealthCareProviderName from "./components/HealthcareProviderName";
import PlanIndicator from "./components/PlanIndicator";
import { Column } from "./types";

const Components = {
  planIndicator: PlanIndicator,
  hcpLink: HcpLink,
  hcpText: HcpText,
  hcpMessageLink: HcpMessageLink,
  healthcareProviderName: HealthCareProviderName,
};
type ComponentMap = typeof Components;
export type Component = keyof ComponentMap;

export default (column: Column, data: any, role?: string | undefined) => {
  if (column.component && Components[column.component]) {
    const FcComponent: React.FC<{ column: Column; data: any; role: string | undefined }> = Components[column.component];
    return React.createElement(FcComponent, {
      key: column.dataKey,
      column,
      data: data.row,
      role,
    });
  }
  return React.createElement(
    () => (
      <>
        {column.format && (
          <NumberFormat value={get(data.row, column.dataKey)} displayType={"text"} {...column.format} />
        )}
        {!column.format && get(data.row, column.dataKey)}
      </>
    ),
    {
      key: column.dataKey,
    },
  );
};
