import React from "react";
import { Link, useParams } from "react-router-dom";
import { useClientRoutesAndLabels } from "../../../ui/routes";
import { Column } from "../types";

interface Props {
  column: Column;
  data: {
    pfz_presc_id: string;
    first_name: string;
    last_name: string;
  };
}

const HcpLink: React.FC<Props> = ({ column, data }) => {
  const { client: selectedClient } = useParams<{ client: string }>();
  const routes = useClientRoutesAndLabels(selectedClient);

  return (
    <Link
      className="font-bold text-primary hover:underline"
      to={routes.client.dpp.hcps.show.with({ client: selectedClient, id: data.pfz_presc_id })}
    >
      {data.first_name} {data.last_name}
    </Link>
  );
};

export default HcpLink;
