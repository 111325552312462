import InfoOutlined from "@material-ui/icons/InfoOutlined";
import React from "react";
import { useTranslation } from "react-i18next";
import NumberFormat, { NumberFormatProps } from "react-number-format";
import ErrorDisplay from "../../components/ErrorDisplay/ErrorDisplay";
import TableHeader from "../../components/Headers/TableHeader";
import SkeletonText from "../../components/Skeletons/SkeletonText";
import Tooltip from "../../components/Tooltip/Tooltip";
import { useClientConfiguration } from "../../data/global/useProductConfiguration";
import { usePayerLandscapeState } from "../../data/payerLandscape/hooks";
import { PlanData } from "../../generate";
interface Props {
  client: string;
  loading: boolean;
  planData?: PlanData;
  error?: Error;
}

type PlanDataKeys = keyof PlanData;

const PlanDataTable: React.FC<Props> = ({ client, loading, planData, error }) => {
  const { t } = useTranslation();
  const {
    sharedFilters: { timePeriod },
  } = usePayerLandscapeState();
  const config = useClientConfiguration(client);

  const { hidePlanDataTooltip } = useClientConfiguration(client);

  const Cell = ({
    dataKey,
    format,
    align = "left",
  }: {
    dataKey: PlanDataKeys;
    format?: NumberFormatProps;
    align?: string;
  }) => {
    if (loading) {
      return <SkeletonText align={align} />;
    }
    if (!planData || !planData[dataKey]) {
      return <span>{t("common.na")}</span>;
    }
    if (format) {
      return <NumberFormat value={planData[dataKey]} displayType={"text"} {...format} />;
    }
    return <span>{planData[dataKey]}</span>;
  };

  const PlanTable = () => (
    <div className="md:flex w-full  border-2 border-rules">
      <div
        className={
          config.hidePlanDataRight
            ? "w-full md:border-r-2 border-rules divide-y divide-rules"
            : "md:w-1/2 md:border-r-2 border-rules divide-y divide-rules"
        }
      >
        <div className="flex bg-rules">
          <div className="w-2/3 p-3 font-bold">{t("planProfile.metric")}</div>
          <div className="w-1/3 p-3 font-bold text-right">{t("planProfile.data")}</div>
        </div>
        <div className="flex">
          <div className="w-2/3 p-3">{t("planProfile.totalLives")}</div>
          <div className="w-1/3 p-3 text-right">
            <Cell dataKey="totalLives" align="right" format={{ thousandSeparator: true }} />
          </div>
        </div>
        <div className="flex">
          <div className="w-2/3 p-3">{t([`planProfile.percentBob.${client}`, `planProfile.percentBob.default`])}</div>
          <div className="w-1/3 p-3 text-right">
            <Cell
              dataKey="percentBOB"
              align="right"
              format={{ suffix: "%", decimalScale: 1, fixedDecimalScale: true }}
            />
          </div>
        </div>
        {config.hidePlanDataLastRow ? null : (
          <div className="flex">
            <div className="w-2/3 p-3">
              {t([`planProfile.noReportingHcps.${client}`, `planProfile.noReportingHcps.default`])}
            </div>
            <div className="w-1/3 p-3 text-right">
              <Cell dataKey="reportingHcps" align="right" format={{ thousandSeparator: true }} />
            </div>
          </div>
        )}
      </div>
      {!config.hidePlanDataRight && (
        <div className="md:w-1/2 divide-y divide-rules">
          <div className="hidden md:flex bg-rules">
            <div className="w-2/3 p-3 font-bold">{t("planProfile.metric")}</div>
            <div className="w-1/3 p-3 font-bold text-right">{t("planProfile.data")}</div>
          </div>
          <div className="flex">
            <div className="w-2/3 p-3">
              {t([
                `planProfile.planMarketTrx.${timePeriod}.${client}`,
                `planProfile.planMarketTrx.${timePeriod}.default`,
              ])}
            </div>
            <div className="w-1/3 p-3 text-right">
              <Cell dataKey="marketTrx" align="right" format={{ thousandSeparator: true }} />
            </div>
          </div>
          <div className="flex">
            <div className="w-2/3 p-3">
              {t(
                [
                  `planProfile.trxWithProduct.${timePeriod}.${client}`,
                  `planProfile.trxWithProduct.${timePeriod}.default`,
                ],
                { product: client },
              )}
            </div>
            <div className="w-1/3 p-3 text-right">
              <Cell dataKey="productTrx" align="right" format={{ thousandSeparator: true }} />
            </div>
          </div>
          {config.hidePlanDataLastRow ? null : (
            <div className="flex">
              <div className="w-2/3 p-3">
                {t([
                  `planProfile.marketShare.${timePeriod}.${client}`,
                  `planProfile.marketShare.${timePeriod}.default`,
                ])}
              </div>
              <div className="w-1/3 p-3 text-right">
                <Cell
                  dataKey="marketShare"
                  align="right"
                  format={{ suffix: "%", decimalScale: 1, fixedDecimalScale: true }}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );

  const DisplayError = ({ error }: { error: Error }) => (
    <div className="w-full  border-2 border-rules">
      <div className="flex">
        <div className="md:w-1/2 md:border-r-2 border-rules divide-y divide-rules flex-grow">
          <div className="flex bg-rules">
            <div className="w-2/3 p-3 font-bold">{t("planProfile.metric")}</div>
            <div className="w-1/3 p-3 font-bold text-right">{t("planProfile.data")}</div>
          </div>
        </div>
        <div className="hidden md:block md:w-1/2 md:border-r-2 border-rules divide-y divide-rules">
          <div className="flex bg-rules">
            <div className="w-2/3 p-3 font-bold">{t("planProfile.metric")}</div>
            <div className="w-1/3 p-3 font-bold text-right">{t("planProfile.data")}</div>
          </div>
        </div>
      </div>
      <div className="py-20 md:pt-3 md:pb-2">
        <ErrorDisplay error={error} />
      </div>
    </div>
  );

  return (
    <div className="pb-6">
      <TableHeader title={t("planProfile.planData")}>
        {!hidePlanDataTooltip && (
          <Tooltip
            placement="bottom-end"
            tip={t([`planProfile.planDataTooltip.${client}`, `planProfile.planDataTooltip.default`]) as string}
          >
            <InfoOutlined className="text-primary" />
          </Tooltip>
        )}
      </TableHeader>
      {error ? <DisplayError error={error} /> : <PlanTable />}
    </div>
  );
};

export default PlanDataTable;
