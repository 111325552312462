import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useParams } from "react-router-dom";
import {
  useClientHCPLandscapeConfiguration,
  useClientPayerLandscapeConfiguration,
  useClientPlanProfileConfiguration,
  useClientPrintConfiguration,
} from "../../data/global/useProductConfiguration";
import useGetRole, { GENERAL_ROLE } from "../../hooks/useGetRole";
import { useClientRoutesAndLabels } from "../../ui/routes";

interface Props {
  closeNav: () => void;
  hasHcpAccessAsFrm?: boolean;
}

const Navigation: React.FC<Props> = ({ closeNav, hasHcpAccessAsFrm }) => {
  const { t, i18n } = useTranslation();
  const { client: selectedClient } = useParams<{ client: string }>();
  const routes = useClientRoutesAndLabels(selectedClient);
  const printConfig = useClientPrintConfiguration(selectedClient);
  const planProfileConfig = useClientPlanProfileConfiguration(selectedClient);
  const HCPLandscapeConfig = useClientHCPLandscapeConfiguration(selectedClient);
  const showHCPLandscape = HCPLandscapeConfig.enabled === true || HCPLandscapeConfig.enabled === undefined;
  const payerLandscapeConfig = useClientPayerLandscapeConfiguration(selectedClient);
  const showPayerLandscape = payerLandscapeConfig.enabled === true || payerLandscapeConfig.enabled === undefined;
  const { data: role, isFetched } = useGetRole(selectedClient);
  const isGeneral = !isFetched || String(role) === GENERAL_ROLE;
  const planProfileVisible = planProfileConfig.hiddenForGeneral && isGeneral;

  return (
    <div className="lg:flex flex-grow lg:px-4 lg:space-x-3">
      {!planProfileVisible && (showPayerLandscape || showHCPLandscape) ? (
        <div className="nav-container lg:w-60">
          <NavLink
            onClick={closeNav}
            to={routes.client.dpp.with({ client: selectedClient })}
            activeClassName="active-nav"
            className="nav"
          >
            {t("common.payerPortal")}{" "}
            <span className="hidden lg:inline">
              <KeyboardArrowDownIcon />
            </span>
          </NavLink>
          <ul className="sub-nav lg:w-60">
            {showPayerLandscape && (
              <li className="body-2">
                <NavLink
                  className="block"
                  onClick={closeNav}
                  to={routes.client.dpp.payers.with({ client: selectedClient })}
                >
                  {routes.client.dpp.payers.label}
                </NavLink>
              </li>
            )}
            {showHCPLandscape && (
              <li className="body-2">
                <NavLink
                  className="block"
                  onClick={closeNav}
                  to={routes.client.dpp.hcps.with({ client: selectedClient })}
                >
                  {routes.client.dpp.hcps.label}
                </NavLink>
              </li>
            )}
          </ul>
        </div>
      ) : null}

      {(printConfig.hcpPrintMessages || printConfig.hcpEmailMessages || printConfig.resourceLibrary) &&
        (!hasHcpAccessAsFrm || printConfig.printEnabledForFRM) && (
          <div className="nav-container lg:w-72">
            <NavLink
              onClick={closeNav}
              to={routes.client.print.with({ client: selectedClient })}
              activeClassName="active-nav"
              className="nav"
            >
              {i18n.exists(`common.ccPortal.${selectedClient}`)
                ? t(`common.ccPortal.${selectedClient}`)
                : t(`common.ccPortal.default`)}
              <span className="hidden lg:inline">
                <KeyboardArrowDownIcon />
              </span>
            </NavLink>
            <ul className="sub-nav lg:w-72">
              {printConfig.hcpPrintMessages && (
                <li className="body-2">
                  <NavLink
                    className="block"
                    onClick={closeNav}
                    to={routes.client.print.messages.with({ client: selectedClient })}
                  >
                    {routes.client.print.messages.label}
                  </NavLink>
                </li>
              )}
              {printConfig.hcpEmailMessages && (
                <li className="body-2">
                  <NavLink
                    className="block"
                    onClick={closeNav}
                    to={routes.client.print.email.with({ client: selectedClient })}
                  >
                    {routes.client.print.email.label}
                  </NavLink>
                </li>
              )}
              {printConfig.resourceLibrary && (
                <li className="body-2">
                  <NavLink
                    className="block"
                    onClick={closeNav}
                    to={routes.client.print.resources.with({ client: selectedClient })}
                  >
                    {routes.client.print.resources.label}
                  </NavLink>
                </li>
              )}
            </ul>
          </div>
        )}
    </div>
  );
};

export default Navigation;
