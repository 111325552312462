/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from "react";
import { Column } from "../Table/types";
import SkeletonText from "./SkeletonText";

interface PropsWithColumns {
  columnCount?: number;
  columns: Column[];
  rowCount: number;
}

interface PropsWithColumnCount {
  columnCount: number;
  columns?: Column[];
  rowCount: number;
}

type Col = {
  align: string;
  width?: number;
};

type Props = PropsWithColumns | PropsWithColumnCount;

const SkeletonTableBody: React.FC<Props> = ({ columns, columnCount = 1, rowCount }) => {
  const cols: Col[] = columns
    ? columns
    : Array.from({ length: columnCount }, (x, i) => ({ align: "left", width: 200 }));
  const rows = Array.from({ length: rowCount }, (x, i) => i);

  return (
    <tbody>
      {rows.map((row, rowIndex) => (
        <tr key={rowIndex}>
          {cols.map((column, colIndex) => (
            <td key={colIndex} className="sm:text-left">
              <SkeletonText align={column.align} />
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );
};

export default SkeletonTableBody;
