import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import React from "react";
import { useTranslation } from "react-i18next";
import { SortDirection } from "../../generate";
import Select from "../Inputs/Select";
import { Column, Sort } from "./types";

interface Props {
  columns: Column[];
  sort: Sort;
  handleSort: (value: Sort) => void;
}

const MobileSorter: React.FC<Props> = ({ columns, sort, handleSort }) => {
  const [t] = useTranslation();
  const sortOptions = columns.map((column) => ({
    value: column.dataKey,
    label: column?.translationParams ? t(column.label, column?.translationParams) : t(column.label),
  }));

  const changeSortField = (value: string) => {
    const newSort = {
      ...sort,
      field: value,
      direction: SortDirection.Ascending,
    };
    handleSort(newSort);
  };

  const toggleSortDirection = () => {
    const direction = sort.direction === SortDirection.Ascending ? SortDirection.Descending : SortDirection.Ascending;
    const newSort = {
      ...sort,
      direction,
    };
    handleSort(newSort);
  };

  const DirectionButton = () => (
    <button className="btn btn-primary w-12" onClick={toggleSortDirection} aria-label={t("common.toggleSort")}>
      {sort.direction === SortDirection.Ascending && <ArrowDownwardIcon />}
      {sort.direction === SortDirection.Descending && <ArrowUpwardIcon />}
    </button>
  );

  return (
    <div className="pb-4 flex sm:mt-4">
      <Select
        containerClasses="border"
        label={t("common.sortBy")}
        value={sort.field}
        options={sortOptions}
        handleChange={changeSortField}
        endAdornment={<DirectionButton />}
      />
    </div>
  );
};

export default MobileSorter;
