import { makeStyles, Theme } from "@material-ui/core";
import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

const useClasses = makeStyles((theme: Theme) => {
  const secondaryColor = theme.palette.secondary.main;
  const secondaryColorDarker = theme.palette.secondary.dark;
  return {
    root: {
      background: `conic-gradient(from -50deg at 50% 50%, ${secondaryColor} 0deg, ${secondaryColorDarker} 180deg, ${secondaryColor} 360deg)`,
      overflowY: "scroll",
    },
  };
});

interface Props {
  children: React.ReactElement;
}
const AuthLayout: React.FC<Props> = ({ children }) => {
  const [t] = useTranslation();
  const { client: selectedClient } = useParams<{ client: string }>();
  const classes = useClasses();

  return (
    <div className={classes.root}>
      <Helmet>
        <title>{t("defaultLayout.appName", { product: selectedClient })}</title>
        <meta name="description" content={t("defaultLayout.appName", { product: selectedClient }) as string} />
      </Helmet>
      <div className="h-screen w-full flex justify-center sm:items-center">{children}</div>
    </div>
  );
};

export default AuthLayout;
