import * as React from "react";
import { HCPSpecificMessage } from "../../generate/models";

type Props = {
  children?: React.ReactNode;
};

type PrintMessagesState = {
  selectedHCPSpecificMessages: HCPSpecificMessage[];
  expandedHCPProvider: string | null;
};

type PrintMessagesDispatch = {
  setHCPSpecificMessages: React.Dispatch<React.SetStateAction<HCPSpecificMessage[]>>;
  setExpandedHCPProvider: React.Dispatch<React.SetStateAction<string | null>>;
};

const PrintMessagesStateContext = React.createContext<PrintMessagesState | undefined>(undefined);
const PrintMessagesDispatchContext = React.createContext<PrintMessagesDispatch | undefined>(undefined);

export const PrintMessagesProvider: React.FC<Props> = ({ children }) => {
  const [selectedHCPSpecificMessages, setHCPSpecificMessages] = React.useState<HCPSpecificMessage[]>([]);
  const [expandedHCPProvider, setExpandedHCPProvider] = React.useState<string | null>(null);

  return (
    <PrintMessagesStateContext.Provider value={{ selectedHCPSpecificMessages, expandedHCPProvider }}>
      <PrintMessagesDispatchContext.Provider value={{ setHCPSpecificMessages, setExpandedHCPProvider }}>
        {children}
      </PrintMessagesDispatchContext.Provider>
    </PrintMessagesStateContext.Provider>
  );
};

function usePrintMessagesState() {
  const context = React.useContext(PrintMessagesStateContext);
  if (context === undefined) {
    throw new Error("usePrintMessagesState must be used within a PrintMessagesProvider");
  }
  return context;
}

function usePrintMessagesDispatch() {
  const context = React.useContext(PrintMessagesDispatchContext);
  if (context === undefined) {
    throw new Error("usePrintMessagesDispatch must be used within a PrintMessagesProvider");
  }
  return context;
}

export function usePrintMessages(): [PrintMessagesState, PrintMessagesDispatch] {
  return [usePrintMessagesState(), usePrintMessagesDispatch()];
}
