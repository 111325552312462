import fileSaver from "file-saver";
import { QueryFunctionContext, useInfiniteQuery, useMutation, useQuery } from "react-query";
import { useGlobalState } from "../data/global/hooks";
import { usePayerLandscapeState } from "../data/payerLandscape/hooks";
import { ComparisonFeed, PayerApi, PayerDetailGetRequest, PlanFeed } from "../generate";
import { call } from "./api";

export const usePayerLandscapeComparison = (client: string, enabled = true) => {
  const { sharedFilters, comparisonFilters, coverageStatus, comparisonSort, detailFilters } = usePayerLandscapeState();
  const { selectedGeoName, selectedGeoType } = useGlobalState();
  const requestParams = {
    client,
    resultNumber: 200,
    ...sharedFilters,
    ...detailFilters,
    ...comparisonFilters,
    ...coverageStatus,
    level: selectedGeoType,
    geography: selectedGeoName,
    sortSortField: comparisonSort.field,
    sortSortDirection: comparisonSort.direction,
  };

  const fetch = async ({ pageParam = 0 }) => {
    const queryParams = { ...requestParams, skip: pageParam };
    const { data } = await call(PayerApi).payerComparisonGet(queryParams);
    return data;
  };

  type ApiResponse = ComparisonFeed[] | null | undefined;
  return useInfiniteQuery<ApiResponse, Error>(["comparison", requestParams], fetch, {
    enabled: Boolean(requestParams.client) && enabled,
    getNextPageParam: (lastPage, pages) =>
      lastPage && lastPage.length === 0 ? undefined : pages.length * requestParams.resultNumber,
  });
};

export const usePayerLandscapeComparisonProducts = (client: string) => {
  const { sharedFilters, comparisonFilters, coverageStatus, comparisonSort, detailFilters } = usePayerLandscapeState();
  const { selectedGeoName, selectedGeoType } = useGlobalState();
  const fetch = async () => {
    const { data } = await call(PayerApi).payerComparisonGet({
      client,
      skip: 0,
      resultNumber: 1,
      ...sharedFilters,
      ...detailFilters,
      ...comparisonFilters,
      ...coverageStatus,
      level: selectedGeoType,
      geography: selectedGeoName,
      sortSortField: comparisonSort.field,
      sortSortDirection: comparisonSort.direction,
    });
    return data;
  };

  return useQuery([`comparison-products-${detailFilters.indication}`, client], fetch, {
    enabled: Boolean(client),
    select: (data) => {
      const comparision = data ? data[0] : undefined;
      if (!comparision) {
        return [];
      }
      const { prod1, prod2, prod3, prod4, prod5, prod6 } = comparision;
      return [prod1, prod2, prod3, prod4, prod5, prod6].map((product, index) => {
        return {
          key: `prod${index + 1}`,
          name: product,
        };
      });
    },
  });
};

export const usePayerLandscapeComparisonDropdowns = (client: string) => {
  const fetch = async () => {
    const { data } = await call(PayerApi).payerComparisonDropdownsGet({ client });
    return data;
  };
  return useQuery(["comparison-dropdowns", client], fetch);
};

type ApiResponse = PlanFeed[] | null | undefined;
type ApiRequest = Omit<PayerDetailGetRequest, "resultNumber"> & { resultNumber: number };
type FetchPage = (options: Omit<QueryFunctionContext, "queryKey">) => Promise<ApiResponse>;

const usePayerLandscapeComparisonFetch = (client: string): [ApiRequest, FetchPage] => {
  const { sharedFilters, detailFilters, detailSort } = usePayerLandscapeState();
  const { selectedGeoName, selectedGeoType } = useGlobalState();
  const requestParams = {
    client,
    resultNumber: 100,
    ...sharedFilters,
    ...detailFilters,
    level: selectedGeoType,
    geography: selectedGeoName,
    sortSortField: detailSort.field,
    sortSortDirection: detailSort.direction,
  };

  const fetch = async ({ pageParam = 0 }) => {
    const queryParams = { ...requestParams, skip: pageParam };
    const { data } = await call(PayerApi).payerComparisonGet(queryParams);
    return data;
  };

  return [requestParams, fetch];
};

export const usePayerLandscapeComparisonFetchExport = (client: string, columnKeys: string[], columnNames: string[]) => {
  const [queryParams] = usePayerLandscapeComparisonFetch(client);
  return useMutation<void, Error>(async () => {
    const response = await call(PayerApi).payerComparisonExcelGetRaw({
      columnKeys,
      columnNames,
      ...queryParams,
    });
    const blob = await response.raw.blob();
    const file = new File([blob], "export.xlsx");
    fileSaver.saveAs(file);
  });
};
