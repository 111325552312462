import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { MetaDataLibrary } from "../../generate";
import { useParams } from "react-router-dom";

const LastUpdate: React.FC<MetaDataLibrary & { className?: string; isFRM?: boolean }> = ({
  className,
  ccstartdate,
  ccenddate,
  trxstartdate,
  trxenddate,
  isFRM,
}) => {
  const [t] = useTranslation();
  const format = { year: "numeric", month: "long" };
  const { client: selectedClient } = useParams<{ client: string }>();

  return (
    <p className={classNames("text-xs text-slate-gray", className)}>
      {!isFRM
        ? t([`common.lastUpdate.${selectedClient}`, `common.lastUpdate.default`], {
            ccstartdate: new Date(ccstartdate || ""),
            ccenddate: new Date(ccenddate || ""),
            trxstartdate: new Date(trxstartdate || ""),
            trxenddate: new Date(trxenddate || ""),
            formatParams: {
              ccstartdate: format,
              ccenddate: format,
              trxstartdate: format,
              trxenddate: format,
            },
          })
        : t("common.frmLastUpdate", {
            ccenddate: new Date(ccenddate || ""),
            formatParams: {
              ccstartdate: format,
              ccenddate: format,
              trxstartdate: format,
              trxenddate: format,
            },
          })}
    </p>
  );
};

export default LastUpdate;
