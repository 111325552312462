import React from "react";

interface Props {
  align: string;
  bgColor?: string;
}

const SkeletonText: React.FC<Props> = ({ align, bgColor = "bg-rules" }) => {
  const randomPercentage = () => Math.floor(Math.random() * 41) + 30 + "%";

  return (
    <div
      style={{ width: randomPercentage() }}
      className={`skeleton animate-pulse rounded h-5 float-${align} ${bgColor}`}
    ></div>
  );
};

export default SkeletonText;
