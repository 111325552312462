import { useQuery, UseQueryOptions } from "react-query";
import { PayerApi } from "../generate";
import { call } from "./api";

export const useClientDropdown = <T = Array<string> | null | undefined>(
  options?: UseQueryOptions<Array<string> | null | undefined, Error, T>,
) => {
  const fetch = async () => {
    const { data } = await call(PayerApi).payerClientDropdownGet({});
    return data;
  };
  type ApiResponse = Array<string> | null | undefined;
  return useQuery<ApiResponse, Error, T>("client-dropdowns", fetch, options);
};
