import React from "react";
import { useTranslation } from "react-i18next";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import PageHeader from "../../components/Headers/PageHeader";

const GeoLandscape: React.FC = () => {
  const [t] = useTranslation();

  return (
    <>
      <PageHeader title={t("common.geographicLandscape")} />
      <BreadCrumbs />
    </>
  );
};

export default GeoLandscape;
