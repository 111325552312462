import { useState } from "react";
import useQueryParams from "../../hooks/useQueryParams";

export interface TabProps {
  children?: React.ReactNode;
  changeTab: (newValue: string) => void;
  tabName: string;
  currentTab: string;
}

const useTabs = (initTab: string) => {
  const [, setQuery] = useQueryParams("tab", "");
  const [currentTab, setCurrentTab] = useState<string>(initTab);

  const changeTab = (newValue: string) => {
    setQuery(newValue);
    setCurrentTab(newValue);
  };

  return { currentTab, changeTab };
};

export default useTabs;
