import { isEmpty, values } from "lodash";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import { RangeType } from "../../components/Inputs/RangeInput";
import { PayerComparisonGetRequest, PayerDetailGetRequest, SortDirection } from "../../generate";
import { booleanArrayToCount } from "../../utils/booleanArrayToCount";
import { updateFilters } from "../hcpLandscape/actions";
import {
  clearComparisonFilters,
  clearDetailFilters,
  updateComparisonFilters,
  updateComparisonSort,
  updateCoverageStatus,
  updateDetailFilters,
  updateDetailSort,
  updateSharedFilters,
} from "./actions";
import { CoverageStatus, DetailFilters, PayerLandscapeState, SharedFilters } from "./types";

const sharedFilters: SharedFilters = {
  beneficiaryType: "",
  planName: "",
  timePeriod: "",
};

const detailFilters: DetailFilters = {
  product: "",
  minimumLives: undefined,
  maximumLives: undefined,
  rangeTypeLives: RangeType.GE,
  coverageStatus: "",
  relativeStatus: "",
  minimumTrx: undefined,
  maximumTrx: undefined,
  rangeTypeTrx: RangeType.GE,
  minimumMarketTrx: undefined,
  maximumMarketTrx: undefined,
  rangeTypeMarketTrx: RangeType.GE,
  minimumMarketShare: undefined,
  maximumMarketShare: undefined,
  rangeTypeMarketShare: RangeType.GE,
  minimumMonthlyDelta: undefined,
  maximumMonthlyDelta: undefined,
  rangeTypeMonthlyDelta: RangeType.GE,
};

const comparisonFilters = {
  minimumLives: undefined,
  maximumLives: undefined,
  rangeTypeLives: RangeType.GE,
  minimumTransactions: undefined,
  maximumTransactions: undefined,
  rangeTypeMarketTrx: RangeType.GE,
  coverageStatus: {},
};

export const initialState: PayerLandscapeState = {
  sharedFilters: sharedFilters,
  detailFilters: detailFilters,
  detailFilterCount: 0,
  detailSort: { field: "mkt_trx", direction: SortDirection.Descending },
  comparisonFilters: comparisonFilters,
  comparisonFilterCount: 0,
  comparisonSort: { field: "mkt_trx", direction: SortDirection.Descending },
  coverageStatus: {},
};

const getDetailFilterCount = (f: Partial<PayerDetailGetRequest>, s: SharedFilters) => {
  return booleanArrayToCount([
    s.planName,
    f.minimumLives || f.maximumLives,
    f.coverageStatus,
    f.relativeStatus,
    f.minimumTrx || f.maximumTrx,
    f.minimumMarketTrx || f.maximumMarketTrx,
    f.minimumMarketShare || f.maximumMarketShare,
    f.minimumMonthlyDelta || f.maximumMonthlyDelta,
  ]);
};

const getComparisonFilterCount = (
  comparisonFilters: Partial<PayerComparisonGetRequest>,
  coverageStatus: CoverageStatus,
  sharedFilters: SharedFilters,
) => {
  const coverageStatusValues = values(coverageStatus);
  return booleanArrayToCount([
    ...coverageStatusValues,
    comparisonFilters.planName,
    comparisonFilters.minimumLives || comparisonFilters.maximumLives,
    comparisonFilters.minimumTransactions || comparisonFilters.maximumTransactions,
    sharedFilters.planName,
  ]);
};

const reducer = reducerWithInitialState(initialState)
  .case(updateFilters, (state, { timePeriod }) => ({
    ...state,
    sharedFilters: { ...state.sharedFilters, timePeriod },
    detailFilterCount: getDetailFilterCount(state.detailFilters, { ...state.sharedFilters, timePeriod }),
    comparisonFilterCount: getComparisonFilterCount(state.comparisonFilters, state.coverageStatus, {
      ...state.sharedFilters,
      timePeriod,
    }),
  }))
  .case(updateSharedFilters, (state, filters) => ({
    ...state,
    sharedFilters: { ...state.sharedFilters, ...filters },
    detailFilterCount: getDetailFilterCount(state.detailFilters, { ...state.sharedFilters, ...filters }),
    comparisonFilterCount: getComparisonFilterCount(state.comparisonFilters, state.coverageStatus, {
      ...state.sharedFilters,
      ...filters,
    }),
  }))
  .case(updateDetailFilters, (state, filters) => ({
    ...state,
    detailFilters: { ...state.detailFilters, ...filters },
    detailFilterCount: getDetailFilterCount({ ...state.detailFilters, ...filters }, state.sharedFilters),
  }))
  .case(clearDetailFilters, (state) => {
    //Clear filters but reserve the rangeTypes
    return {
      ...state,
      sharedFilters: {
        ...state.sharedFilters,
        planName: "",
      },
      detailFilters: {
        ...detailFilters,
        rangeTypeLives: state.detailFilters.rangeTypeLives,
        rangeTypeTrx: state.detailFilters.rangeTypeTrx,
        rangeTypeMarketTrx: state.detailFilters.rangeTypeMarketTrx,
        rangeTypeMarketShare: state.detailFilters.rangeTypeMarketShare,
        rangeTypeMonthlyDelta: state.detailFilters.rangeTypeMonthlyDelta,
      },
      detailFilterCount: 0,
    };
  })
  .case(updateDetailSort, (state, detailSort) => {
    return { ...state, detailSort };
  })
  .case(updateComparisonFilters, (state, filters) => ({
    ...state,
    comparisonFilters: { ...state.comparisonFilters, ...filters },
    comparisonFilterCount: getComparisonFilterCount(
      { ...state.comparisonFilters, ...filters },
      state.coverageStatus,
      state.sharedFilters,
    ),
  }))
  .case(clearComparisonFilters, (state) => {
    //Clear filters but reserve the rangeTypes
    return {
      ...state,
      sharedFilters: {
        ...state.sharedFilters,
        planName: "",
      },
      comparisonFilters: {
        ...comparisonFilters,
        rangeTypeLives: state.comparisonFilters.rangeTypeLives,
        rangeTypeMarketTrx: state.comparisonFilters.rangeTypeMarketTrx,
      },
      comparisonFilterCount: 0,
      coverageStatus: {},
    };
  })
  .case(updateComparisonSort, (state, comparisonSort) => {
    return { ...state, comparisonSort };
  })
  .case(updateCoverageStatus, (state, coverageStatus) => {
    const newCoverageStatus = isEmpty(coverageStatus) ? {} : { ...state.coverageStatus, ...coverageStatus };
    return {
      ...state,
      coverageStatus: newCoverageStatus,
      comparisonFilterCount: getComparisonFilterCount(state.comparisonFilters, newCoverageStatus, state.sharedFilters),
    };
  });

export default reducer;
