/* tslint:disable */
/* eslint-disable */
/**
 * Milliman.PullThroughPortal.WebAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface PlanData
 */
export interface PlanData {
  /**
   *
   * @type {string}
   * @memberof PlanData
   */
  planName?: string | null;
  /**
   *
   * @type {string}
   * @memberof PlanData
   */
  totalLives?: string | null;
  /**
   *
   * @type {string}
   * @memberof PlanData
   */
  reportingHcps?: string | null;
  /**
   *
   * @type {string}
   * @memberof PlanData
   */
  marketTrx?: string | null;
  /**
   *
   * @type {string}
   * @memberof PlanData
   */
  percentBOB?: string | null;
  /**
   *
   * @type {string}
   * @memberof PlanData
   */
  productTrx?: string | null;
  /**
   *
   * @type {string}
   * @memberof PlanData
   */
  product?: string | null;
  /**
   *
   * @type {string}
   * @memberof PlanData
   */
  marketShare?: string | null;
  /**
   *
   * @type {string}
   * @memberof PlanData
   */
  pbmVendor?: string | null;
}

export function PlanDataFromJSON(json: any): PlanData {
  return PlanDataFromJSONTyped(json, false);
}

export function PlanDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlanData {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    planName: !exists(json, "planName") ? undefined : json["planName"],
    totalLives: !exists(json, "totalLives") ? undefined : json["totalLives"],
    reportingHcps: !exists(json, "reportingHcps") ? undefined : json["reportingHcps"],
    marketTrx: !exists(json, "marketTrx") ? undefined : json["marketTrx"],
    percentBOB: !exists(json, "percentBOB") ? undefined : json["percentBOB"],
    productTrx: !exists(json, "productTrx") ? undefined : json["productTrx"],
    product: !exists(json, "product") ? undefined : json["product"],
    marketShare: !exists(json, "marketShare") ? undefined : json["marketShare"],
    pbmVendor: !exists(json, "pbmVendor") ? undefined : json["pbmVendor"],
  };
}

export function PlanDataToJSON(value?: PlanData | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    planName: value.planName,
    totalLives: value.totalLives,
    reportingHcps: value.reportingHcps,
    marketTrx: value.marketTrx,
    percentBOB: value.percentBOB,
    productTrx: value.productTrx,
    product: value.product,
    marketShare: value.marketShare,
    pbmVendor: value.pbmVendor,
  };
}
