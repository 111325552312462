import React, { useEffect, useRef } from "react";
import NumberFormat from "react-number-format";
import { RangeType } from "./RangeInput";

interface Props {
  placeholder: string;
  amount: number | undefined | null;
  determineRange: (newAmount: number, rangeType?: RangeType) => void;
}
const OneInput: React.FC<Props> = ({ placeholder, amount, determineRange, ...inputProps }) => {
  //Set focus on mount
  const inputRef = useRef<null | HTMLInputElement>(null);
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  return (
    <NumberFormat
      getInputRef={inputRef}
      type="text"
      aria-label={placeholder}
      className={"w-full py-3 px-2"}
      value={amount}
      onValueChange={({ value }) => determineRange(parseFloat(value))}
      placeholder={placeholder}
      {...inputProps}
    />
  );
};

export default OneInput;
