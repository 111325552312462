/* eslint-disable @typescript-eslint/no-explicit-any */
import { useSnackbar } from "notistack";
import React from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import SnackBar, { SnackVariant } from "../components/SnackBar/SnackBar";
import { UserPreferences, UserPreferencesControllerResponse } from "../generate";
import { PermissionsApi, PermissionsPreferencesPutRequest } from "../generate/apis";
import { call } from "./api";

export const useGetPreferences = () => {
  const fetch = async () => {
    const { data } = await call(PermissionsApi).permissionsPreferencesGet({});
    return data;
  };
  return useQuery<UserPreferences | undefined, Error>(["get-preferences"], fetch);
};

export const usePutPreferences = () => {
  const [t] = useTranslation();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation<UserPreferencesControllerResponse, any, PermissionsPreferencesPutRequest>(
    async (postData) => {
      const data = await call(PermissionsApi).permissionsPreferencesPut(postData);
      return data;
    },
    {
      onMutate: (variables) => {
        //Optimistically update data
        queryClient.setQueryData("get-preferences", (old: UserPreferences | undefined) => ({
          ...old,
          ...variables,
        }));
      },
      onError: (error) => {
        enqueueSnackbar(<SnackBar message={t("userProfile.errorChangingPreferences")} variant={SnackVariant.ERROR} />);
        queryClient.invalidateQueries("get-preferences");
      },
    },
  );
};
