import React from "react";
import { useTranslation } from "react-i18next";
import ModalHeader from "../Headers/ModalHeader";

interface Props {
  title: string;
  children: React.ReactNode;
  onClear: () => void;
  onFilter: () => void;
}

const FilterModal: React.FC<Props> = ({ title, children, onClear, onFilter }) => {
  const [t] = useTranslation();

  return (
    <div className="">
      <ModalHeader title={title} />
      <div className=" max-w-7xl grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 sm:gap-6 ">{children}</div>

      <div className="flex flex-col sm:flex-row justify-end space-y-3 sm:space-y-0  sm:space-x-6 mt-3 sm:mt-6">
        <button onClick={() => onClear()} className="flex-1 lg:flex-none btn btn-rules md:w-40">
          {t("common.clearFilters")}
        </button>
        <button onClick={() => onFilter()} className="flex-1 lg:flex-none btn btn-primary md:w-40">
          {t("common.applyFilters")}
        </button>
      </div>
    </div>
  );
};

export default FilterModal;
