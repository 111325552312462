import { CircularProgress } from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = require("pdfjs-dist/build/pdf.worker.entry.js");

interface Props {
  url: string;
  isLoading: boolean;
  client: string;
}

const ScrollPdfPreview: React.FC<Props> = ({ url, isLoading, client }) => {
  const { t, i18n } = useTranslation();
  const [numPages, setNumPages] = useState<number | null>(null);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
  }

  return (
    <div className="pdf-preview-container w-full lg:min-w-840">
      {isLoading && (
        <div className="text-center">
          <p>
            {i18n.exists(`common.generatingPDF.${client}`)
              ? t(`common.generatingPDF.${client}`)
              : t(`common.generatingPDF.default`)}
          </p>
          <div className="pt-40">
            <CircularProgress size={80} />
          </div>
        </div>
      )}
      {!isLoading && (
        <Document file={url} onLoadSuccess={onDocumentLoadSuccess} renderMode="canvas">
          {Array.from(new Array(numPages), (el, index) => (
            <div className="lg:mb-10" key={`page_${index + 1}`}>
              <Page className="shadow-lg m-3" width={780} pageNumber={index + 1} />
            </div>
          ))}
        </Document>
      )}
    </div>
  );
};

export default ScrollPdfPreview;
