import { useInfiniteQuery } from "react-query";
import { useGlobalState } from "../data/global/hooks";
import { useHcpSpecificMessagingState } from "../data/hcpSpecificMessaging/hooks";
import { HCPSpecificMessage, PrintApi, PrintHCPSpecificMessagingGetRequest } from "../generate";
import { call } from "./api";

export const usePrintHCPSpecificMessagingApi = (client: string, isPrint = true) => {
  const { filters } = useHcpSpecificMessagingState();
  const { selectedGeoName } = useGlobalState();
  const resultNumber = 100;
  const requestParams: PrintHCPSpecificMessagingGetRequest = {
    client,
    resultNumber,
    isPrint,
    ...filters,
    territory: selectedGeoName ?? "",
  };

  const fetch = async ({ pageParam = 0 }) => {
    const queryParams = { ...requestParams, skip: pageParam };
    const { data } = await call(PrintApi).printHCPSpecificMessagingGet(queryParams);
    return data;
  };

  type ApiResponse = HCPSpecificMessage[] | null | undefined;
  return useInfiniteQuery<ApiResponse, Error>(["print-hcp-specific-message", requestParams], fetch, {
    enabled: Boolean(requestParams.client),
    select: (data) => {
      return {
        ...data,
        pages: data.pages.map((hcps) => {
          return hcps?.map((hcp) => {
            return {
              ...hcp,
              messages: hcp.messages?.sort((a, b) => {
                const aMessage = a.messageName || "";
                const bMessage = b.messageName || "";
                return aMessage.localeCompare(bMessage);
              }),
            };
          });
        }),
      };
    },
    getNextPageParam: (lastPage, pages) =>
      lastPage && lastPage.length < resultNumber ? undefined : pages.length * requestParams.resultNumber!,
  });
};
