import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import classNames from "classnames";
import React from "react";
import { SortDirection } from "../../generate";
import { Column, Sort } from "./types";

interface Props {
  column: Column;
  sort: Sort;
  handleSort: (value: Sort) => void;
}

const ColumnFilterButton: React.FC<Props> = ({ column, sort, handleSort }) => {
  //Determine if current column is the current sort
  const isCurrentSort = sort.field === column.dataKey;

  //Highlight button blue if modal is open
  const buttonClasses = classNames(
    "z-20 w-full flex p-3 items-center h-14 hover:bg-light-blue",
    {
      "justify-center ml-3": column.align === "center",
    },
    {
      "flex-row-reverse": column.align === "right",
    },
  );

  const makeCurrentSort = () => {
    const direction =
      isCurrentSort && sort.direction === SortDirection.Ascending ? SortDirection.Descending : SortDirection.Ascending;
    const newSort = {
      field: column.dataKey,
      direction,
    };
    handleSort(newSort);
  };

  return (
    <>
      <button className={buttonClasses} onClick={makeCurrentSort}>
        <div className={`text-${column.align}`}>
          <p>{column.label}</p>
          <p className="text-xs">{column.subLabel}</p>
        </div>
        <span className="w-6">
          {isCurrentSort && sort.direction === SortDirection.Ascending && <ArrowDropDownIcon />}
          {isCurrentSort && sort.direction === SortDirection.Descending && <ArrowDropUpIcon />}
        </span>
      </button>
    </>
  );
};

export default ColumnFilterButton;
