import React from "react";

interface Props {
  title: string;
  children?: React.ReactNode;
}

const TableHeader: React.FC<Props> = ({ title, children }) => {
  return (
    <div className="mb-1 flex justify-between items-center">
      <h3 className="text-secondary header-5-semi">{title}</h3>
      <div>{children}</div>
    </div>
  );
};

export default TableHeader;
