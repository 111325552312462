const getTenantKey = () => {
  // Determine logic to determine theme (Can't use window, should be done at build time, should probably use env vars)
  // if (window.location.host === "ccportal.pfizer.com") {
  //   return "pfizer";
  // }

  // if (window.location.pathname.includes("eli-lilly")) {
  //   return "lilly";
  // }

  return "default";
};

module.exports = getTenantKey;
