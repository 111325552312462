import { useAuth0 } from "@developertown/oidc-provider";
import React from "react";
import { useTranslation } from "react-i18next";

const NoAccess: React.FC = () => {
  const [t] = useTranslation();
  const Auth0 = useAuth0();
  return (
    <div className="text-white text-center">
      <h1 className="text-3xl font-bold mb-8">{t("noAccess.header")}</h1>
      <button
        className="border border-white rounded p-2 mt-6 hover:bg-white hover:text-primary transition duration-150"
        onClick={() => Auth0.logout({ extraQueryParams: { returnTo: window.location.origin } })}
      >
        {t("common.logout")}
      </button>
    </div>
  );
};

export default NoAccess;
