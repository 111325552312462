import React from "react";
import { useTranslation } from "react-i18next";
import ImageSearchTwoToneIcon from "@material-ui/icons/ImageSearchTwoTone";
import GeoSelect from "../GeoSelect/GeoSelect";

const SelectTerritory: React.FC<{ client: string; requireTerritory?: boolean }> = ({
  client,
  requireTerritory = true,
}) => {
  const { t, i18n } = useTranslation();

  return (
    <div className="flex md:items-center justify-center h-screen md:-mt-24">
      <div className="md:flex items-center md:space-x-4">
        <div className="text-center text-primary">
          <ImageSearchTwoToneIcon style={{ fontSize: 140 }} />
        </div>
        <div>
          <h1 className="text-3xl">
            {i18n.exists(`specificMessages.selectTerritoryFirst.${client}`)
              ? t(`specificMessages.selectTerritoryFirst.${client}`)
              : t(`specificMessages.selectTerritoryFirst.default`)}
          </h1>
          <GeoSelect client={client} requireTerritory={requireTerritory} />
        </div>
      </div>
    </div>
  );
};

export default SelectTerritory;
