import { IconButton } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import GetAppIcon from "@material-ui/icons/GetApp";
import classNames from "classnames";
import { useSnackbar } from "notistack";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useQueueDispatch } from "../../data/queue/hooks";
import { PrintJobStatus } from "../../data/queue/types";

type Props = Standard | Download;

interface Standard {
  id?: string | number;
  message: string;
  downloadURL?: never;
  variant: SnackVariant.SUCCESS | SnackVariant.ERROR;
}

interface Download extends Omit<Standard, "downloadURL" | "variant"> {
  downloadURL: string;
  variant: SnackVariant.DOWNLOAD;
}

export enum SnackVariant {
  SUCCESS = "success",
  ERROR = "error",
  DOWNLOAD = "download",
}

const iconForVariant = (variant: SnackVariant) => {
  switch (variant) {
    case SnackVariant.SUCCESS:
      return <CheckIcon className="text-white" />;
    case SnackVariant.ERROR:
      return <Close className="text-white" />;
    case SnackVariant.DOWNLOAD:
      return <GetAppIcon className="text-white" />;
    default:
      return null;
  }
};

const SnackBar: React.FC<Props> = ({ id, message, downloadURL, variant }) => {
  const [t] = useTranslation();
  const rootRef = useRef<null | HTMLDivElement>(null);
  const { closeSnackbar } = useSnackbar();
  const { updatePrintJob } = useQueueDispatch();

  const handleDismiss = () => {
    closeSnackbar(id);
  };

  const download = () => {
    if (!downloadURL || !rootRef.current) {
      return;
    }
    const element = document.createElement("a");
    element.setAttribute("href", downloadURL);
    element.style.display = "none";
    rootRef.current.appendChild(element);
    element.click();
    rootRef.current.removeChild(element);
    updatePrintJob({
      status: PrintJobStatus.Idle,
      jobID: undefined,
      packageID: undefined,
      errorMessage: undefined,
      downloadURL: undefined,
      printDocs: [],
    });
  };

  const action = () => {
    if (variant === SnackVariant.DOWNLOAD) {
      download();
    }
    handleDismiss();
  };

  const className = classNames(
    "text-white w-96 rounded shadow-lg flex justify-between items-center",
    { "bg-slate-gray": variant === SnackVariant.SUCCESS || variant === SnackVariant.DOWNLOAD },
    { "bg-error": variant === SnackVariant.ERROR },
  );

  return (
    <div ref={rootRef} className={className}>
      <div className="pl-3 py-3">
        <div>{message}</div>
        {downloadURL && (
          <button onClick={action} className="hover:underline font-bold flex items-center space-x-2">
            {t("common.download")}
          </button>
        )}
      </div>
      <IconButton title={t("common.dismiss")} aria-label={t("common.dismiss")} onClick={action}>
        {iconForVariant(variant)}
      </IconButton>
    </div>
  );
};

export default SnackBar;
