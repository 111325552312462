import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { SortDirection } from "../../generate";
import { Column, Sort } from "./types";

interface Props {
  column: Column;
  sort?: Sort;
  handleSort?: (value: Sort) => void;
}

const ColumnFilterButton: React.FC<Props> = ({ column, sort, handleSort }) => {
  const [t] = useTranslation();
  //Determine if current column is the current sort
  const isCurrentSort = sort && sort.field === column.dataKey;

  //Highlight button blue if modal is open
  const buttonClasses = classNames(
    "z-20 w-full flex p-3 items-center h-14 hover:bg-light-blue",
    {
      "justify-center ml-3": column.align === "center",
    },
    {
      "flex-row-reverse": column.align === "right",
    },
  );

  const makeCurrentSort = () => {
    /*All numbers are formatted, so we are using that to determine starting sort direction as
    we want numbers to start with greatest at top, and text to be A-Z*/
    const startDirection = column.format ? SortDirection.Descending : SortDirection.Ascending;
    const secondDirection = column.format ? SortDirection.Ascending : SortDirection.Descending;
    const direction = isCurrentSort && sort && sort.direction === startDirection ? secondDirection : startDirection;
    const newSort = {
      field: column.dataKey,
      direction,
    };
    handleSort && handleSort(newSort);
  };

  return (
    <>
      <button className={buttonClasses} disabled={!(sort && handleSort)} onClick={makeCurrentSort}>
        <div className={`text-${column.align}`}>
          <p>{column?.translationParams ? t(column.label, column?.translationParams) : t(column.label)}</p>
          {column.subLabel && <p className="text-xs">{t(column.subLabel)}</p>}
        </div>
        {sort && (
          <span className="w-6">
            {isCurrentSort && sort.direction === SortDirection.Descending && <ArrowDropDownIcon />}
            {isCurrentSort && sort.direction === SortDirection.Ascending && <ArrowDropUpIcon />}
          </span>
        )}
      </button>
    </>
  );
};

export default ColumnFilterButton;
