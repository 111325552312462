import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MuiTooltip, { TooltipProps as MuiTooltipProps } from "@material-ui/core/Tooltip";
import React from "react";

type Props = Pick<MuiTooltipProps, "placement"> & {
  children: React.ReactNode;
  tip: string;
};

const Tooltip: React.FC<Props> = ({ tip, placement, children }) => {
  const [open, setOpen] = React.useState(false);
  const handleTooltipClose = () => {
    setOpen(false);
  };
  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <MuiTooltip
        onClose={handleTooltipClose}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        arrow
        title={<span className="whitespace-pre-line">{tip}</span>}
        placement={placement}
      >
        <button aria-label={tip} onClick={handleTooltipOpen}>
          {children}
        </button>
      </MuiTooltip>
    </ClickAwayListener>
  );
};

export default Tooltip;
