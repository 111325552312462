/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store/types";
import {
  clearComparisonFilters,
  clearDetailFilters,
  updateComparisonFilters,
  updateComparisonSort,
  updateCoverageStatus,
  updateDetailFilters,
  updateDetailSort,
  updateSharedFilters,
} from "./actions";
import { PayerLandscapeDispatch, PayerLandscapeState } from "./types";

export const usePayerLandscapeState = (): PayerLandscapeState =>
  useSelector(({ payerLandscape }: AppState) => {
    return payerLandscape;
  });

export const usePayerLandscapeDispatch = (): PayerLandscapeDispatch => {
  const dispatch = useDispatch();
  return useMemo(
    (): PayerLandscapeDispatch => ({
      updateSharedFilters: (filters) => dispatch(updateSharedFilters(filters)),
      updateDetailFilters: (filters) => dispatch(updateDetailFilters(filters)),
      clearDetailFilters: () => dispatch(clearDetailFilters()),
      updateDetailSort: (sort) => dispatch(updateDetailSort(sort)),
      updateComparisonFilters: (filters) => dispatch(updateComparisonFilters(filters)),
      clearComparisonFilters: () => dispatch(clearComparisonFilters()),
      updateComparisonSort: (sort) => dispatch(updateComparisonSort(sort)),
      updateCoverageStatus: (coverageStatus) => dispatch(updateCoverageStatus(coverageStatus)),
    }),
    [dispatch],
  );
};
