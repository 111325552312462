/* tslint:disable */
/* eslint-disable */
/**
 * Milliman.PullThroughPortal.WebAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface PrescFeed
 */
export interface PrescFeed {
  /**
   *
   * @type {string}
   * @memberof PrescFeed
   */
  pfz_presc_id?: string | null;
  /**
   *
   * @type {string}
   * @memberof PrescFeed
   */
  first_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof PrescFeed
   */
  last_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof PrescFeed
   */
  product?: string | null;
  /**
   *
   * @type {string}
   * @memberof PrescFeed
   */
  client_flag?: string | null;
  /**
   *
   * @type {string}
   * @memberof PrescFeed
   */
  client?: string | null;
  /**
   *
   * @type {string}
   * @memberof PrescFeed
   */
  geography?: string | null;
  /**
   *
   * @type {string}
   * @memberof PrescFeed
   */
  level?: string | null;
  /**
   *
   * @type {string}
   * @memberof PrescFeed
   */
  channel?: string | null;
  /**
   *
   * @type {string}
   * @memberof PrescFeed
   */
  address?: string | null;
  /**
   *
   * @type {string}
   * @memberof PrescFeed
   */
  city?: string | null;
  /**
   *
   * @type {string}
   * @memberof PrescFeed
   */
  state?: string | null;
  /**
   *
   * @type {string}
   * @memberof PrescFeed
   */
  zip?: string | null;
  /**
   *
   * @type {number}
   * @memberof PrescFeed
   */
  prod_trx?: number | null;
  /**
   *
   * @type {number}
   * @memberof PrescFeed
   */
  mkt_trx?: number | null;
  /**
   *
   * @type {number}
   * @memberof PrescFeed
   */
  prod_share?: number | null;
  /**
   *
   * @type {number}
   * @memberof PrescFeed
   */
  fav_pct?: number | null;
  /**
   *
   * @type {number}
   * @memberof PrescFeed
   */
  acc_pct?: number | null;
  /**
   *
   * @type {number}
   * @memberof PrescFeed
   */
  delta_fa?: number | null;
  /**
   *
   * @type {string}
   * @memberof PrescFeed
   */
  indication?: string | null;
  /**
   *
   * @type {string}
   * @memberof PrescFeed
   */
  time_period?: string | null;
  /**
   *
   * @type {number}
   * @memberof PrescFeed
   */
  comp_prod_trx?: number | null;
}

export function PrescFeedFromJSON(json: any): PrescFeed {
  return PrescFeedFromJSONTyped(json, false);
}

export function PrescFeedFromJSONTyped(json: any, ignoreDiscriminator: boolean): PrescFeed {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    pfz_presc_id: !exists(json, "pfz_presc_id") ? undefined : json["pfz_presc_id"],
    first_name: !exists(json, "first_name") ? undefined : json["first_name"],
    last_name: !exists(json, "last_name") ? undefined : json["last_name"],
    product: !exists(json, "product") ? undefined : json["product"],
    client_flag: !exists(json, "client_flag") ? undefined : json["client_flag"],
    client: !exists(json, "client") ? undefined : json["client"],
    geography: !exists(json, "geography") ? undefined : json["geography"],
    level: !exists(json, "level") ? undefined : json["level"],
    channel: !exists(json, "channel") ? undefined : json["channel"],
    address: !exists(json, "address") ? undefined : json["address"],
    city: !exists(json, "city") ? undefined : json["city"],
    state: !exists(json, "state") ? undefined : json["state"],
    zip: !exists(json, "zip") ? undefined : json["zip"],
    prod_trx: !exists(json, "prod_trx") ? undefined : json["prod_trx"],
    mkt_trx: !exists(json, "mkt_trx") ? undefined : json["mkt_trx"],
    prod_share: !exists(json, "prod_share") ? undefined : json["prod_share"],
    fav_pct: !exists(json, "fav_pct") ? undefined : json["fav_pct"],
    acc_pct: !exists(json, "acc_pct") ? undefined : json["acc_pct"],
    delta_fa: !exists(json, "delta_fa") ? undefined : json["delta_fa"],
    indication: !exists(json, "indication") ? undefined : json["indication"],
    time_period: !exists(json, "time_period") ? undefined : json["time_period"],
    comp_prod_trx: !exists(json, "comp_prod_trx") ? undefined : json["comp_prod_trx"],
  };
}

export function PrescFeedToJSON(value?: PrescFeed | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    pfz_presc_id: value.pfz_presc_id,
    first_name: value.first_name,
    last_name: value.last_name,
    product: value.product,
    client_flag: value.client_flag,
    client: value.client,
    geography: value.geography,
    level: value.level,
    channel: value.channel,
    address: value.address,
    city: value.city,
    state: value.state,
    zip: value.zip,
    prod_trx: value.prod_trx,
    mkt_trx: value.mkt_trx,
    prod_share: value.prod_share,
    fav_pct: value.fav_pct,
    acc_pct: value.acc_pct,
    delta_fa: value.delta_fa,
    indication: value.indication,
    time_period: value.time_period,
    comp_prod_trx: value.comp_prod_trx,
  };
}
