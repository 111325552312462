import { useState } from "react";
import { useHistory } from "react-router-dom";

const getQuery = () => {
  if (typeof window !== "undefined") {
    return new URLSearchParams(window.location.search);
  }
  return new URLSearchParams();
};

const getQueryStringVal = (key: string): string | null => {
  return getQuery().get(key);
};

const useQueryParam = (key: string, defaultVal: string): [string, (val: string) => void] => {
  const history = useHistory();
  const [query, setQuery] = useState(getQueryStringVal(key) || defaultVal);
  const updateUrl = (newVal: string) => {
    setQuery(newVal);
    const query = getQuery();
    if (newVal.trim() !== "") {
      query.set(key, newVal);
    } else {
      query.delete(key);
    }
    if (typeof history !== "undefined") {
      history.push({ search: query.toString(), state: { isOnlyQueryChange: true } });
    }
  };
  return [query, updateUrl];
};

export default useQueryParam;
