import GeoSelect from "../GeoSelect/GeoSelect";
import React, { MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";

interface MessagePageSubHeaderProps {
  client: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  children?: JSX.Element | JSX.Element[];
}

const MessagePageSubHeader: React.FC<MessagePageSubHeaderProps> = ({ client, onClick, children }) => {
  const { t, i18n } = useTranslation();

  return (
    <div className="flex flex-col lg:flex-row space-y-3 lg:space-y-0 justify-between sm:items-center mb-6">
      <h2 className="w-full lg:w-auto text-lg font-semibold">
        {i18n.exists(`specificMessages.hcpSpecificMessageDatabase.${client}`)
          ? t(`specificMessages.hcpSpecificMessageDatabase.${client}`)
          : t(`specificMessages.hcpSpecificMessageDatabase.default`)}
      </h2>
      <div className="w-full lg:w-7/12 xl:w-6/12 flex flex-col lg:flex-row space-y-2 lg:space-y-0 lg:space-x-2 items-end">
        <div className="w-full">
          <GeoSelect client={client} requireTerritory={true} />
        </div>
        {children && onClick && (
          <div className="w-full lg:w-8/12">
            <button onClick={onClick} className="btn btn-primary btn-md flex-grow w-full" disabled={false}>
              {children}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default MessagePageSubHeader;
