import { ClickAwayListener } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useModal } from "./useModal";

interface Props {
  children?: React.ReactNode;
}

const Modal: React.FC<Props> = ({ children }) => {
  const [t] = useTranslation();
  const { setModal } = useModal();

  //Close modal on escape key
  useEffect(() => {
    const close = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        setModal(undefined);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, [setModal]);

  //Focus on first input/select in card window. Helps with Accessibility
  useEffect(() => {
    const select = document.querySelector("#modal-card input, #modal-card select") as HTMLElement;
    if (select) {
      select.focus();
    }
  }, []);

  return (
    <div role="presentation" className="modal-backdrop pb-8">
      <ClickAwayListener onClickAway={() => setModal(undefined)}>
        <div>
          <button
            className="modal-button pointer-events-auto"
            onClick={() => setModal(undefined)}
            title={t("common.closeModal")}
          >
            <CloseIcon fontSize="large" />
          </button>
          <div id="modal-card" aria-modal="true" className="modal-card pointer-events-auto">
            {children}
          </div>
        </div>
      </ClickAwayListener>
    </div>
  );
};

export default Modal;
