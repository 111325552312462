/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store/types";
import { addQueueItems, removeQueueItems, updatePrintJob, updateQueueItem } from "./actions";
import { QueueDispatch, QueueState } from "./types";

export const useQueueState = (): QueueState =>
  useSelector(({ queue }: AppState) => {
    return queue;
  });

export const useQueueDispatch = (): QueueDispatch => {
  const dispatch = useDispatch();
  return useMemo(
    (): QueueDispatch => ({
      addQueueItems: (data) => dispatch(addQueueItems(data)),
      updateQueueItem: (data) => dispatch(updateQueueItem(data)),
      removeQueueItems: (data) => dispatch(removeQueueItems(data)),
      updatePrintJob: (data) => dispatch(updatePrintJob(data)),
    }),
    [dispatch],
  );
};
