/* tslint:disable */
/* eslint-disable */
/**
 * Milliman.PullThroughPortal.WebAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
  /**
   *
   * @type {string}
   * @memberof ErrorResponse
   */
  readonly errorId?: string;
  /**
   *
   * @type {string}
   * @memberof ErrorResponse
   */
  message?: string | null;
}

export function ErrorResponseFromJSON(json: any): ErrorResponse {
  return ErrorResponseFromJSONTyped(json, false);
}

export function ErrorResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ErrorResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    errorId: !exists(json, "errorId") ? undefined : json["errorId"],
    message: !exists(json, "message") ? undefined : json["message"],
  };
}

export function ErrorResponseToJSON(value?: ErrorResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    message: value.message,
  };
}
