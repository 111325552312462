/* tslint:disable */
/* eslint-disable */
/**
 * Milliman.PullThroughPortal.WebAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface HCPLandscapeDropdowns
 */
export interface HCPLandscapeDropdowns {
  /**
   *
   * @type {Array<string>}
   * @memberof HCPLandscapeDropdowns
   */
  timePeriod?: Array<string> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof HCPLandscapeDropdowns
   */
  benificiaryType?: Array<string> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof HCPLandscapeDropdowns
   */
  indication?: Array<string> | null;
}

export function HCPLandscapeDropdownsFromJSON(json: any): HCPLandscapeDropdowns {
  return HCPLandscapeDropdownsFromJSONTyped(json, false);
}

export function HCPLandscapeDropdownsFromJSONTyped(json: any, ignoreDiscriminator: boolean): HCPLandscapeDropdowns {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    timePeriod: !exists(json, "timePeriod") ? undefined : json["timePeriod"],
    benificiaryType: !exists(json, "benificiaryType") ? undefined : json["benificiaryType"],
    indication: !exists(json, "indication") ? undefined : json["indication"],
  };
}

export function HCPLandscapeDropdownsToJSON(value?: HCPLandscapeDropdowns | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    timePeriod: value.timePeriod,
    benificiaryType: value.benificiaryType,
    indication: value.indication,
  };
}
