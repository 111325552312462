/* tslint:disable */
/* eslint-disable */
/**
 * Milliman.PullThroughPortal.WebAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  CoverageStatusIEnumerableControllerResponse,
  CoverageStatusIEnumerableControllerResponseFromJSON,
  CoverageStatusIEnumerableControllerResponseToJSON,
  HCPLandscapeDropdownsControllerResponse,
  HCPLandscapeDropdownsControllerResponseFromJSON,
  HCPLandscapeDropdownsControllerResponseToJSON,
  HealthcareProviderControllerResponse,
  HealthcareProviderControllerResponseFromJSON,
  HealthcareProviderControllerResponseToJSON,
  MessageIEnumerableControllerResponse,
  MessageIEnumerableControllerResponseFromJSON,
  MessageIEnumerableControllerResponseToJSON,
  PrescFeedIEnumerableControllerResponse,
  PrescFeedIEnumerableControllerResponseFromJSON,
  PrescFeedIEnumerableControllerResponseToJSON,
  PrescribingHabitsIEnumerableControllerResponse,
  PrescribingHabitsIEnumerableControllerResponseFromJSON,
  PrescribingHabitsIEnumerableControllerResponseToJSON,
  SortDirection,
  SortDirectionFromJSON,
  SortDirectionToJSON,
} from "../models";

export interface HCPDropdownsGetRequest {
  client?: string | null;
  apiVersion?: string;
}

export interface HCPHCPLandscapeExcelGetRequest {
  genericFilter?: string | null;
  name?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  clientFlag?: string | null;
  client?: string | null;
  geography?: string | null;
  level?: string | null;
  benificiaryType?: string | null;
  timePeriod?: string | null;
  trxMin?: number | null;
  trxMax?: number | null;
  marketTrxMin?: number | null;
  marketTrxMax?: number | null;
  favorableAccessPercentMin?: number | null;
  favorableAccessPercentMax?: number | null;
  favorableAccessChangePercentMin?: number | null;
  favorableAccessChangePercentMax?: number | null;
  overallAccessPercentMin?: number | null;
  overallAccessPercentMax?: number | null;
  productShareMin?: number | null;
  productShareMax?: number | null;
  indication?: string | null;
  columnKeys?: Array<string> | null;
  columnNames?: Array<string> | null;
  skip?: number;
  sortSortField?: string | null;
  sortSortDirection?: SortDirection;
  apiVersion?: string;
}

export interface HCPHCPLandscapeGetRequest {
  resultNumber: number;
  genericFilter?: string | null;
  name?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  clientFlag?: string | null;
  client?: string | null;
  geography?: string | null;
  level?: string | null;
  benificiaryType?: string | null;
  timePeriod?: string | null;
  trxMin?: number | null;
  trxMax?: number | null;
  marketTrxMin?: number | null;
  marketTrxMax?: number | null;
  favorableAccessPercentMin?: number | null;
  favorableAccessPercentMax?: number | null;
  favorableAccessChangePercentMin?: number | null;
  favorableAccessChangePercentMax?: number | null;
  overallAccessPercentMin?: number | null;
  overallAccessPercentMax?: number | null;
  productShareMin?: number | null;
  productShareMax?: number | null;
  indication?: string | null;
  skip?: number;
  sortSortField?: string | null;
  sortSortDirection?: SortDirection;
  apiVersion?: string;
}

export interface HCPHCPProfileCCMessagesGetRequest {
  iD: string;
  client: string;
  isPrint: boolean;
  apiVersion?: string;
}

export interface HCPHCPProfileCoverageStatusExcelGetRequest {
  iD: string;
  client: string;
  indication?: string | null;
  timePeriod?: string | null;
  sortSortField?: string | null;
  sortSortDirection?: SortDirection;
  columnKeys?: Array<string> | null;
  columnNames?: Array<string> | null;
  apiVersion?: string;
}

export interface HCPHCPProfileCoverageStatusGetRequest {
  iD: string;
  client: string;
  indication?: string | null;
  timePeriod?: string | null;
  sortSortField?: string | null;
  sortSortDirection?: SortDirection;
  apiVersion?: string;
}

export interface HCPHCPProfileHealthcareProviderGetRequest {
  iD: string;
  client: string;
  indication?: string | null;
  timePeriod?: string | null;
  sortSortField?: string | null;
  sortSortDirection?: SortDirection;
  apiVersion?: string;
}

export interface HCPHCPProfilePrescribingHabitsGetRequest {
  iD: string;
  client: string;
  indication?: string | null;
  timePeriod?: string | null;
  sortSortField?: string | null;
  sortSortDirection?: SortDirection;
  apiVersion?: string;
}

/**
 *
 */
export class HCPApi extends runtime.BaseAPI {
  /**
   */
  async hCPDropdownsGetRaw(
    requestParameters: HCPDropdownsGetRequest,
  ): Promise<runtime.ApiResponse<HCPLandscapeDropdownsControllerResponse>> {
    const queryParameters: any = {};

    if (requestParameters.client !== undefined) {
      queryParameters["client"] = requestParameters.client;
    }

    if (requestParameters.apiVersion !== undefined) {
      queryParameters["api-version"] = requestParameters.apiVersion;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/HCP/Dropdowns`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      HCPLandscapeDropdownsControllerResponseFromJSON(jsonValue),
    );
  }

  /**
   */
  async hCPDropdownsGet(requestParameters: HCPDropdownsGetRequest): Promise<HCPLandscapeDropdownsControllerResponse> {
    const response = await this.hCPDropdownsGetRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async hCPHCPLandscapeExcelGetRaw(
    requestParameters: HCPHCPLandscapeExcelGetRequest,
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {};

    if (requestParameters.genericFilter !== undefined) {
      queryParameters["GenericFilter"] = requestParameters.genericFilter;
    }

    if (requestParameters.name !== undefined) {
      queryParameters["Name"] = requestParameters.name;
    }

    if (requestParameters.firstName !== undefined) {
      queryParameters["FirstName"] = requestParameters.firstName;
    }

    if (requestParameters.lastName !== undefined) {
      queryParameters["LastName"] = requestParameters.lastName;
    }

    if (requestParameters.clientFlag !== undefined) {
      queryParameters["ClientFlag"] = requestParameters.clientFlag;
    }

    if (requestParameters.client !== undefined) {
      queryParameters["Client"] = requestParameters.client;
    }

    if (requestParameters.geography !== undefined) {
      queryParameters["Geography"] = requestParameters.geography;
    }

    if (requestParameters.level !== undefined) {
      queryParameters["Level"] = requestParameters.level;
    }

    if (requestParameters.benificiaryType !== undefined) {
      queryParameters["BenificiaryType"] = requestParameters.benificiaryType;
    }

    if (requestParameters.timePeriod !== undefined) {
      queryParameters["TimePeriod"] = requestParameters.timePeriod;
    }

    if (requestParameters.trxMin !== undefined) {
      queryParameters["TrxMin"] = requestParameters.trxMin;
    }

    if (requestParameters.trxMax !== undefined) {
      queryParameters["TrxMax"] = requestParameters.trxMax;
    }

    if (requestParameters.marketTrxMin !== undefined) {
      queryParameters["MarketTrxMin"] = requestParameters.marketTrxMin;
    }

    if (requestParameters.marketTrxMax !== undefined) {
      queryParameters["MarketTrxMax"] = requestParameters.marketTrxMax;
    }

    if (requestParameters.favorableAccessPercentMin !== undefined) {
      queryParameters["FavorableAccessPercentMin"] = requestParameters.favorableAccessPercentMin;
    }

    if (requestParameters.favorableAccessPercentMax !== undefined) {
      queryParameters["FavorableAccessPercentMax"] = requestParameters.favorableAccessPercentMax;
    }

    if (requestParameters.favorableAccessChangePercentMin !== undefined) {
      queryParameters["FavorableAccessChangePercentMin"] = requestParameters.favorableAccessChangePercentMin;
    }

    if (requestParameters.favorableAccessChangePercentMax !== undefined) {
      queryParameters["FavorableAccessChangePercentMax"] = requestParameters.favorableAccessChangePercentMax;
    }

    if (requestParameters.overallAccessPercentMin !== undefined) {
      queryParameters["OverallAccessPercentMin"] = requestParameters.overallAccessPercentMin;
    }

    if (requestParameters.overallAccessPercentMax !== undefined) {
      queryParameters["OverallAccessPercentMax"] = requestParameters.overallAccessPercentMax;
    }

    if (requestParameters.productShareMin !== undefined) {
      queryParameters["ProductShareMin"] = requestParameters.productShareMin;
    }

    if (requestParameters.productShareMax !== undefined) {
      queryParameters["ProductShareMax"] = requestParameters.productShareMax;
    }

    if (requestParameters.indication !== undefined) {
      queryParameters["Indication"] = requestParameters.indication;
    }

    if (requestParameters.columnKeys) {
      queryParameters["ColumnKeys"] = requestParameters.columnKeys;
    }

    if (requestParameters.columnNames) {
      queryParameters["ColumnNames"] = requestParameters.columnNames;
    }

    if (requestParameters.skip !== undefined) {
      queryParameters["Skip"] = requestParameters.skip;
    }

    if (requestParameters.sortSortField !== undefined) {
      queryParameters["Sort.SortField"] = requestParameters.sortSortField;
    }

    if (requestParameters.sortSortDirection !== undefined) {
      queryParameters["Sort.SortDirection"] = requestParameters.sortSortDirection;
    }

    if (requestParameters.apiVersion !== undefined) {
      queryParameters["api-version"] = requestParameters.apiVersion;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/HCP/HCPLandscape/Excel`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async hCPHCPLandscapeExcelGet(requestParameters: HCPHCPLandscapeExcelGetRequest): Promise<void> {
    await this.hCPHCPLandscapeExcelGetRaw(requestParameters);
  }

  /**
   */
  async hCPHCPLandscapeGetRaw(
    requestParameters: HCPHCPLandscapeGetRequest,
  ): Promise<runtime.ApiResponse<PrescFeedIEnumerableControllerResponse>> {
    if (requestParameters.resultNumber === null || requestParameters.resultNumber === undefined) {
      throw new runtime.RequiredError(
        "resultNumber",
        "Required parameter requestParameters.resultNumber was null or undefined when calling hCPHCPLandscapeGet.",
      );
    }

    const queryParameters: any = {};

    if (requestParameters.genericFilter !== undefined) {
      queryParameters["GenericFilter"] = requestParameters.genericFilter;
    }

    if (requestParameters.name !== undefined) {
      queryParameters["Name"] = requestParameters.name;
    }

    if (requestParameters.firstName !== undefined) {
      queryParameters["FirstName"] = requestParameters.firstName;
    }

    if (requestParameters.lastName !== undefined) {
      queryParameters["LastName"] = requestParameters.lastName;
    }

    if (requestParameters.clientFlag !== undefined) {
      queryParameters["ClientFlag"] = requestParameters.clientFlag;
    }

    if (requestParameters.client !== undefined) {
      queryParameters["Client"] = requestParameters.client;
    }

    if (requestParameters.geography !== undefined) {
      queryParameters["Geography"] = requestParameters.geography;
    }

    if (requestParameters.level !== undefined) {
      queryParameters["Level"] = requestParameters.level;
    }

    if (requestParameters.benificiaryType !== undefined) {
      queryParameters["BenificiaryType"] = requestParameters.benificiaryType;
    }

    if (requestParameters.timePeriod !== undefined) {
      queryParameters["TimePeriod"] = requestParameters.timePeriod;
    }

    if (requestParameters.trxMin !== undefined) {
      queryParameters["TrxMin"] = requestParameters.trxMin;
    }

    if (requestParameters.trxMax !== undefined) {
      queryParameters["TrxMax"] = requestParameters.trxMax;
    }

    if (requestParameters.marketTrxMin !== undefined) {
      queryParameters["MarketTrxMin"] = requestParameters.marketTrxMin;
    }

    if (requestParameters.marketTrxMax !== undefined) {
      queryParameters["MarketTrxMax"] = requestParameters.marketTrxMax;
    }

    if (requestParameters.favorableAccessPercentMin !== undefined) {
      queryParameters["FavorableAccessPercentMin"] = requestParameters.favorableAccessPercentMin;
    }

    if (requestParameters.favorableAccessPercentMax !== undefined) {
      queryParameters["FavorableAccessPercentMax"] = requestParameters.favorableAccessPercentMax;
    }

    if (requestParameters.favorableAccessChangePercentMin !== undefined) {
      queryParameters["FavorableAccessChangePercentMin"] = requestParameters.favorableAccessChangePercentMin;
    }

    if (requestParameters.favorableAccessChangePercentMax !== undefined) {
      queryParameters["FavorableAccessChangePercentMax"] = requestParameters.favorableAccessChangePercentMax;
    }

    if (requestParameters.overallAccessPercentMin !== undefined) {
      queryParameters["OverallAccessPercentMin"] = requestParameters.overallAccessPercentMin;
    }

    if (requestParameters.overallAccessPercentMax !== undefined) {
      queryParameters["OverallAccessPercentMax"] = requestParameters.overallAccessPercentMax;
    }

    if (requestParameters.productShareMin !== undefined) {
      queryParameters["ProductShareMin"] = requestParameters.productShareMin;
    }

    if (requestParameters.productShareMax !== undefined) {
      queryParameters["ProductShareMax"] = requestParameters.productShareMax;
    }

    if (requestParameters.indication !== undefined) {
      queryParameters["Indication"] = requestParameters.indication;
    }

    if (requestParameters.resultNumber !== undefined) {
      queryParameters["ResultNumber"] = requestParameters.resultNumber;
    }

    if (requestParameters.skip !== undefined) {
      queryParameters["Skip"] = requestParameters.skip;
    }

    if (requestParameters.sortSortField !== undefined) {
      queryParameters["Sort.SortField"] = requestParameters.sortSortField;
    }

    if (requestParameters.sortSortDirection !== undefined) {
      queryParameters["Sort.SortDirection"] = requestParameters.sortSortDirection;
    }

    if (requestParameters.apiVersion !== undefined) {
      queryParameters["api-version"] = requestParameters.apiVersion;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/HCP/HCPLandscape`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PrescFeedIEnumerableControllerResponseFromJSON(jsonValue),
    );
  }

  /**
   */
  async hCPHCPLandscapeGet(
    requestParameters: HCPHCPLandscapeGetRequest,
  ): Promise<PrescFeedIEnumerableControllerResponse> {
    const response = await this.hCPHCPLandscapeGetRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async hCPHCPProfileCCMessagesGetRaw(
    requestParameters: HCPHCPProfileCCMessagesGetRequest,
  ): Promise<runtime.ApiResponse<MessageIEnumerableControllerResponse>> {
    if (requestParameters.iD === null || requestParameters.iD === undefined) {
      throw new runtime.RequiredError(
        "iD",
        "Required parameter requestParameters.iD was null or undefined when calling hCPHCPProfileCCMessagesGet.",
      );
    }

    if (requestParameters.client === null || requestParameters.client === undefined) {
      throw new runtime.RequiredError(
        "client",
        "Required parameter requestParameters.client was null or undefined when calling hCPHCPProfileCCMessagesGet.",
      );
    }

    if (requestParameters.isPrint === null || requestParameters.isPrint === undefined) {
      throw new runtime.RequiredError(
        "isPrint",
        "Required parameter requestParameters.isPrint was null or undefined when calling hCPHCPProfileCCMessagesGet.",
      );
    }

    const queryParameters: any = {};

    if (requestParameters.iD !== undefined) {
      queryParameters["ID"] = requestParameters.iD;
    }

    if (requestParameters.client !== undefined) {
      queryParameters["Client"] = requestParameters.client;
    }

    if (requestParameters.isPrint !== undefined) {
      queryParameters["IsPrint"] = requestParameters.isPrint;
    }

    if (requestParameters.apiVersion !== undefined) {
      queryParameters["api-version"] = requestParameters.apiVersion;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/HCP/HCPProfile/CCMessages`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      MessageIEnumerableControllerResponseFromJSON(jsonValue),
    );
  }

  /**
   */
  async hCPHCPProfileCCMessagesGet(
    requestParameters: HCPHCPProfileCCMessagesGetRequest,
  ): Promise<MessageIEnumerableControllerResponse> {
    const response = await this.hCPHCPProfileCCMessagesGetRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async hCPHCPProfileCoverageStatusExcelGetRaw(
    requestParameters: HCPHCPProfileCoverageStatusExcelGetRequest,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.iD === null || requestParameters.iD === undefined) {
      throw new runtime.RequiredError(
        "iD",
        "Required parameter requestParameters.iD was null or undefined when calling hCPHCPProfileCoverageStatusExcelGet.",
      );
    }

    if (requestParameters.client === null || requestParameters.client === undefined) {
      throw new runtime.RequiredError(
        "client",
        "Required parameter requestParameters.client was null or undefined when calling hCPHCPProfileCoverageStatusExcelGet.",
      );
    }

    const queryParameters: any = {};

    if (requestParameters.iD !== undefined) {
      queryParameters["ID"] = requestParameters.iD;
    }

    if (requestParameters.client !== undefined) {
      queryParameters["Client"] = requestParameters.client;
    }

    if (requestParameters.indication !== undefined) {
      queryParameters["Indication"] = requestParameters.indication;
    }

    if (requestParameters.timePeriod !== undefined) {
      queryParameters["TimePeriod"] = requestParameters.timePeriod;
    }

    if (requestParameters.sortSortField !== undefined) {
      queryParameters["Sort.SortField"] = requestParameters.sortSortField;
    }

    if (requestParameters.sortSortDirection !== undefined) {
      queryParameters["Sort.SortDirection"] = requestParameters.sortSortDirection;
    }

    if (requestParameters.columnKeys) {
      queryParameters["ColumnKeys"] = requestParameters.columnKeys;
    }

    if (requestParameters.columnNames) {
      queryParameters["ColumnNames"] = requestParameters.columnNames;
    }

    if (requestParameters.apiVersion !== undefined) {
      queryParameters["api-version"] = requestParameters.apiVersion;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/HCP/HCPProfile/CoverageStatus/Excel`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async hCPHCPProfileCoverageStatusExcelGet(
    requestParameters: HCPHCPProfileCoverageStatusExcelGetRequest,
  ): Promise<void> {
    await this.hCPHCPProfileCoverageStatusExcelGetRaw(requestParameters);
  }

  /**
   */
  async hCPHCPProfileCoverageStatusGetRaw(
    requestParameters: HCPHCPProfileCoverageStatusGetRequest,
  ): Promise<runtime.ApiResponse<CoverageStatusIEnumerableControllerResponse>> {
    if (requestParameters.iD === null || requestParameters.iD === undefined) {
      throw new runtime.RequiredError(
        "iD",
        "Required parameter requestParameters.iD was null or undefined when calling hCPHCPProfileCoverageStatusGet.",
      );
    }

    if (requestParameters.client === null || requestParameters.client === undefined) {
      throw new runtime.RequiredError(
        "client",
        "Required parameter requestParameters.client was null or undefined when calling hCPHCPProfileCoverageStatusGet.",
      );
    }

    const queryParameters: any = {};

    if (requestParameters.iD !== undefined) {
      queryParameters["ID"] = requestParameters.iD;
    }

    if (requestParameters.client !== undefined) {
      queryParameters["Client"] = requestParameters.client;
    }

    if (requestParameters.indication !== undefined) {
      queryParameters["Indication"] = requestParameters.indication;
    }

    if (requestParameters.timePeriod !== undefined) {
      queryParameters["TimePeriod"] = requestParameters.timePeriod;
    }

    if (requestParameters.sortSortField !== undefined) {
      queryParameters["Sort.SortField"] = requestParameters.sortSortField;
    }

    if (requestParameters.sortSortDirection !== undefined) {
      queryParameters["Sort.SortDirection"] = requestParameters.sortSortDirection;
    }

    if (requestParameters.apiVersion !== undefined) {
      queryParameters["api-version"] = requestParameters.apiVersion;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/HCP/HCPProfile/CoverageStatus`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CoverageStatusIEnumerableControllerResponseFromJSON(jsonValue),
    );
  }

  /**
   */
  async hCPHCPProfileCoverageStatusGet(
    requestParameters: HCPHCPProfileCoverageStatusGetRequest,
  ): Promise<CoverageStatusIEnumerableControllerResponse> {
    const response = await this.hCPHCPProfileCoverageStatusGetRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async hCPHCPProfileHealthcareProviderGetRaw(
    requestParameters: HCPHCPProfileHealthcareProviderGetRequest,
  ): Promise<runtime.ApiResponse<HealthcareProviderControllerResponse>> {
    if (requestParameters.iD === null || requestParameters.iD === undefined) {
      throw new runtime.RequiredError(
        "iD",
        "Required parameter requestParameters.iD was null or undefined when calling hCPHCPProfileHealthcareProviderGet.",
      );
    }

    if (requestParameters.client === null || requestParameters.client === undefined) {
      throw new runtime.RequiredError(
        "client",
        "Required parameter requestParameters.client was null or undefined when calling hCPHCPProfileHealthcareProviderGet.",
      );
    }

    const queryParameters: any = {};

    if (requestParameters.iD !== undefined) {
      queryParameters["ID"] = requestParameters.iD;
    }

    if (requestParameters.client !== undefined) {
      queryParameters["Client"] = requestParameters.client;
    }

    if (requestParameters.indication !== undefined) {
      queryParameters["Indication"] = requestParameters.indication;
    }

    if (requestParameters.timePeriod !== undefined) {
      queryParameters["TimePeriod"] = requestParameters.timePeriod;
    }

    if (requestParameters.sortSortField !== undefined) {
      queryParameters["Sort.SortField"] = requestParameters.sortSortField;
    }

    if (requestParameters.sortSortDirection !== undefined) {
      queryParameters["Sort.SortDirection"] = requestParameters.sortSortDirection;
    }

    if (requestParameters.apiVersion !== undefined) {
      queryParameters["api-version"] = requestParameters.apiVersion;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/HCP/HCPProfile/HealthcareProvider`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      HealthcareProviderControllerResponseFromJSON(jsonValue),
    );
  }

  /**
   */
  async hCPHCPProfileHealthcareProviderGet(
    requestParameters: HCPHCPProfileHealthcareProviderGetRequest,
  ): Promise<HealthcareProviderControllerResponse> {
    const response = await this.hCPHCPProfileHealthcareProviderGetRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async hCPHCPProfilePrescribingHabitsGetRaw(
    requestParameters: HCPHCPProfilePrescribingHabitsGetRequest,
  ): Promise<runtime.ApiResponse<PrescribingHabitsIEnumerableControllerResponse>> {
    if (requestParameters.iD === null || requestParameters.iD === undefined) {
      throw new runtime.RequiredError(
        "iD",
        "Required parameter requestParameters.iD was null or undefined when calling hCPHCPProfilePrescribingHabitsGet.",
      );
    }

    if (requestParameters.client === null || requestParameters.client === undefined) {
      throw new runtime.RequiredError(
        "client",
        "Required parameter requestParameters.client was null or undefined when calling hCPHCPProfilePrescribingHabitsGet.",
      );
    }

    const queryParameters: any = {};

    if (requestParameters.iD !== undefined) {
      queryParameters["ID"] = requestParameters.iD;
    }

    if (requestParameters.client !== undefined) {
      queryParameters["Client"] = requestParameters.client;
    }

    if (requestParameters.indication !== undefined) {
      queryParameters["Indication"] = requestParameters.indication;
    }

    if (requestParameters.timePeriod !== undefined) {
      queryParameters["TimePeriod"] = requestParameters.timePeriod;
    }

    if (requestParameters.sortSortField !== undefined) {
      queryParameters["Sort.SortField"] = requestParameters.sortSortField;
    }

    if (requestParameters.sortSortDirection !== undefined) {
      queryParameters["Sort.SortDirection"] = requestParameters.sortSortDirection;
    }

    if (requestParameters.apiVersion !== undefined) {
      queryParameters["api-version"] = requestParameters.apiVersion;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/HCP/HCPProfile/PrescribingHabits`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PrescribingHabitsIEnumerableControllerResponseFromJSON(jsonValue),
    );
  }

  /**
   */
  async hCPHCPProfilePrescribingHabitsGet(
    requestParameters: HCPHCPProfilePrescribingHabitsGetRequest,
  ): Promise<PrescribingHabitsIEnumerableControllerResponse> {
    const response = await this.hCPHCPProfilePrescribingHabitsGetRaw(requestParameters);
    return await response.value();
  }
}
