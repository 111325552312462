import GetAppIcon from "@material-ui/icons/GetApp";
import Tooltip from "@material-ui/core/Tooltip";
import classNames from "classnames";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { pdfjs } from "react-pdf";
import { useDownloadDocument, usePrintDoc } from "../../api/usePrintDoc";
import AddToPrintQueueIcon from "../../components/Icons/AddToPrintQueueIcon";
import { QueueItem } from "../../data/queue/types";
import { useQueueActions } from "../../data/queue/useQueueActions";
import { HCPSpecificMessage, Message, PrintDoc } from "../../generate";
import SellSheetModal from "../../components/SellSheetModal/SellSheetModal";

pdfjs.GlobalWorkerOptions.workerSrc = require("pdfjs-dist/build/pdf.worker.entry.js");

interface Props {
  client: string;
  hcp: HCPSpecificMessage;
  sellSheet: Message;
}

const MessagesSellSheetModal: React.FC<Props> = ({ client, hcp, sellSheet }) => {
  const [t] = useTranslation();
  const [includePi, setIncludePi] = useState(true);
  const { addUniqueQueueItemsToQueue } = useQueueActions();

  const printDoc: PrintDoc = {
    client,
    baseDocName: sellSheet.messageName,
    prescID: hcp.prescID,
    includePI: includePi,
    isResource: false,
    isPrint: true,
  };
  const document = usePrintDoc({
    printDoc,
  });
  const { isLoading: isDownloading, mutateAsync: downloadDocument } = useDownloadDocument();

  const url = document.data && document.data.downloadURL ? document.data.downloadURL : "";
  const downloadPrintPdf = async () => {
    await downloadDocument({ printDoc, hcp, url });
  };

  const addToPrintQueue = () => {
    const queueItem: QueueItem = {
      client,
      hcp: hcp.hcpName,
      prescID: hcp.prescID,
      displayName: sellSheet.messageName ?? "",
      baseDocName: sellSheet.messageName,
      includePI: includePi,
      isResource: false,
    };
    addUniqueQueueItemsToQueue([queueItem]);
  };

  const buttonClasses = classNames("btn btn-primary mb-6 md:mb-0 w-full", {
    "opacity-50 cursor-disabled": document.isLoading,
  });

  return (
    <SellSheetModal
      client={client}
      hcp={hcp}
      sellSheet={sellSheet}
      includePi={includePi}
      onIncludePiChange={(value) => setIncludePi(value)}
      isPrint={true}
    >
      <button disabled={document.isLoading || isDownloading} className={buttonClasses} onClick={downloadPrintPdf}>
        <GetAppIcon className={classNames({ "animate-pulse": isDownloading })} />
        <span>{document.isLoading ? t("loading") : t("specificMessages.downloadPrintPdf")}</span>
      </button>
      <Tooltip disableFocusListener disableTouchListener arrow title={t("resourceLibrary.addToQueue") as string}>
        <button onClick={addToPrintQueue} aria-label={t("resourceLibrary.addToQueue")} className="btn btn-slate-gray">
          <AddToPrintQueueIcon />
        </button>
      </Tooltip>
    </SellSheetModal>
  );
};

export default MessagesSellSheetModal;
