/* tslint:disable */
/* eslint-disable */
/**
 * Milliman.PullThroughPortal.WebAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface JobStatus
 */
export interface JobStatus {
  /**
   *
   * @type {string}
   * @memberof JobStatus
   */
  state?: string | null;
  /**
   *
   * @type {string}
   * @memberof JobStatus
   */
  errors?: string | null;
}

export function JobStatusFromJSON(json: any): JobStatus {
  return JobStatusFromJSONTyped(json, false);
}

export function JobStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobStatus {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    state: !exists(json, "state") ? undefined : json["state"],
    errors: !exists(json, "errors") ? undefined : json["errors"],
  };
}

export function JobStatusToJSON(value?: JobStatus | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    state: value.state,
    errors: value.errors,
  };
}
