import PrintOutlinedIcon from "@material-ui/icons/PrintOutlined";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import { GeographyType } from "../../components/GeoSelect/GeoTypes";
import { useGlobalState } from "../../data/global/hooks";
import { usePrintMessages } from "../../data/messages/context";
import { useQueueState } from "../../data/queue/hooks";
import { QueueItem } from "../../data/queue/types";
import { useQueueActions } from "../../data/queue/useQueueActions";
import { useClientRoutesAndLabels } from "../../ui/routes";
import HcpList from "./HcpList";
import SelectTerritory from "../../components/SelectTerritory/SelectTerritory";
import MessagePageHeader from "../../components/MessagePageHeader/MessagePageHeader";
import MessagePageSubHeader from "../../components/MessagePageSubHeader/MessagePageSubHeader";

const Messages: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [{ selectedHCPSpecificMessages }, { setHCPSpecificMessages }] = usePrintMessages();
  const { addUniqueQueueItemsToQueue } = useQueueActions();
  const { queue } = useQueueState();
  const { selectedGeoType } = useGlobalState();
  const { client: selectedClient } = useParams<{ client: string }>();
  const routes = useClientRoutesAndLabels(selectedClient);

  // const prefQuery = useGetPreferences();

  const addHcpsToPrintQueue = () => {
    //Format messages for print queue
    const queueItems: QueueItem[] = [];
    selectedHCPSpecificMessages.forEach((hcp) => {
      if (!hcp.messages) {
        return;
      }
      hcp.messages.forEach((sellSheet) => {
        const showPI = true; // !prefQuery || !prefQuery.data || prefQuery.data.showPI === undefined ? true : prefQuery.data.showPI;
        queueItems.push({
          client: selectedClient ?? "",
          hcp: hcp.hcpName,
          prescID: hcp.prescID,
          displayName: sellSheet.messageName ?? "",
          baseDocName: sellSheet.messageName,
          includePI: showPI,
          isResource: false,
        });
      });
    });

    //Add to queue and clear selected
    addUniqueQueueItemsToQueue(queueItems);
    setHCPSpecificMessages([]);
  };

  if (selectedGeoType !== GeographyType.Territory) {
    return <SelectTerritory client={selectedClient} />;
  }

  return (
    <div>
      <MessagePageHeader client={selectedClient} path={routes.client.print.queue}>
        <PrintOutlinedIcon />
        <span>{t("messages.viewPrintQueueWithCount", { count: queue.length })}</span>
      </MessagePageHeader>
      <BreadCrumbs />
      <div>
        <MessagePageSubHeader client={selectedClient} onClick={addHcpsToPrintQueue}>
          <PrintOutlinedIcon />
          <span>{t("common.addToQueueWithCount", { count: selectedHCPSpecificMessages.length })}</span>
        </MessagePageSubHeader>
        <HcpList
          client={selectedClient}
          footnote={
            i18n.exists(`messages.footnote.${selectedClient}`)
              ? t(`messages.footnote.${selectedClient}`)
              : t(`messages.footnote.default`)
          }
        />
      </div>
    </div>
  );
};

export default Messages;
