import { CustomWindow } from "../env";
import { BaseAPI, Configuration, Middleware, ResponseContext } from "../generate";
import { defaultRoutes } from "../ui/routes";

type ApiConstructor<T extends BaseAPI> = new (config: Configuration) => T;

const runtime: CustomWindow = window;
const domain = runtime.env?.REACT_APP_AUTH0_DOMAIN || "not set";
const clientId = runtime.env?.REACT_APP_AUTH0_CLIENT_ID || "not set";
const key = `oidc.user:${domain}:${clientId}`;

export const unauthenticatedResponseHandlerMiddleware: Middleware = {
  post: async (context: ResponseContext): Promise<Response | void> => {
    if (context.response.status === 401) {
      sessionStorage.removeItem(key);
      window.location.href = defaultRoutes.path;
    }
  },
};

const getAccessToken = () => {
  const value = sessionStorage.getItem(key);
  const { access_token: accessToken } = value ? JSON.parse(value) : { access_token: "not set" };
  return accessToken;
};

const call = <T extends BaseAPI>(api: ApiConstructor<T>): T => {
  return new api(
    new Configuration({
      basePath: `${window.origin}/api`,
      middleware: [unauthenticatedResponseHandlerMiddleware],
      apiKey: `Bearer ${getAccessToken()}`,
    }),
  );
};

export { call };
