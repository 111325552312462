/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from "react";
import PlanIndicator from "./components/PlanIndicator";
import { Column } from "./types";

const Components = {
  planIndicator: PlanIndicator,
};
type ComponentMap = typeof Components;
export type Component = keyof ComponentMap;

export default (column: Column, data: any) => {
  if (column.component && Components[column.component]) {
    return React.createElement(Components[column.component], {
      key: column.dataKey,
      column: column,
      data: data.row,
    });
  }
  return React.createElement(() => <>{data.row[column.dataKey]}</>, {
    key: column.dataKey,
  });
};
