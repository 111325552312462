import Tooltip from "@material-ui/core/Tooltip";
import React from "react";
import { useTranslation } from "react-i18next";
import AddToPrintQueueIcon from "../../components/Icons/AddToPrintQueueIcon";
import { useModal } from "../../components/Modal/useModal";
import { QueueItem } from "../../data/queue/types";
import { useQueueActions } from "../../data/queue/useQueueActions";
import { ResourceLibrary } from "../../generate";
import ResourceModal from "./ResourceModal";

interface Props {
  client: string;
  resource: ResourceLibrary;
}

const ResourceCard: React.FC<Props> = ({ client, resource }) => {
  const [t] = useTranslation();
  const { setModal } = useModal();
  // const prefsQuery = useGetPreferences();
  const { addUniqueQueueItemsToQueue } = useQueueActions();

  const addToPrintQueue = () => {
    const queueItem: QueueItem = {
      client,
      hcp: t("common.na"),
      displayName: resource.display_name ?? "",
      baseDocName: resource.pdf_name,
      includePI: true, // prefsQuery.data?.showPI ?? true,
      isResource: true,
    };
    addUniqueQueueItemsToQueue([queueItem]);
  };

  return (
    <div>
      {/* Thumbnnail */}
      <div className="h-48 bg-steel w-full overflow-hidden border border-rules">
        {resource.pdfThumbnailUrl && <img loading="lazy" src={resource.pdfThumbnailUrl} alt={`${resource.pdf_name}`} />}
      </div>

      {/* Meta Info */}
      <div className="bg-light-gray w-full px-3 py-4 mb-px">
        <h3 className="h-6 truncate  border-rules text-slate-gray body-2">{resource.display_name}</h3>
        {/* <p className="text-slate-gray body-3">{t("resourceLibrary.expirationDate")}: NA</p> */}
      </div>

      {/* Buttons */}
      <div className="flex divide-x-2 divide-white mt-px">
        <Tooltip disableFocusListener disableTouchListener arrow title={t("resourceLibrary.addToQueue") as string}>
          <button onClick={addToPrintQueue} aria-label={t("resourceLibrary.addToQueue")} className="btn btn-slate-gray">
            <AddToPrintQueueIcon />
          </button>
        </Tooltip>
        <button
          className="flex-grow btn btn-primary"
          onClick={() => setModal(<ResourceModal resource={resource} client={client} />)}
        >
          {t("resourceLibrary.view")}
        </button>
      </div>
    </div>
  );
};

export default ResourceCard;
export {};
