import React from "react";
import SkeletonText from "./SkeletonText";

interface Props {
  columnCount: number;
}

const SkeletonTableHeader: React.FC<Props> = ({ columnCount }) => {
  const columns = Array.from({ length: columnCount }, (x, i) => i);

  return (
    <thead className="bg-rules">
      <tr>
        {columns.map((column, index) => (
          <th key={index} className="sm:text-left p-3">
            <SkeletonText align="left" bgColor="bg-steel" />
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default SkeletonTableHeader;
