import axios from "axios";
import { useSnackbar } from "notistack";
import * as yup from "yup";
import SnackBar, { SnackVariant } from "../components/SnackBar/SnackBar";
import { CustomWindow } from "../env";

const useAuth0Api = () => {
  const runtime: CustomWindow = window;
  const domain = runtime.env?.REACT_APP_AUTH0_DOMAIN || "not set";
  const clientId = runtime.env?.REACT_APP_AUTH0_CLIENT_ID || "not set";
  const { enqueueSnackbar } = useSnackbar();

  const api = axios.create({
    baseURL: `https://${domain}`,
  });

  const resetPassword = async (email: string | undefined) => {
    //Confirm a valid email is being used
    const schema = yup.string().email();
    const isValid = await schema.isValid(email);
    if (!isValid) {
      enqueueSnackbar(<SnackBar message={`"${email}" is not a valid email address`} variant={SnackVariant.ERROR} />, {
        autoHideDuration: 3000,
      });
      return;
    }

    try {
      const response = await api.post("/dbconnections/change_password", {
        client_id: clientId,
        email: email,
        connection: "Username-Password-Authentication",
      });

      //Show success message
      enqueueSnackbar(<SnackBar message={response.data} variant={SnackVariant.SUCCESS} />, {
        autoHideDuration: 3000,
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      //Show error message
      enqueueSnackbar(
        <SnackBar
          message={e.response.data.error || e.message || "Error: Could not connect to Auth0"}
          variant={SnackVariant.ERROR}
        />,
        {
          autoHideDuration: 3000,
        },
      );
      return;
    }
  };

  return { resetPassword };
};

export default useAuth0Api;
