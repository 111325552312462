/* tslint:disable */
/* eslint-disable */
/**
 * Milliman.PullThroughPortal.WebAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ResourceLibrary
 */
export interface ResourceLibrary {
  /**
   *
   * @type {string}
   * @memberof ResourceLibrary
   */
  display_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof ResourceLibrary
   */
  pdf_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof ResourceLibrary
   */
  implementation_guide?: string | null;
  /**
   *
   * @type {string}
   * @memberof ResourceLibrary
   */
  pdfThumbnailUrl?: string | null;
  /**
   *
   * @type {string}
   * @memberof ResourceLibrary
   */
  pdfUrl?: string | null;
  /**
   *
   * @type {string}
   * @memberof ResourceLibrary
   */
  docID?: string | null;
}

export function ResourceLibraryFromJSON(json: any): ResourceLibrary {
  return ResourceLibraryFromJSONTyped(json, false);
}

export function ResourceLibraryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResourceLibrary {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    display_name: !exists(json, "display_name") ? undefined : json["display_name"],
    pdf_name: !exists(json, "pdf_name") ? undefined : json["pdf_name"],
    implementation_guide: !exists(json, "implementation_guide") ? undefined : json["implementation_guide"],
    pdfThumbnailUrl: !exists(json, "pdfThumbnailUrl") ? undefined : json["pdfThumbnailUrl"],
    pdfUrl: !exists(json, "pdfUrl") ? undefined : json["pdfUrl"],
    docID: !exists(json, "docID") ? undefined : json["docID"],
  };
}

export function ResourceLibraryToJSON(value?: ResourceLibrary | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    display_name: value.display_name,
    pdf_name: value.pdf_name,
    implementation_guide: value.implementation_guide,
    pdfThumbnailUrl: value.pdfThumbnailUrl,
    pdfUrl: value.pdfUrl,
    docID: value.docID,
  };
}
