import Fuse from "fuse.js";
import { useState } from "react";

interface FuseOptions {
  keys: string[];
  returnFullListOnNoSearch?: boolean;
  isCaseSensitive?: boolean;
  includeScore?: boolean;
  shouldSort?: boolean;
  includeMatches?: boolean;
  findAllMatches?: boolean;
  minMatchCharLength?: number;
  location?: number;
  threshold?: number;
  distance?: number;
  useExtendedSearch?: boolean;
  ignoreLocation?: boolean;
  ignoreFieldNorm?: boolean;
}

type Indice = [number, number];

function useFuse<T>(list: T[], fuseOptions: FuseOptions) {
  interface FuseItem {
    item: T;
    refIndex?: number;
    score?: number;
    matches?: Match[];
  }

  interface Match {
    key: string;
    value: string;
    indices: Indice[];
  }

  const [search, setSearch] = useState("");
  const updateSearch = (value: string) => {
    setSearch(value);
  };
  let items;
  if (!search && fuseOptions.returnFullListOnNoSearch) {
    items = list.map((item) => ({ item: item }));
  } else {
    const fuse = new Fuse(list, fuseOptions);
    items = fuse.search(search);
  }
  return [search, updateSearch, items as FuseItem[]] as const;
}

export default useFuse;
