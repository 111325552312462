import React from "react";
import { FallbackProps } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import image from "../../assets/Error.png";
import { useClientRoutesAndLabels } from "../../ui/routes";

const BoundaryError: React.FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  const [t] = useTranslation();
  const history = useHistory();
  const routes = useClientRoutesAndLabels();

  return (
    <div className="lg:flex lg:h-screen items-center justify-center fixed top-0 left-0 w-full pt-12">
      <div className="flex flex-col-reverse lg:flex-row container">
        <div className="px-10 lg:w-1/2">
          <div className="lg:pt-20">
            <div className="pb-6 lg:pb-12 text-center lg:text-left">
              <h1 className="text-2xl md:text-4xl font-bold text-brand py-2">{t("errors.sorrySomethingWentWrong")}</h1>
              <p className="body-2 text-slate-gray">{t("errors.thereWasAnIssue")}</p>
              <p className="py-2 font-mono">{error?.message}</p>
            </div>
            <div className="flex flex-col space-y-2 sm:space-y-0 sm:flex-row sm:space-x-2 lg:flex-col lg:w-80 lg:space-x-0 lg:space-y-2">
              <button onClick={resetErrorBoundary} className="text-left p-3 btn-primary w-full">
                {t("errors.tryAgain")}
              </button>
              <button onClick={() => history.push(routes.path)} className="text-left p-3 btn-secondary w-full ">
                {t("errors.getHelp")}
              </button>
            </div>
          </div>
        </div>
        <div className="lg:w-1/2">
          <img className="p-10 lg:p-0" alt={t("errors.sorrySomethingWentWrong")} src={image} />
        </div>
      </div>
    </div>
  );
};

export default BoundaryError;
