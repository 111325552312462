import { get } from "lodash";
import React, { useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import { DataWindow } from "../../api/useMetaDataApi";
import {
  usePayerLandscapeComparisonDropdowns,
  usePayerLandscapeComparisonProducts,
} from "../../api/usePayerLandscapeComparisonApi";
import RangeInput from "../../components/Inputs/RangeInput";
import Select from "../../components/Inputs/Select";
import FilterModal from "../../components/Modal/FilterModal";
import { useModal } from "../../components/Modal/useModal";
import SearchBox from "../../components/SearchBox/SearchBox";
import { useClientConfiguration } from "../../data/global/useProductConfiguration";
import {
  clearComparisonFilters as clearAction,
  updateComparisonFilters as filterAction,
  updateCoverageStatus as coverageAction,
  updateSharedFilters as filterSharedAction,
} from "../../data/payerLandscape/actions";
import { usePayerLandscapeDispatch, usePayerLandscapeState } from "../../data/payerLandscape/hooks";
import payerLandscapeReducer from "../../data/payerLandscape/reducer";
import { arrayToOptions } from "../../utils/arrayToOptions";

const ProductComparisonFiltersModal: React.FC<{ client: string }> = ({ client }) => {
  const [t] = useTranslation();
  const { setModal } = useModal();
  const [refreshKey, setRefreshKey] = useState(0);

  const comparisonDropdownQuery = usePayerLandscapeComparisonDropdowns(client);
  const { data: allProducts } = usePayerLandscapeComparisonProducts(client);
  const { products: allowedProducts } = useClientConfiguration(client);
  const products = (allProducts || []).filter((product) => allowedProducts?.includes(product.name || ""));

  //Replicate redux state locally so we don't filter until we apply the filters
  const payerLandscapeState = usePayerLandscapeState();
  const { updateComparisonFilters, updateCoverageStatus, clearComparisonFilters, updateSharedFilters } =
    usePayerLandscapeDispatch();
  const [{ comparisonFilters, coverageStatus, sharedFilters }, dispatch] = useReducer(
    payerLandscapeReducer,
    payerLandscapeState,
  );

  //Clear all advanced filters and force refresh input ranges
  const clear = () => {
    dispatch(clearAction());
    clearComparisonFilters();
    setRefreshKey((key) => key + 1);
  };

  //Update redux's state with local state and close modal
  const filter = () => {
    updateComparisonFilters(comparisonFilters);
    updateCoverageStatus(coverageStatus);
    updateSharedFilters(sharedFilters);
    setModal(undefined);
  };

  return (
    <FilterModal onClear={clear} onFilter={filter} title={t("payerLandscape.productComparisonFilters")}>
      <SearchBox
        value={sharedFilters.planName}
        handleChange={(value) => dispatch(filterSharedAction({ planName: value }))}
        label={t("payerLandscape.planName")}
      />
      <RangeInput
        key={`lives-${refreshKey}`}
        range={{
          min: comparisonFilters.minimumLives,
          max: comparisonFilters.maximumLives,
          rangeType: comparisonFilters.rangeTypeLives!,
        }}
        setRange={(range) =>
          dispatch(filterAction({ minimumLives: range.min, maximumLives: range.max, rangeTypeLives: range.rangeType }))
        }
        label={t("payerLandscape.lives")}
        allowNegative={false}
      />
      <RangeInput
        key={`marketTrx-${refreshKey}`}
        range={{
          min: comparisonFilters.minimumTransactions,
          max: comparisonFilters.maximumTransactions,
          rangeType: comparisonFilters.rangeTypeMarketTrx!,
        }}
        setRange={(range) =>
          dispatch(
            filterAction({
              minimumTransactions: range.min,
              maximumTransactions: range.max,
              rangeTypeMarketTrx: range.rangeType,
            }),
          )
        }
        label={t(`payerLandscape.marketTrx.${DataWindow.twelveMonths}.${client}`)}
        allowNegative={false}
      />
      {products.map(({ key, name }) => {
        const filterKey = `${key}Status`;
        const options = get(comparisonDropdownQuery?.data, filterKey);
        const selectOptions = arrayToOptions(options);
        return (
          <Select
            key={`${key}-${refreshKey}`}
            minWidth={250}
            value={coverageStatus[filterKey]}
            handleChange={(value) => {
              dispatch(coverageAction({ [filterKey]: value }));
            }}
            id={key}
            label={t("payerLandscape.coverageStatusWithName", { name })}
            options={selectOptions}
          />
        );
      })}
    </FilterModal>
  );
};

export default ProductComparisonFiltersModal;
