import { useSnackbar } from "notistack";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import PageHeader from "../../components/Headers/PageHeader";
import ConfirmationModal from "../../components/Modal/ConfirmationModal";
import { useModal } from "../../components/Modal/useModal";
import SearchBox from "../../components/SearchBox/SearchBox";
import SnackBar, { SnackVariant } from "../../components/SnackBar/SnackBar";
import { useQueueDispatch, useQueueState } from "../../data/queue/hooks";
import { PrintJobStatus } from "../../data/queue/types";
import useFuse from "../../hooks/useFuse";
import QueueRow from "./QueueRow";

const Queue: React.FC = () => {
  const [t] = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { queue } = useQueueState();
  const { removeQueueItems, updatePrintJob } = useQueueDispatch();
  const { setModal } = useModal();
  const { client: selectedClient } = useParams<{ client: string }>();

  //Configure fuse search
  const options = {
    returnFullListOnNoSearch: true,
    includeMatches: true,
    threshold: 0.3,
    keys: ["hcp", "messageTitle"],
  };
  const [search, setSearch, filteredQueue] = useFuse(queue, options);

  const removeAll = () => {
    setModal(undefined);
    enqueueSnackbar(
      <SnackBar message={t("queue.removedWithCount", { count: queue.length })} variant={SnackVariant.SUCCESS} />,
      {
        autoHideDuration: 3000,
      },
    );
    removeQueueItems(queue);
  };

  const showRemoveAllModal = () => {
    setModal(
      <ConfirmationModal
        title="Remove All"
        confirmButtonText="Remove All"
        onConfirm={removeAll}
        confirmButtonColor="error"
      >
        <div>
          <p className="text-xl py-3">{t("queue.removeAllConfirm")}</p>
          <p className="text-sm text-slate-gray">{t("queue.removeNoUndo")}</p>
        </div>
      </ConfirmationModal>,
    );
  };

  const downloadAll = () => {
    updatePrintJob({ status: PrintJobStatus.Start, printDocs: queue, downloadURL: undefined, errorMessage: undefined });
  };

  return (
    <div>
      <PageHeader title={t("common.printQueue")}>
        <SearchBox value={search} handleChange={setSearch} />
      </PageHeader>
      <div className="sticky z-20 " style={{ top: 54 }}>
        <div className="py-3 flex items-center justify-between bg-white">
          <h2 className="text-xl">{t("queue.totalItems", { total: queue.length })}</h2>
        </div>
        <div className="flex items-center bg-rules py-2.5">
          <div className="w-5/12 pl-4">{t("queue.items")}</div>
          <div className="w-3/12">{t("queue.includePI")}</div>
        </div>
      </div>

      <div className="pb-20">
        {filteredQueue.map((row, index) => (
          <QueueRow key={index} client={selectedClient} queueItem={row.item} />
        ))}
      </div>

      {queue.length === 0 && <p className="text-center text-2xl pt-40">{t("queue.nothingInQueue")}</p>}

      <div className="fixed left-0 bottom-0 bg-white w-full">
        <div className="flex space-x-2 justify-end container px-6 py-3">
          <button disabled={queue.length === 0} onClick={showRemoveAllModal} className="btn btn-error">
            {t("queue.removeAll")}
          </button>
          <button onClick={downloadAll} disabled={queue.length === 0} className="btn btn-primary">
            {t("queue.downloadPrintAll")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Queue;
