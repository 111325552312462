import { withAuthenticationRequired } from "@developertown/oidc-provider";
import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Route, Switch } from "react-router";
import { BrowserRouter as Router } from "react-router-dom";
import ModalWrapper from "../components/Modal/ModalWrapper";
import { useModal } from "../components/Modal/useModal";
import AuthLayout from "../layouts/AuthLayout";
import NoAccess from "../pages/NoAccess/NoAccess";
import PickAClient from "../pages/PickAClient/PickAClient";
import PickAPortal from "../pages/PickAPortal/PickAPortal";
import BoundaryError from "../pages/SystemError/BoundaryError";
import DefaultLayoutRouter from "./DefaultLayoutRouter";
import { useClientRoutesAndLabels } from "./routes";
import SiteUnderMaintenance from "../pages/SiteUnderMaintenance/SiteUnderMaintenance";

const App: React.FC = () => {
  const { modal } = useModal();
  const routes = useClientRoutesAndLabels();

  return (
    <ErrorBoundary FallbackComponent={BoundaryError}>
      <Router>
        {modal && <ModalWrapper>{modal}</ModalWrapper>}
        <Switch>
          {/* AUTH LAYOUT */}
          <Route exact path={[routes.path, routes.client.path, routes.noAccess.path]}>
            <AuthLayout>
              <Switch>
                <Route path={routes.path} exact component={PickAClient} />
                <Route path={routes.noAccess.path} exact component={NoAccess} />
                <Route path={routes.siteUnderMaintenance.path} exact component={SiteUnderMaintenance} />
                <Route path={routes.client.path} exact component={PickAPortal} />
              </Switch>
            </AuthLayout>
          </Route>

          {/* DEFAULT LAYOUTS */}
          <Route path={routes.client.path}>
            <DefaultLayoutRouter />
          </Route>
        </Switch>
      </Router>
    </ErrorBoundary>
  );
};

export default withAuthenticationRequired(App);
