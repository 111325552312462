import actionCreatorFactory from "typescript-fsa";
import { Sort } from "../../components/Table/types";
import { ComparisonFilters, CoverageStatus, DetailFilters, SharedFilters } from "./types";

const createAction = actionCreatorFactory("PAYER_LANDSCAPE");

//SHARED
export const updateSharedFilters = createAction<SharedFilters>(
  "UPDATE_SHARED_FILTERS",
  (sharedFilters: SharedFilters) => {
    return sharedFilters;
  },
);

//DETAILED
export const updateDetailFilters = createAction<DetailFilters>(
  "UPDATE_DETAIL_FILTERS",
  (detailFilters: DetailFilters) => {
    return detailFilters;
  },
);

export const clearDetailFilters = createAction("CLEAR_DETAIL_FILTERS", () => {
  return;
});

export const updateDetailSort = createAction<Sort>("UPDATE_DETAIL_SORT", (sort: Sort) => {
  return sort;
});

//COMPARISON
export const updateComparisonFilters = createAction<ComparisonFilters>(
  "UPDATE_COMPARISON_FILTERS",
  (comparisonFilters: ComparisonFilters) => {
    return comparisonFilters;
  },
);

export const clearComparisonFilters = createAction("CLEAR_COMPARISON_FILTERS", () => {
  return;
});

export const updateComparisonSort = createAction<Sort>("UPDATE_COMPARISON_SORT", (sort: Sort) => {
  return sort;
});

//COVERAGE STATUS
export const updateCoverageStatus = createAction<CoverageStatus>(
  "UPDATE_COVERAGE_STATUS",
  (coverageStatus: CoverageStatus) => {
    return coverageStatus;
  },
);
