import SearchBox from "../SearchBox/SearchBox";
import { Link } from "react-router-dom";
import PageHeader from "../Headers/PageHeader";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHcpSpecificMessagingDispatch, useHcpSpecificMessagingState } from "../../data/hcpSpecificMessaging/hooks";
import { usePrintMessages } from "../../data/messages/context";
import { Route } from "static-route-paths";

interface MessagePageHeaderProps {
  client: string;
  path: Route;
  children?: JSX.Element | JSX.Element[];
  isPrint?: boolean;
}

const MessagePageHeader: React.FC<MessagePageHeaderProps> = ({ client, path, children, isPrint = true }) => {
  const [t] = useTranslation();
  const { filters } = useHcpSpecificMessagingState();
  const { updateGenericFilter } = useHcpSpecificMessagingDispatch();
  const [, { setExpandedHCPProvider }] = usePrintMessages();

  return (
    <PageHeader title={isPrint ? t("messages.costAndCoveragePrintPortal") : t("email.costAndCoverageEmailPortal")}>
      <div className="flex flex-col sm:flex-row space-y-3 sm:space-y-0 sm:space-x-3">
        <SearchBox
          value={filters.genericFilter}
          handleChange={(value) => {
            updateGenericFilter(value);
            setExpandedHCPProvider(null);
          }}
        />
        {children && (
          <Link to={path.with({ client })} className="btn btn-primary">
            {children}
          </Link>
        )}
      </div>
    </PageHeader>
  );
};

export default MessagePageHeader;
