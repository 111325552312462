import { useQuery, UseQueryOptions } from "react-query";
import { MetaDataLibrary } from "../generate";
import { MetaApi } from "../generate/apis/MetaApi";
import { call } from "./api";

export enum DataWindow {
  twelveMonths = "Last 12 months",
  sixMonths = "Last 6 months",
  threeMonths = "Last 3 months",
  yearToDate = "Year to date",
}

const useMetaDataApi = <T>(
  client: string,
  options?: UseQueryOptions<MetaDataLibrary[] | null | undefined, Error, T>,
) => {
  return useQuery<MetaDataLibrary[] | null | undefined, Error, T>(
    ["meta-data", client],
    async () => {
      const { data } = await call(MetaApi).metaDataGet({ client });
      return data;
    },
    { enabled: Boolean(client), ...options },
  );
};

export const useMetaData = (client: string, dataWindow: string | DataWindow = DataWindow.twelveMonths) => {
  return useMetaDataApi<MetaDataLibrary | undefined>(client, {
    select: (data: MetaDataLibrary[] | null | undefined) => {
      return (data || []).find((datum) => datum.time_period === dataWindow);
    },
  });
};

export const useDataWindows = (client: string) => {
  return useMetaDataApi<string[]>(client, {
    select: (data: MetaDataLibrary[] | null | undefined) => {
      return (data || []).map((datum) => datum.time_period || "").sort(sortOptions);
    },
  });
};

const sortOptions = (a: string, b: string) => {
  return a.localeCompare(b, undefined, {
    numeric: true,
    sensitivity: "base",
  });
};
