import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PhoneIcon from "@material-ui/icons/Phone";
import React from "react";
import { useParams } from "react-router-dom";
import { useHealthCareProvider } from "../../api/useHcpProfileApi";

const HCPProviderCard: React.FC = () => {
  const { client: selectedClient, id } = useParams<{ client: string; id: string }>();
  const query = useHealthCareProvider(selectedClient, id);
  return (
    <div className="flex flex-col">
      <div className="flex flex-row justify-between">
        <h2 className="text-2xl text-brand font-bold">
          {query.data?.first_name} {query.data?.last_name}
        </h2>
        <button className="text-slate-gray text-right">
          <p>{query.data?.address}</p>
          <p>
            {query.data?.city}, {query.data?.state} {query.data?.zip}
          </p>
        </button>
      </div>

      {/* Hide email/phone for now. They might want to add it to the API later */}
      <div className="hidden sm:justify-end space-x-3">
        <button className="underline text-slate-gray  hover:text-primary">
          <MailOutlineIcon style={{ fontSize: 16 }} className="mr-1 text-primary" />
          mberman@email.com
        </button>
        <button className="underline text-slate-gray hover:text-primary">
          <PhoneIcon style={{ fontSize: 16 }} className="mr-1 text-primary" />
          (317) 555-5555
        </button>
      </div>
    </div>
  );
};

export default HCPProviderCard;
