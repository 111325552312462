import { reducerWithInitialState } from "typescript-fsa-reducers";
import { updateDefaultGeo, updateGeoAccess, updateRootGeo, updateSelectedGeo, updateUserEmail } from "./actions";
import { GlobalState } from "./types";

export const initialState: GlobalState = {
  selectedGeoType: null,
  selectedGeoName: null,
  rootGeoType: null,
  rootGeoName: null,
  hasGeoAccess: true,
  defaultGeoType: null,
  defaultGeoName: null,
};

const reducer = reducerWithInitialState(initialState)
  .case(updateSelectedGeo, (state, geoPayload) => ({
    ...state,
    selectedGeoType: geoPayload.type,
    selectedGeoName: geoPayload.name,
  }))
  .case(updateRootGeo, (state, geoPayload) => ({
    ...state,
    rootGeoType: geoPayload.type,
    rootGeoName: geoPayload.name,
  }))
  .case(updateDefaultGeo, (state, geoPayload) => ({
    ...state,
    defaultGeoType: geoPayload.type,
    defaultGeoName: geoPayload.name,
  }))
  .case(updateGeoAccess, (state, access) => ({
    ...state,
    hasGeoAccess: access,
  }))
  .case(updateUserEmail, (state, email) => ({
    ...state,
    userEmail: email,
  }));

export default reducer;
