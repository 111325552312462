/* tslint:disable */
/* eslint-disable */
/**
 * Milliman.PullThroughPortal.WebAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface MarketSummary
 */
export interface MarketSummary {
  /**
   *
   * @type {string}
   * @memberof MarketSummary
   */
  planName?: string | null;
  /**
   *
   * @type {string}
   * @memberof MarketSummary
   */
  product?: string | null;
  /**
   *
   * @type {string}
   * @memberof MarketSummary
   */
  coverageStatus?: string | null;
  /**
   *
   * @type {string}
   * @memberof MarketSummary
   */
  relativeStatus?: string | null;
  /**
   *
   * @type {string}
   * @memberof MarketSummary
   */
  tRx?: string | null;
  /**
   *
   * @type {string}
   * @memberof MarketSummary
   */
  marketShare?: string | null;
  /**
   *
   * @type {string}
   * @memberof MarketSummary
   */
  prodTRx?: string | null;
  /**
   *
   * @type {string}
   * @memberof MarketSummary
   */
  marketTRx?: string | null;
}

export function MarketSummaryFromJSON(json: any): MarketSummary {
  return MarketSummaryFromJSONTyped(json, false);
}

export function MarketSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): MarketSummary {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    planName: !exists(json, "planName") ? undefined : json["planName"],
    product: !exists(json, "product") ? undefined : json["product"],
    coverageStatus: !exists(json, "coverageStatus") ? undefined : json["coverageStatus"],
    relativeStatus: !exists(json, "relativeStatus") ? undefined : json["relativeStatus"],
    tRx: !exists(json, "tRx") ? undefined : json["tRx"],
    marketShare: !exists(json, "marketShare") ? undefined : json["marketShare"],
    prodTRx: !exists(json, "prodTRx") ? undefined : json["prodTRx"],
    marketTRx: !exists(json, "marketTRx") ? undefined : json["marketTRx"],
  };
}

export function MarketSummaryToJSON(value?: MarketSummary | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    planName: value.planName,
    product: value.product,
    coverageStatus: value.coverageStatus,
    relativeStatus: value.relativeStatus,
    tRx: value.tRx,
    marketShare: value.marketShare,
    prodTRx: value.prodTRx,
    marketTRx: value.marketTRx,
  };
}
