/* tslint:disable */
/* eslint-disable */
/**
 * Milliman.PullThroughPortal.WebAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface TopHealthcareProviders
 */
export interface TopHealthcareProviders {
  /**
   *
   * @type {string}
   * @memberof TopHealthcareProviders
   */
  pfz_presc_id?: string | null;
  /**
   *
   * @type {string}
   * @memberof TopHealthcareProviders
   */
  channel?: string | null;
  /**
   *
   * @type {string}
   * @memberof TopHealthcareProviders
   */
  topHCPs?: string | null;
  /**
   *
   * @type {string}
   * @memberof TopHealthcareProviders
   */
  marketShare?: string | null;
  /**
   *
   * @type {string}
   * @memberof TopHealthcareProviders
   */
  prod_trx?: string | null;
  /**
   *
   * @type {string}
   * @memberof TopHealthcareProviders
   */
  mkt_trx?: string | null;
  /**
   *
   * @type {string}
   * @memberof TopHealthcareProviders
   */
  bob_pct_ovrl?: string | null;
  /**
   *
   * @type {string}
   * @memberof TopHealthcareProviders
   */
  fav_pct?: string | null;
  /**
   *
   * @type {string}
   * @memberof TopHealthcareProviders
   */
  acc_pct?: string | null;
  /**
   *
   * @type {string}
   * @memberof TopHealthcareProviders
   */
  prod_share?: string | null;
  /**
   *
   * @type {string}
   * @memberof TopHealthcareProviders
   */
  chnl_prod_trx?: string | null;
  /**
   *
   * @type {string}
   * @memberof TopHealthcareProviders
   */
  chnl_comp_trx?: string | null;
  /**
   *
   * @type {string}
   * @memberof TopHealthcareProviders
   */
  chnl_mkt_trx?: string | null;
  /**
   *
   * @type {string}
   * @memberof TopHealthcareProviders
   */
  chnl_prod_share?: string | null;
  /**
   *
   * @type {string}
   * @memberof TopHealthcareProviders
   */
  comp_prod_trx?: string | null;
}

export function TopHealthcareProvidersFromJSON(json: any): TopHealthcareProviders {
  return TopHealthcareProvidersFromJSONTyped(json, false);
}

export function TopHealthcareProvidersFromJSONTyped(json: any, ignoreDiscriminator: boolean): TopHealthcareProviders {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    pfz_presc_id: !exists(json, "pfz_presc_id") ? undefined : json["pfz_presc_id"],
    channel: !exists(json, "channel") ? undefined : json["channel"],
    topHCPs: !exists(json, "topHCPs") ? undefined : json["topHCPs"],
    marketShare: !exists(json, "marketShare") ? undefined : json["marketShare"],
    prod_trx: !exists(json, "prod_trx") ? undefined : json["prod_trx"],
    mkt_trx: !exists(json, "mkt_trx") ? undefined : json["mkt_trx"],
    bob_pct_ovrl: !exists(json, "bob_pct_ovrl") ? undefined : json["bob_pct_ovrl"],
    fav_pct: !exists(json, "fav_pct") ? undefined : json["fav_pct"],
    acc_pct: !exists(json, "acc_pct") ? undefined : json["acc_pct"],
    prod_share: !exists(json, "prod_share") ? undefined : json["prod_share"],
    chnl_prod_trx: !exists(json, "chnl_prod_trx") ? undefined : json["chnl_prod_trx"],
    chnl_comp_trx: !exists(json, "chnl_comp_trx") ? undefined : json["chnl_comp_trx"],
    chnl_mkt_trx: !exists(json, "chnl_mkt_trx") ? undefined : json["chnl_mkt_trx"],
    chnl_prod_share: !exists(json, "chnl_prod_share") ? undefined : json["chnl_prod_share"],
    comp_prod_trx: !exists(json, "comp_prod_trx") ? undefined : json["comp_prod_trx"],
  };
}

export function TopHealthcareProvidersToJSON(value?: TopHealthcareProviders | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    pfz_presc_id: value.pfz_presc_id,
    channel: value.channel,
    topHCPs: value.topHCPs,
    marketShare: value.marketShare,
    prod_trx: value.prod_trx,
    mkt_trx: value.mkt_trx,
    bob_pct_ovrl: value.bob_pct_ovrl,
    fav_pct: value.fav_pct,
    acc_pct: value.acc_pct,
    prod_share: value.prod_share,
    chnl_prod_trx: value.chnl_prod_trx,
    chnl_comp_trx: value.chnl_comp_trx,
    chnl_mkt_trx: value.chnl_mkt_trx,
    chnl_prod_share: value.chnl_prod_share,
    comp_prod_trx: value.comp_prod_trx,
  };
}
