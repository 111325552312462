import fileSaver from "file-saver";
import { QueryFunctionContext, useInfiniteQuery, useMutation, useQuery, UseQueryOptions } from "react-query";
import { useGlobalState } from "../data/global/hooks";
import { usePayerLandscapeState } from "../data/payerLandscape/hooks";
import { DetailDropdowns, PayerApi, PayerDetailGetRequest, PlanFeed } from "../generate";
import { call } from "./api";

type ApiResponse = PlanFeed[] | null | undefined;
type ApiRequest = Omit<PayerDetailGetRequest, "resultNumber"> & { resultNumber: number };
type FetchPage = (options: Omit<QueryFunctionContext, "queryKey">) => Promise<ApiResponse>;

const usePayerLandscapeDetailFetch = (client: string): [ApiRequest, FetchPage] => {
  const { sharedFilters, detailFilters, detailSort } = usePayerLandscapeState();
  const { selectedGeoName, selectedGeoType } = useGlobalState();
  const requestParams = {
    client,
    resultNumber: 100,
    ...sharedFilters,
    ...detailFilters,
    level: selectedGeoType,
    geography: selectedGeoName,
    sortSortField: detailSort.field,
    sortSortDirection: detailSort.direction,
  };

  const fetch = async ({ pageParam = 0 }) => {
    const queryParams = { ...requestParams, skip: pageParam };
    const { data } = await call(PayerApi).payerDetailGet(queryParams);
    return data;
  };

  return [requestParams, fetch];
};

export const usePayerLandscapeDetail = (client: string) => {
  const [requestParams, fetch] = usePayerLandscapeDetailFetch(client);
  return useInfiniteQuery<ApiResponse, Error>(["payer-detail", requestParams], fetch, {
    enabled:
      Boolean(requestParams.client) &&
      Boolean(requestParams.level) &&
      Boolean(requestParams.geography) &&
      Boolean(requestParams.product),
    getNextPageParam: (lastPage, pages) =>
      lastPage && lastPage.length === 0 ? undefined : pages.length * requestParams.resultNumber,
  });
};

export const usePayerLandscapeDetailDetailExport = (client: string, columnKeys: string[], columnNames: string[]) => {
  const [queryParams] = usePayerLandscapeDetailFetch(client);
  return useMutation<void, Error>(async () => {
    const response = await call(PayerApi).payerDetailExcelGetRaw({
      columnKeys,
      columnNames,
      ...queryParams,
    });
    const blob = await response.raw.blob();
    const file = new File([blob], "export.xlsx");
    fileSaver.saveAs(file);
  });
};

export const usePayerLandscapeDetailDropdowns = <T = DetailDropdowns | undefined>(
  client: string,
  options?: UseQueryOptions<DetailDropdowns | undefined, Error, T>,
) => {
  const fetch = async () => {
    const { data } = await call(PayerApi).payerDetailDropdownsGet({ client });
    return data;
  };
  type ApiResponse = DetailDropdowns | undefined;
  return useQuery<ApiResponse, Error, T>(["payer-dropdowns", client], fetch, options);
};
