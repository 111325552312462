/* tslint:disable */
/* eslint-disable */
/**
 * Milliman.PullThroughPortal.WebAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface PrintDocInfo
 */
export interface PrintDocInfo {
  /**
   *
   * @type {string}
   * @memberof PrintDocInfo
   */
  downloadURL?: string | null;
  /**
   *
   * @type {string}
   * @memberof PrintDocInfo
   */
  docID?: string | null;
}

export function PrintDocInfoFromJSON(json: any): PrintDocInfo {
  return PrintDocInfoFromJSONTyped(json, false);
}

export function PrintDocInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PrintDocInfo {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    downloadURL: !exists(json, "downloadURL") ? undefined : json["downloadURL"],
    docID: !exists(json, "docID") ? undefined : json["docID"],
  };
}

export function PrintDocInfoToJSON(value?: PrintDocInfo | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    downloadURL: value.downloadURL,
    docID: value.docID,
  };
}
