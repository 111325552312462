import React from "react";
import { Column } from "../types";

interface Props {
  column: Column;
  data: {
    first_name: string;
    last_name: string;
  };
}

const HcpText: React.FC<Props> = ({ column, data }) => {
  return (
    <p className="font-bold text-primary">
      {data.first_name} {data.last_name}
    </p>
  );
};

export default HcpText;
