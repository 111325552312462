import KeyboardArrowDownOutlinedIcon from "@material-ui/icons/KeyboardArrowDownOutlined";
import React from "react";
import { useTranslation } from "react-i18next";

interface Props extends Omit<React.SelectHTMLAttributes<HTMLSelectElement>, "value"> {
  label?: string;
  value?: string | null;
  handleChange: (value: string) => void;
  options: Option[];
  minWidth?: number;
  containerClasses?: string;
  endAdornment?: React.ReactElement;
}

interface Option {
  value: string;
  label: string;
  disabled?: boolean;
}

const SelectFilter: React.FC<Props> = ({
  value,
  label,
  options,
  handleChange,
  minWidth,
  containerClasses,
  endAdornment,
  ...selectProps
}) => {
  const [t] = useTranslation();
  const selectValue = value === null ? undefined : value;
  return (
    <div style={{ minWidth: minWidth }} className="flex-1">
      <label htmlFor={label} className="body-3">
        {label}
      </label>
      <div className="flex my-2">
        <div className={`flex-grow input-container my-0 ${containerClasses}`}>
          <button tabIndex={-1} className="select-arrow" title={t("common.selectDropdownArrow")}>
            <KeyboardArrowDownOutlinedIcon />
          </button>
          <select
            value={selectValue}
            aria-label={label}
            id={label}
            className=" py-4 w-full float-left block focus:border-b focus:border-primary"
            onBlur={(e) => handleChange(e.target.value)}
            onChange={(e) => handleChange(e.target.value)}
            {...selectProps}
          >
            {options.map((option) => (
              <option key={option.value} value={option.value} disabled={option.disabled}>
                &nbsp;{option.label}
              </option>
            ))}
          </select>
        </div>
        {endAdornment && endAdornment}
      </div>
    </div>
  );
};

export default SelectFilter;
