import { useQuery } from "react-query";
import { ResourceLibrary } from "../generate";
import { ResourceLibraryApi } from "../generate/apis";
import { call } from "./api";

export type ResourceLibraryApiResponse = ResourceLibrary[] | null | undefined;

export const useResourceLibrary = () => {
  const requestParams = {
    resultNumber: 100,
  };

  const fetch = async ({ pageParam = 0 }) => {
    const { data } = await call(ResourceLibraryApi).resourceLibraryGet({});
    return data;
  };

  return useQuery<ResourceLibraryApiResponse, Error>(["resource-library", requestParams], fetch);
};
export {};
