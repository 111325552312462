/* tslint:disable */
/* eslint-disable */
/**
 * Milliman.PullThroughPortal.WebAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  ErrorResponse,
  ErrorResponseFromJSON,
  ErrorResponseFromJSONTyped,
  ErrorResponseToJSON,
  PlanFeed,
  PlanFeedFromJSON,
  PlanFeedFromJSONTyped,
  PlanFeedToJSON,
} from "./";

/**
 *
 * @export
 * @interface PlanFeedIEnumerableControllerResponse
 */
export interface PlanFeedIEnumerableControllerResponse {
  /**
   *
   * @type {Array<PlanFeed>}
   * @memberof PlanFeedIEnumerableControllerResponse
   */
  data?: Array<PlanFeed> | null;
  /**
   *
   * @type {ErrorResponse}
   * @memberof PlanFeedIEnumerableControllerResponse
   */
  error?: ErrorResponse;
}

export function PlanFeedIEnumerableControllerResponseFromJSON(json: any): PlanFeedIEnumerableControllerResponse {
  return PlanFeedIEnumerableControllerResponseFromJSONTyped(json, false);
}

export function PlanFeedIEnumerableControllerResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PlanFeedIEnumerableControllerResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: !exists(json, "data")
      ? undefined
      : json["data"] === null
      ? null
      : (json["data"] as Array<any>).map(PlanFeedFromJSON),
    error: !exists(json, "error") ? undefined : ErrorResponseFromJSON(json["error"]),
  };
}

export function PlanFeedIEnumerableControllerResponseToJSON(value?: PlanFeedIEnumerableControllerResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data:
      value.data === undefined
        ? undefined
        : value.data === null
        ? null
        : (value.data as Array<any>).map(PlanFeedToJSON),
    error: ErrorResponseToJSON(value.error),
  };
}
