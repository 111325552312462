import { Checkbox } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  useClientPlanProfileConfiguration,
  useClientPrintConfiguration,
} from "../../data/global/useProductConfiguration";
import { HCPSpecificMessage } from "../../generate";
import useGetRole, { FRM_ROLE, GENERAL_ROLE } from "../../hooks/useGetRole";
import { useClientRoutesAndLabels } from "../../ui/routes";
import ExpandedActions from "./ExpandedActions";

interface Props {
  client: string;
  index: number;
  hcp: HCPSpecificMessage;
  selectedHcps: HCPSpecificMessage[];
  toggleHcp: (hcp: HCPSpecificMessage, isSelected: boolean) => void;
  expandedRow: null | string;
  setExpandedRow: React.Dispatch<React.SetStateAction<string | null>>;
  isPrint: boolean;
}

const HcpRow: React.FC<Props> = ({
  client,
  index,
  hcp,
  selectedHcps,
  toggleHcp,
  expandedRow,
  setExpandedRow,
  isPrint,
}) => {
  const rowId = `${index}-${hcp.prescID}`;
  const [t] = useTranslation();
  const [maxHeight, setMaxHeight] = useState(() => (rowId === expandedRow ? 1000 : 0));
  const [isExpanded, setIsExpanded] = useState(() => rowId === expandedRow);
  const routes = useClientRoutesAndLabels(client);
  const config = useClientPlanProfileConfiguration(client);
  const printConfig = useClientPrintConfiguration(client);
  const { data: role, isFetched } = useGetRole(client);
  const isGeneral = !isFetched || String(role) === GENERAL_ROLE;
  const isFRM = !isFetched || String(role) === FRM_ROLE;

  //Set current row as the current row, unless it is already expanded, then minimize all rows
  const expandRow = () => {
    setExpandedRow(expandedRow === rowId ? null : rowId);
  };

  //Manage the maxHeight and visibility of the expanded actions
  useEffect(() => {
    if (rowId === expandedRow) {
      setIsExpanded(true);
      setMaxHeight(1000);
    } else {
      setMaxHeight(0);
      //Use timeout to remove expanded items from tab order, after smooth animation
      setTimeout(() => {
        setIsExpanded(false);
      }, 300);
    }
  }, [expandedRow, rowId]);

  const isSelected = Boolean(selectedHcps.find((selectedHcp) => selectedHcp.prescID === hcp.prescID));
  const isEven = index % 2 === 0;

  const expandButtonClasses = classNames(
    "hidden sm:block w-10 h-10 rounded-full hover:bg-light-blue focus:outline-none focus:ring transform transition-all duration-300 rotate-0",
    {
      "rotate-180": isExpanded,
    },
  );
  const outerRowClasses = classNames(
    "border-l-4",
    {
      "border-primary": isExpanded,
    },
    {
      "border-light-gray": !isExpanded && isEven,
    },
    {
      "border-white": !isExpanded && !isEven,
    },
  );
  const innerRowClasses = classNames(
    "flex items-center",
    {
      "bg-light-gray": isEven && !isExpanded,
    },
    {
      "border-b border-rules bg-light-blue": isExpanded,
    },
  );
  return (
    <div className={outerRowClasses}>
      <div className={innerRowClasses}>
        <div className="w-10 sm:pl-2 sm:w-24 flex items-center justify-between">
          <Checkbox
            inputProps={{ "aria-label": "select row" }}
            onChange={() => toggleHcp(hcp, isSelected)}
            name="selectHcp"
            color="primary"
            checked={isSelected}
          />
          <button
            className={expandButtonClasses}
            onClick={expandRow}
            aria-label={t("specificMessages.toggleRowCollapse")}
          >
            <ExpandMoreIcon />
          </button>
        </div>

        <div className="flex-grow pl-2">
          <button
            onClick={expandRow}
            className="pl-2 w-full text-left text-brand flex py-4 sm:py-1  justify-between items-center hover:underline"
          >
            {hcp.hcpName}
            {(config.hiddenForGeneral && isGeneral) || (isFRM && printConfig.printEnabledForFRM) ? null : (
              <Link
                to={routes.client.dpp.hcps.show.with({ client, id: hcp.prescID })}
                href="#test"
                className="hidden sm:block text-slate-gray hover:underline p-4 underline hover:bg-light-blue focus:ring rounded mr-1"
              >
                {t("specificMessages.viewProfile")}
              </Link>
            )}
          </button>
        </div>
      </div>
      <div style={{ maxHeight: maxHeight }} className="overflow-hidden duration-300 border-b border-rules sm:pl-24">
        {isExpanded && <ExpandedActions client={client} hcp={hcp} isPrint={isPrint} />}
      </div>
    </div>
  );
};

export default HcpRow;
