/* eslint @typescript-eslint/no-var-requires: "off" */
import defaultTheme from "../tenants/default/theme";
import pfizerTheme from "../tenants/pfizer/theme";
const getTenantKey = require("../utils/getTenantKey");

const themes = {
  pfizer: { ...pfizerTheme },
  default: { ...defaultTheme },
};

const tenantKey = getTenantKey();
const theme = themes[tenantKey];

export default theme;
