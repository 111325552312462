/* eslint-disable  @typescript-eslint/no-explicit-any */
import Tooltip from "@material-ui/core/Tooltip";
import ExportIcon from "@material-ui/icons/OpenInNew";
import WarningIcon from "@material-ui/icons/Warning";
import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { UseInfiniteQueryResult, UseMutationResult } from "react-query";
import { useGlobalState } from "../../data/global/hooks";
import { MetaDataLibrary } from "../../generate";
import { BIOSIM_ROLE, FRM_ROLE } from "../../hooks/useGetRole";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";
import useWindowSize, { LayoutSize } from "../../hooks/useWindowSize";
import ErrorDisplayTableRow from "../ErrorDisplay/ErrorDisplayTableRow";
import LastUpdate from "../LastUpdate/LastUpdate";
import NoGeographySelectionTableRow from "../NoGeographySelection/NoGeographySelectionTableRow";
import NoResultsTableRow from "../NoResults/NoResultsTableRow";
import SkeletonTableBody from "../Skeletons/SkeletonTableBody";
import ColumnFilterButton from "./ColumnSortButton";
import Components from "./Components";
import MobileSorter from "./MobileSorter";
import { Column, Sort } from "./types";

const headerHeight = 530;
const calculatedTableHeight = window.innerHeight - headerHeight;
const tableHeight = calculatedTableHeight < 200 ? 200 : calculatedTableHeight;
const rowCount = 30;

interface Props {
  columns: Column[];
  query: UseInfiniteQueryResult<any, Error | null>;
  disableFooter?: boolean;
  sort?: Sort;
  handleSort?: (sort: Sort) => void;
  stickyMargin?: number;
  legend?: React.ReactNode;
  export?: UseMutationResult<void, Error, void>;
  footnote?: React.ReactNode;
  boldFootnote?: boolean;
  metaData?: MetaDataLibrary;
  role?: string;
}

const Table: React.FC<Props> = ({
  columns,
  query,
  disableFooter,
  sort,
  handleSort,
  export: exportTable,
  stickyMargin,
  legend,
  footnote,
  boldFootnote = false,
  metaData,
  role,
}) => {
  const [t] = useTranslation();
  const layoutSize = useWindowSize();
  const [minimizeTable, setMinimizeTable] = useState(false);
  const [newMinHasBeenSet, setNewMinHasBeenSet] = useState(false);
  const [minWindowBreak, setMinWindowBreak] = useState(640);
  const tableContainerRef = useRef<HTMLDivElement | null>(null);
  const tableRef = useRef<HTMLTableElement | null>(null);
  const loadMoreRef = useRef<HTMLButtonElement | null>(null);
  const { selectedGeoName } = useGlobalState();

  const isBiosim = String(role) === BIOSIM_ROLE;
  const isFrm = String(role) === FRM_ROLE;

  const noGeographySelection = selectedGeoName === null;
  const noResults = !noGeographySelection && query.data && query.data.pages[0].length === 0;

  //Setup infinite scroll
  useIntersectionObserver({
    target: loadMoreRef,
    rootMargin: "1000px",
    onIntersect: query.fetchNextPage,
    enabled: query.hasNextPage && !noGeographySelection,
  });

  //Scroll to top before changing sort
  const scrollTopThenSort = (value: Sort) => {
    const scrollPosition = window.scrollY;
    const tableTop = tableRef.current?.getBoundingClientRect().top || 0;
    const margin = stickyMargin || 0;
    const stickyTop = scrollPosition + tableTop - margin;
    const scrollTo = scrollPosition < stickyTop ? scrollPosition : stickyTop;
    window.scrollTo(0, scrollTo);
    handleSort && handleSort(value);
  };

  // Determine if table should be minimized on resize
  useEffect(() => {
    const handleResize = () => {
      if (tableRef.current && tableContainerRef.current) {
        //If table size is bigger than container, set what the new min window size is
        if (tableContainerRef.current.clientWidth < tableRef.current.clientWidth && !newMinHasBeenSet) {
          setMinWindowBreak(tableRef.current.clientWidth);
          setNewMinHasBeenSet(true);
        }

        //check to see if we are under the min window width to minimize table
        const minimize = tableContainerRef.current.clientWidth < minWindowBreak;
        setMinimizeTable(minimize);
      }
    };

    // Add event listener
    window.addEventListener("resize", handleResize);

    //Call handler right away so state gets updated on init
    handleResize();

    //Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, [tableContainerRef, tableRef, minimizeTable, minWindowBreak, newMinHasBeenSet]);

  //Render table Headers
  const TableHeaders = () => (
    <>
      {columns.map((column, index) => {
        return (
          <th
            style={{ width: column.width, top: stickyMargin }}
            key={index}
            className={`sticky-table-header  sm:text-${column.align} text-${column.align}`}
          >
            <ColumnFilterButton column={column} sort={sort} handleSort={scrollTopThenSort} />
          </th>
        );
      })}
    </>
  );

  //Render tables TDs
  const Cells = (row: any) => (
    <>
      {columns.map((column) => (
        <td
          key={column.dataKey}
          data-label={t(column.label, column.translationParams)}
          className={`sm:text-${column.align} text-${column.align}`}
        >
          {Components(column, row, role)}
        </td>
      ))}
    </>
  );

  //Render tables TRs
  const TableRows = () => (
    <tbody>
      {query.data &&
        query.data.pages &&
        query.data.pages.map((page: any[], pageIndex: number) =>
          page.map((row, rowIndex) => (
            <tr key={rowIndex}>
              <Cells row={row} />
            </tr>
          )),
        )}
    </tbody>
  );

  //Determine what to render in the table body
  const TableBody = () => {
    if (query.isLoading) {
      return <SkeletonTableBody columns={columns} rowCount={rowCount} />;
    }
    if (query.isError && query.error) {
      return <ErrorDisplayTableRow columnCount={columns.length} height={tableHeight} error={query.error} />;
    }
    if (noResults) {
      return <NoResultsTableRow columnCount={columns.length} height={tableHeight} />;
    }
    if (noGeographySelection) {
      return <NoGeographySelectionTableRow columnCount={columns.length} height={tableHeight} />;
    }
    return <TableRows />;
  };

  const TableFooter = () => (
    <div className="sticky w-full bottom-0 pb-6 bg-white left-0">
      <div className="lg:container">
        <div className="bg-rules flex items-center justify-between h-10">
          <div className="pl-3">{legend && legend}</div>
          <div className="flex items-center">
            <div className="pr-3">{query.isFetching && <span>{t("common.loading")}</span>}</div>
            {!isBiosim && !isFrm && exportTable && layoutSize === LayoutSize.Desktop && (
              <Tooltip
                disableFocusListener
                disableHoverListener={exportTable.status !== "error"}
                disableTouchListener={exportTable.status !== "error"}
                arrow
                title={t("common.exportError") as string}
              >
                <div className="h-full flex items-center divide-x-2 divide-white">
                  <button className="btn p-0" disabled={exportTable.isLoading} onClick={() => exportTable.mutate()}>
                    {(exportTable.status === "idle" || exportTable.status === "success") && (
                      <span>{t("common.export")}</span>
                    )}
                    {exportTable.status === "loading" && <span>{t("common.exportLoading")}</span>}
                    {exportTable.status === "error" && (
                      <>
                        <WarningIcon /> <span>{t("common.export")}</span>
                      </>
                    )}
                  </button>

                  <button
                    className="btn p-0"
                    aria-label={t("common.export")}
                    disabled={exportTable.isLoading}
                    onClick={() => exportTable.mutate()}
                  >
                    <ExportIcon />
                  </button>
                </div>
              </Tooltip>
            )}
          </div>
        </div>
        {footnote &&
          (boldFootnote ? (
            <p className="text-xs text-slate-gray pt-2 whitespace-pre-line leading-6 font-bold">{footnote}</p>
          ) : (
            <p className="text-xs text-slate-gray pt-2 whitespace-pre-line leading-6">{footnote}</p>
          ))}
        {metaData && <LastUpdate className={"pt-2"} {...metaData} isFRM={role === "frm" ? true : false} />}
      </div>
    </div>
  );

  const tableClasses = classNames("w-full", {
    "minimize-table": minimizeTable,
  });

  return (
    <div className="lg:container">
      <div ref={tableContainerRef} className="pb-6">
        {minimizeTable && sort && handleSort && <MobileSorter columns={columns} sort={sort} handleSort={handleSort} />}
        <table ref={tableRef} className={tableClasses}>
          <thead className="bg-rules">
            <tr>
              <TableHeaders />
            </tr>
          </thead>
          <TableBody />
        </table>
        {!noGeographySelection && (
          <div className="pt-10 pb-4 flex items-center justify-center w-full">
            <button
              ref={loadMoreRef}
              onClick={() => query.fetchNextPage()}
              disabled={!query.hasNextPage || query.isFetchingNextPage}
            >
              {query.isFetchingNextPage
                ? t("common.loadingMore")
                : query.hasNextPage
                ? t("common.loadMore")
                : !query.isError && !noResults
                ? t("common.nothingMoreToLoad")
                : null}
            </button>
          </div>
        )}
      </div>
      {!disableFooter && <TableFooter />}
    </div>
  );
};

export default Table;
