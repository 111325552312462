import React from "react";
import ErrorDisplayTableRow from "../ErrorDisplay/ErrorDisplayTableRow";
import NoResultsTableRow from "../NoResults/NoResultsTableRow";
import SkeletonTableBody from "../Skeletons/SkeletonTableBody";
import Components from "./Components";
import { Column } from "./types";

interface Props {
  columns: Column[];
  data?: any[] | null;
  loading: boolean;
  error?: null | Error;
  estimatedRows?: number;
  rowHeight?: number;
  tableFooter?: any;
}

const SimpleTable: React.FC<Props> = ({
  columns,
  data,
  loading,
  error,
  estimatedRows = 5,
  rowHeight = 39,
  tableFooter,
}) => {
  const tableHeight = rowHeight * estimatedRows;
  //Table Headers
  const TableHead = () => (
    <thead>
      <tr className="bg-rules">
        {columns.map((column, i) => (
          <th key={column.label} className={`p-3 sm:text-${column.align} text-${column.align}`}>
            <p>{column.label}</p>
            <p className="text-xs">{column.subLabel}</p>
          </th>
        ))}
      </tr>
    </thead>
  );

  //Render tables TRs
  const TableRows = () => (
    <tbody>
      {data &&
        data.map((row, index) => {
          return (
            <tr key={index}>
              <Cells row={row} />
            </tr>
          );
        })}
    </tbody>
  );

  //Render tables TDs
  const Cells = (row: any) => (
    <>
      {columns.map((column) => (
        <td
          key={column.dataKey}
          data-label={column.label}
          className={`${column.tdClasses} sm:text-${column.align} text-${column.align}`}
        >
          {Components(column, row)}
        </td>
      ))}
    </>
  );

  //Determine what to render in the table body
  const TableBody = () => {
    if (loading) {
      return <SkeletonTableBody columns={columns} rowCount={estimatedRows} />;
    }
    if (error) {
      return <ErrorDisplayTableRow columnCount={columns.length} height={tableHeight} error={error} />;
    }
    if (data === null || data === undefined || data.length === 0) {
      return <NoResultsTableRow columnCount={columns.length} height={tableHeight} />;
    }
    return <TableRows />;
  };

  const TableFooter = () => {
    return tableFooter;
  };

  return (
    <table className="w-full  border-rules sm:border-2">
      <TableHead />
      <TableBody />
      <TableFooter />
    </table>
  );
};

export default SimpleTable;
