import { useParams } from "react-router-dom";
import { useClientRoutesAndLabels } from "../../ui/routes";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import HcpList from "../Messages/HcpList";
import React from "react";
import { useGlobalState } from "../../data/global/hooks";
import { GeographyType } from "../../components/GeoSelect/GeoTypes";
import SelectTerritory from "../../components/SelectTerritory/SelectTerritory";
import MessagePageHeader from "../../components/MessagePageHeader/MessagePageHeader";
import MessagePageSubHeader from "../../components/MessagePageSubHeader/MessagePageSubHeader";
import { useTranslation } from "react-i18next";

const Email: React.FC = () => {
  const { selectedGeoType } = useGlobalState();
  const { client: selectedClient } = useParams<{ client: string }>();
  const { t, i18n } = useTranslation();
  const routes = useClientRoutesAndLabels(selectedClient);

  if (selectedGeoType !== GeographyType.Territory) {
    return <SelectTerritory client={selectedClient} />;
  }

  return (
    <div>
      <MessagePageHeader client={selectedClient} path={routes.client.print.email} isPrint={false} />
      <BreadCrumbs />
      <div>
        <MessagePageSubHeader client={selectedClient} />
        <HcpList
          client={selectedClient}
          isPrint={false}
          footnote={
            i18n.exists(`email.footnote.${selectedClient}`)
              ? t(`email.footnote.${selectedClient}`)
              : t(`email.footnote.default`)
          }
        />
      </div>
    </div>
  );
};

export default Email;
