import fileSaver from "file-saver";
import { useInfiniteQuery, useMutation, useQuery } from "react-query";
import { GeographyType } from "../components/GeoSelect/GeoTypes";
import { useGlobalState } from "../data/global/hooks";
import {
  MarketSummary,
  PayerApi,
  PayerPlanProfileAdditionalCoverageUrlGetRequest,
  PayerPlanProfileMarketSummaryGetRequest,
  PayerPlanProfilePlanDataGetRequest,
  PayerPlanProfileTopHealthcareProvidersGetRequest,
  PlanData,
  TopHealthcareProviders,
} from "../generate";
import { call } from "./api";

export const usePlanData = (client: string, id: string, timePeriod: string, indication?: string) => {
  const { selectedGeoName, selectedGeoType } = useGlobalState();
  const requestParams: PayerPlanProfilePlanDataGetRequest = {
    client,
    planID: id,
    geography: selectedGeoName ?? GeographyType.National,
    geographyType: selectedGeoType ?? GeographyType.National,
    timePeriod,
    indication,
  };
  const fetch = async () => {
    const { data } = await call(PayerApi).payerPlanProfilePlanDataGet(requestParams);
    return data;
  };
  type ApiResponse = PlanData | undefined;
  return useQuery<ApiResponse, Error>(["plandata", requestParams], fetch, {
    enabled: Boolean(client) && Boolean(selectedGeoName) && Boolean(selectedGeoType),
    retry: 1,
  });
};

export const useMarketSummary = (client: string, id: string, timePeriod: string, indication?: string) => {
  const { selectedGeoName, selectedGeoType } = useGlobalState();
  const requestParams: PayerPlanProfileMarketSummaryGetRequest = {
    client,
    planID: id,
    geography: selectedGeoName ?? GeographyType.National,
    geographyType: selectedGeoType ?? GeographyType.National,
    timePeriod,
    indication,
  };
  const fetch = async () => {
    const { data } = await call(PayerApi).payerPlanProfileMarketSummaryGet(requestParams);
    return data;
  };
  type ApiResponse = MarketSummary[] | undefined | null;
  return useQuery<ApiResponse, Error>(["marketsummary", requestParams], fetch, {
    enabled: Boolean(client) && Boolean(selectedGeoName) && Boolean(selectedGeoType),
    retry: 1,
  });
};

export const useTopHealthcareProviders = (
  client: string,
  id: string,
  timePeriod: string,
  hcpID?: string,
  indication?: string,
) => {
  const { selectedGeoName, selectedGeoType } = useGlobalState();
  const requestParams: PayerPlanProfileTopHealthcareProvidersGetRequest = {
    client,
    planID: id,
    prescID: hcpID,
    geography: selectedGeoName ?? GeographyType.National,
    geographyType: selectedGeoType ?? GeographyType.National,
    timePeriod,
    resultNumber: 20,
    indication,
  };
  const fetch = async () => {
    const { data } = await call(PayerApi).payerPlanProfileTopHealthcareProvidersGet(requestParams);
    return data;
  };
  type ApiResponse = TopHealthcareProviders[] | undefined | null;
  return useQuery<ApiResponse, Error>(["tophealthcareproviders", requestParams], fetch, {
    enabled: Boolean(client) && Boolean(selectedGeoName) && Boolean(selectedGeoType),
    retry: 1,
  });
};

export const usePayerProfileTopHCPExport = (
  planID: string,
  columnKeys: string[],
  columnNames: string[],
  client: string,
  resultNumber: number,
  timePeriod?: string,
  indication?: string,
) => {
  const { selectedGeoName, selectedGeoType } = useGlobalState();
  const requestParams: PayerPlanProfileTopHealthcareProvidersGetRequest = {
    client,
    planID: planID,
    geography: selectedGeoName ?? GeographyType.National,
    geographyType: selectedGeoType ?? GeographyType.National,
    timePeriod,
    resultNumber,
    indication,
  };
  return useMutation<void, Error>(async () => {
    const response = await call(PayerApi).payerPlanProfileTopHealthcareProvidersExcelGetRaw({
      columnKeys,
      columnNames,
      ...requestParams,
    });
    const blob = await response.raw.blob();
    const file = new File([blob], "export.xlsx");
    fileSaver.saveAs(file);
  });
};
export const useHealthcareProviders = (
  client: string,
  id: string,
  timePeriod: string,
  hcpID?: string,
  indication?: string,
) => {
  const { selectedGeoName, selectedGeoType } = useGlobalState();
  const requestParams: PayerPlanProfileTopHealthcareProvidersGetRequest & { resultNumber: number } = {
    client,
    planID: id,
    prescID: hcpID,
    geography: selectedGeoName ?? GeographyType.National,
    geographyType: selectedGeoType ?? GeographyType.National,
    timePeriod,
    resultNumber: 100,
    indication,
  };
  const fetch = async ({ pageParam = 0 }) => {
    const queryParams = { ...requestParams, skip: pageParam };
    const { data } = await call(PayerApi).payerPlanProfileTopHealthcareProvidersGet(queryParams);
    return data;
  };

  type ApiResponse = TopHealthcareProviders[] | undefined | null;
  return useInfiniteQuery<ApiResponse, Error>(["healthcareproviders", requestParams], fetch, {
    enabled: Boolean(client) && Boolean(selectedGeoName) && Boolean(selectedGeoType),
    getNextPageParam: (lastPage, pages) =>
      lastPage && lastPage.length === 0 ? undefined : pages.length * requestParams.resultNumber,
  });
};

export const useAdditionalCoverageUrl = (client: string, id: string, indication?: string) => {
  const requestParams: PayerPlanProfileAdditionalCoverageUrlGetRequest = {
    client,
    planID: id,
    indication: indication ? indication : "",
  };
  const fetch = async () => {
    const { data } = await call(PayerApi).payerPlanProfileAdditionalCoverageUrlGet(requestParams);
    return data;
  };
  type ApiResponse = string | undefined | null;
  return useQuery<ApiResponse, Error>(["additonalCoverage", requestParams], fetch, {
    enabled: Boolean(client) && Boolean(id),
    retry: 1,
  });
};
