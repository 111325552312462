import { useClientDropdown } from "../api/useClientDropdown";
import { usePayerLandscapeDetailDropdowns } from "../api/usePayerLandscapeDetailApi";

export const useProducts = (client: string) => {
  const { isLoading, data: clients } = useClientDropdown();
  return usePayerLandscapeDetailDropdowns(client, {
    select: (data) => data?.product || [],
    enabled: !isLoading && clients !== undefined && clients !== null && clients.length > 0,
  });
};
