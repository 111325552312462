/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";

const ModalContext = React.createContext<
  | {
      modal: JSX.Element | undefined;
      setModal: React.Dispatch<React.SetStateAction<JSX.Element | undefined>>;
      setScrollRef: (ref: any) => void;
    }
  | undefined
>(undefined);

export const ModalProvider = ({ children }: { children: any }) => {
  const [modal, setModal] = useState<JSX.Element | undefined>();
  const [scrollRef, setScrollRef] = useState<any>(null);

  //on open, fix the bg and move it up to prevent jumps
  useEffect(() => {
    if (modal) {
      if (scrollRef && scrollRef.current) {
        const scrollY = window.scrollY; //Get position, before fixing BG
        scrollRef.current.style.position = "fixed";
        scrollRef.current.style.top = `-${scrollY}px`;
      }
    }
  }, [modal, scrollRef]);

  //on close, reset position and top, then scroll back to previous position
  useEffect(() => {
    if (!modal && scrollRef && scrollRef.current) {
      const scrollY = scrollRef.current.style.top;
      scrollRef.current.style.position = "";
      scrollRef.current.style.top = "";
      window.scrollTo(0, parseInt(scrollY) * -1);
    }
  }, [scrollRef, modal]);

  return <ModalContext.Provider value={{ modal, setModal, setScrollRef }}>{children}</ModalContext.Provider>;
};

export const useModal = () => {
  const context = React.useContext(ModalContext);
  if (context === undefined) {
    throw new Error("useModal must be used within a ModalProvider");
  }
  return context;
};
