import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store/types";
import { updateGenericFilter } from "./actions";
import { HcpSpecificMessagingDispatch, HcpSpecificMessagingState } from "./types";

export const useHcpSpecificMessagingState = (): HcpSpecificMessagingState =>
  useSelector(({ hcpSpecificMessaging }: AppState) => {
    return hcpSpecificMessaging;
  });

export const useHcpSpecificMessagingDispatch = (): HcpSpecificMessagingDispatch => {
  const dispatch = useDispatch();
  return useMemo(
    (): HcpSpecificMessagingDispatch => ({
      updateGenericFilter: (genericFilter) => dispatch(updateGenericFilter(genericFilter)),
    }),
    [dispatch],
  );
};
