/* tslint:disable */
/* eslint-disable */
/**
 * Milliman.PullThroughPortal.WebAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  AuditInfoControllerResponse,
  AuditInfoControllerResponseFromJSON,
  AuditInfoControllerResponseToJSON,
  HCPSpecificMessageIEnumerableControllerResponse,
  HCPSpecificMessageIEnumerableControllerResponseFromJSON,
  HCPSpecificMessageIEnumerableControllerResponseToJSON,
  JobStatusControllerResponse,
  JobStatusControllerResponseFromJSON,
  JobStatusControllerResponseToJSON,
  PrintDoc,
  PrintDocFromJSON,
  PrintDocToJSON,
  PrintDocInfoControllerResponse,
  PrintDocInfoControllerResponseFromJSON,
  PrintDocInfoControllerResponseToJSON,
  StringControllerResponse,
  StringControllerResponseFromJSON,
  StringControllerResponseToJSON,
  ZipPackageInfoControllerResponse,
  ZipPackageInfoControllerResponseFromJSON,
  ZipPackageInfoControllerResponseToJSON,
  ZipPackageInput,
  ZipPackageInputFromJSON,
  ZipPackageInputToJSON,
} from "../models";

export interface PrintEmailTemplatePostRequest {
  apiVersion?: string;
  printDoc?: PrintDoc;
}

export interface PrintHCPSpecificMessagingGetRequest {
  territory: string;
  client: string;
  isPrint: boolean;
  genericFilter?: string | null;
  resultNumber?: number;
  skip?: number;
  apiVersion?: string;
}

export interface PrintLogPrintDocPostRequest {
  apiVersion?: string;
  printDoc?: PrintDoc;
}

export interface PrintPrintDocPostRequest {
  apiVersion?: string;
  printDoc?: PrintDoc;
}

export interface PrintZippedJobStatusJobIDGetRequest {
  jobID: string | null;
  apiVersion?: string;
}

export interface PrintZippedPrintDocsPackageIDGetRequest {
  packageID: string | null;
  apiVersion?: string;
}

export interface PrintZippedPrintDocsPostRequest {
  apiVersion?: string;
  zipPackageInput?: ZipPackageInput;
}

/**
 *
 */
export class PrintApi extends runtime.BaseAPI {
  /**
   */
  async printEmailTemplatePostRaw(
    requestParameters: PrintEmailTemplatePostRequest,
  ): Promise<runtime.ApiResponse<StringControllerResponse>> {
    const queryParameters: any = {};

    if (requestParameters.apiVersion !== undefined) {
      queryParameters["api-version"] = requestParameters.apiVersion;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/Print/EmailTemplate`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: PrintDocToJSON(requestParameters.printDoc),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => StringControllerResponseFromJSON(jsonValue));
  }

  /**
   */
  async printEmailTemplatePost(requestParameters: PrintEmailTemplatePostRequest): Promise<StringControllerResponse> {
    const response = await this.printEmailTemplatePostRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async printHCPSpecificMessagingGetRaw(
    requestParameters: PrintHCPSpecificMessagingGetRequest,
  ): Promise<runtime.ApiResponse<HCPSpecificMessageIEnumerableControllerResponse>> {
    if (requestParameters.territory === null || requestParameters.territory === undefined) {
      throw new runtime.RequiredError(
        "territory",
        "Required parameter requestParameters.territory was null or undefined when calling printHCPSpecificMessagingGet.",
      );
    }

    if (requestParameters.client === null || requestParameters.client === undefined) {
      throw new runtime.RequiredError(
        "client",
        "Required parameter requestParameters.client was null or undefined when calling printHCPSpecificMessagingGet.",
      );
    }

    if (requestParameters.isPrint === null || requestParameters.isPrint === undefined) {
      throw new runtime.RequiredError(
        "isPrint",
        "Required parameter requestParameters.isPrint was null or undefined when calling printHCPSpecificMessagingGet.",
      );
    }

    const queryParameters: any = {};

    if (requestParameters.territory !== undefined) {
      queryParameters["Territory"] = requestParameters.territory;
    }

    if (requestParameters.client !== undefined) {
      queryParameters["Client"] = requestParameters.client;
    }

    if (requestParameters.isPrint !== undefined) {
      queryParameters["IsPrint"] = requestParameters.isPrint;
    }

    if (requestParameters.genericFilter !== undefined) {
      queryParameters["GenericFilter"] = requestParameters.genericFilter;
    }

    if (requestParameters.resultNumber !== undefined) {
      queryParameters["ResultNumber"] = requestParameters.resultNumber;
    }

    if (requestParameters.skip !== undefined) {
      queryParameters["Skip"] = requestParameters.skip;
    }

    if (requestParameters.apiVersion !== undefined) {
      queryParameters["api-version"] = requestParameters.apiVersion;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/Print/HCPSpecificMessaging`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      HCPSpecificMessageIEnumerableControllerResponseFromJSON(jsonValue),
    );
  }

  /**
   */
  async printHCPSpecificMessagingGet(
    requestParameters: PrintHCPSpecificMessagingGetRequest,
  ): Promise<HCPSpecificMessageIEnumerableControllerResponse> {
    const response = await this.printHCPSpecificMessagingGetRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async printLogPrintDocPostRaw(
    requestParameters: PrintLogPrintDocPostRequest,
  ): Promise<runtime.ApiResponse<AuditInfoControllerResponse>> {
    const queryParameters: any = {};

    if (requestParameters.apiVersion !== undefined) {
      queryParameters["api-version"] = requestParameters.apiVersion;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/Print/LogPrintDoc`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: PrintDocToJSON(requestParameters.printDoc),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => AuditInfoControllerResponseFromJSON(jsonValue));
  }

  /**
   */
  async printLogPrintDocPost(requestParameters: PrintLogPrintDocPostRequest): Promise<AuditInfoControllerResponse> {
    const response = await this.printLogPrintDocPostRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async printPrintDocPostRaw(
    requestParameters: PrintPrintDocPostRequest,
  ): Promise<runtime.ApiResponse<PrintDocInfoControllerResponse>> {
    const queryParameters: any = {};

    if (requestParameters.apiVersion !== undefined) {
      queryParameters["api-version"] = requestParameters.apiVersion;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/Print/PrintDoc`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: PrintDocToJSON(requestParameters.printDoc),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => PrintDocInfoControllerResponseFromJSON(jsonValue));
  }

  /**
   */
  async printPrintDocPost(requestParameters: PrintPrintDocPostRequest): Promise<PrintDocInfoControllerResponse> {
    const response = await this.printPrintDocPostRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async printZippedJobStatusJobIDGetRaw(
    requestParameters: PrintZippedJobStatusJobIDGetRequest,
  ): Promise<runtime.ApiResponse<JobStatusControllerResponse>> {
    if (requestParameters.jobID === null || requestParameters.jobID === undefined) {
      throw new runtime.RequiredError(
        "jobID",
        "Required parameter requestParameters.jobID was null or undefined when calling printZippedJobStatusJobIDGet.",
      );
    }

    const queryParameters: any = {};

    if (requestParameters.apiVersion !== undefined) {
      queryParameters["api-version"] = requestParameters.apiVersion;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/Print/ZippedJobStatus/{jobID}`.replace(
        `{${"jobID"}}`,
        encodeURIComponent(String(requestParameters.jobID)),
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => JobStatusControllerResponseFromJSON(jsonValue));
  }

  /**
   */
  async printZippedJobStatusJobIDGet(
    requestParameters: PrintZippedJobStatusJobIDGetRequest,
  ): Promise<JobStatusControllerResponse> {
    const response = await this.printZippedJobStatusJobIDGetRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async printZippedPrintDocsPackageIDGetRaw(
    requestParameters: PrintZippedPrintDocsPackageIDGetRequest,
  ): Promise<runtime.ApiResponse<StringControllerResponse>> {
    if (requestParameters.packageID === null || requestParameters.packageID === undefined) {
      throw new runtime.RequiredError(
        "packageID",
        "Required parameter requestParameters.packageID was null or undefined when calling printZippedPrintDocsPackageIDGet.",
      );
    }

    const queryParameters: any = {};

    if (requestParameters.apiVersion !== undefined) {
      queryParameters["api-version"] = requestParameters.apiVersion;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/Print/ZippedPrintDocs/{packageID}`.replace(
        `{${"packageID"}}`,
        encodeURIComponent(String(requestParameters.packageID)),
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => StringControllerResponseFromJSON(jsonValue));
  }

  /**
   */
  async printZippedPrintDocsPackageIDGet(
    requestParameters: PrintZippedPrintDocsPackageIDGetRequest,
  ): Promise<StringControllerResponse> {
    const response = await this.printZippedPrintDocsPackageIDGetRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async printZippedPrintDocsPostRaw(
    requestParameters: PrintZippedPrintDocsPostRequest,
  ): Promise<runtime.ApiResponse<ZipPackageInfoControllerResponse>> {
    const queryParameters: any = {};

    if (requestParameters.apiVersion !== undefined) {
      queryParameters["api-version"] = requestParameters.apiVersion;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/Print/ZippedPrintDocs`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: ZipPackageInputToJSON(requestParameters.zipPackageInput),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => ZipPackageInfoControllerResponseFromJSON(jsonValue));
  }

  /**
   */
  async printZippedPrintDocsPost(
    requestParameters: PrintZippedPrintDocsPostRequest,
  ): Promise<ZipPackageInfoControllerResponse> {
    const response = await this.printZippedPrintDocsPostRaw(requestParameters);
    return await response.value();
  }
}
