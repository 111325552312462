/* tslint:disable */
/* eslint-disable */
/**
 * Milliman.PullThroughPortal.WebAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ComparisonDropdowns
 */
export interface ComparisonDropdowns {
  /**
   *
   * @type {Array<string>}
   * @memberof ComparisonDropdowns
   */
  timePeriod?: Array<string> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof ComparisonDropdowns
   */
  benificiaryType?: Array<string> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof ComparisonDropdowns
   */
  prod1Status?: Array<string> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof ComparisonDropdowns
   */
  prod2Status?: Array<string> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof ComparisonDropdowns
   */
  prod3Status?: Array<string> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof ComparisonDropdowns
   */
  prod4Status?: Array<string> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof ComparisonDropdowns
   */
  prod5Status?: Array<string> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof ComparisonDropdowns
   */
  prod6Status?: Array<string> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof ComparisonDropdowns
   */
  prod7Status?: Array<string> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof ComparisonDropdowns
   */
  prod8Status?: Array<string> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof ComparisonDropdowns
   */
  prod9Status?: Array<string> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof ComparisonDropdowns
   */
  prod10Status?: Array<string> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof ComparisonDropdowns
   */
  prod1?: Array<string> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof ComparisonDropdowns
   */
  prod2?: Array<string> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof ComparisonDropdowns
   */
  prod3?: Array<string> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof ComparisonDropdowns
   */
  prod4?: Array<string> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof ComparisonDropdowns
   */
  prod5?: Array<string> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof ComparisonDropdowns
   */
  prod6?: Array<string> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof ComparisonDropdowns
   */
  prod7?: Array<string> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof ComparisonDropdowns
   */
  prod8?: Array<string> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof ComparisonDropdowns
   */
  prod9?: Array<string> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof ComparisonDropdowns
   */
  prod10?: Array<string> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof ComparisonDropdowns
   */
  indication?: Array<string> | null;
}

export function ComparisonDropdownsFromJSON(json: any): ComparisonDropdowns {
  return ComparisonDropdownsFromJSONTyped(json, false);
}

export function ComparisonDropdownsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ComparisonDropdowns {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    timePeriod: !exists(json, "timePeriod") ? undefined : json["timePeriod"],
    benificiaryType: !exists(json, "benificiaryType") ? undefined : json["benificiaryType"],
    prod1Status: !exists(json, "prod1Status") ? undefined : json["prod1Status"],
    prod2Status: !exists(json, "prod2Status") ? undefined : json["prod2Status"],
    prod3Status: !exists(json, "prod3Status") ? undefined : json["prod3Status"],
    prod4Status: !exists(json, "prod4Status") ? undefined : json["prod4Status"],
    prod5Status: !exists(json, "prod5Status") ? undefined : json["prod5Status"],
    prod6Status: !exists(json, "prod6Status") ? undefined : json["prod6Status"],
    prod7Status: !exists(json, "prod7Status") ? undefined : json["prod7Status"],
    prod8Status: !exists(json, "prod8Status") ? undefined : json["prod8Status"],
    prod9Status: !exists(json, "prod9Status") ? undefined : json["prod9Status"],
    prod10Status: !exists(json, "prod10Status") ? undefined : json["prod10Status"],
    prod1: !exists(json, "prod1") ? undefined : json["prod1"],
    prod2: !exists(json, "prod2") ? undefined : json["prod2"],
    prod3: !exists(json, "prod3") ? undefined : json["prod3"],
    prod4: !exists(json, "prod4") ? undefined : json["prod4"],
    prod5: !exists(json, "prod5") ? undefined : json["prod5"],
    prod6: !exists(json, "prod6") ? undefined : json["prod6"],
    prod7: !exists(json, "prod7") ? undefined : json["prod7"],
    prod8: !exists(json, "prod8") ? undefined : json["prod8"],
    prod9: !exists(json, "prod9") ? undefined : json["prod9"],
    prod10: !exists(json, "prod10") ? undefined : json["prod10"],
    indication: !exists(json, "indication") ? undefined : json["indication"],
  };
}

export function ComparisonDropdownsToJSON(value?: ComparisonDropdowns | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    timePeriod: value.timePeriod,
    benificiaryType: value.benificiaryType,
    prod1Status: value.prod1Status,
    prod2Status: value.prod2Status,
    prod3Status: value.prod3Status,
    prod4Status: value.prod4Status,
    prod5Status: value.prod5Status,
    prod6Status: value.prod6Status,
    prod7Status: value.prod7Status,
    prod8Status: value.prod8Status,
    prod9Status: value.prod9Status,
    prod10Status: value.prod10Status,
    prod1: value.prod1,
    prod2: value.prod2,
    prod3: value.prod3,
    prod4: value.prod4,
    prod5: value.prod5,
    prod6: value.prod6,
    prod7: value.prod7,
    prod8: value.prod8,
    prod9: value.prod9,
    prod10: value.prod10,
    indication: value.indication,
  };
}
