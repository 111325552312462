import { differenceBy } from "lodash";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import { stringifyQueueItem } from "../../utils/stringifyQueueItem";
import { addQueueItems, removeQueueItems, updatePrintJob, updateQueueItem } from "./actions";
import { PrintJobStatus, QueueState } from "./types";

export const initialState: QueueState = {
  queue: [],
  printJob: {
    status: PrintJobStatus.Idle,
    downloadURL: "",
    errorMessage: "",
    printDocs: [],
  },
};

const reducer = reducerWithInitialState(initialState)
  .case(addQueueItems, (state, queueItems) => ({
    ...state,
    queue: [...state.queue, ...queueItems],
  }))
  .case(updateQueueItem, (state, queueItem) => {
    const updatedQueue = [...state.queue];
    const queueIndex = state.queue.findIndex(
      (item) => item.prescID === queueItem.prescID && item.baseDocName === queueItem.baseDocName,
    );
    updatedQueue.splice(queueIndex, 1, queueItem);
    return {
      ...state,
      queue: updatedQueue,
    };
  })
  .case(removeQueueItems, (state, queueItems) => {
    const filteredQueueItems = differenceBy(state.queue, queueItems, stringifyQueueItem);
    return {
      ...state,
      queue: filteredQueueItems,
    };
  })
  .case(updatePrintJob, (state, printJob) => ({
    ...state,
    printJob: { ...state.printJob, ...printJob },
  }));

export default reducer;
