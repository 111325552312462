/* eslint-disable @typescript-eslint/no-explicit-any */
import ErrorTwoToneIcon from "@material-ui/icons/ErrorTwoTone";
import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
  error: Error | null;
}

const ErrorDisplay: React.FC<Props> = ({ error }) => {
  const [t] = useTranslation();

  return (
    <div className="text-center">
      <ErrorTwoToneIcon style={{ fontSize: 80 }} className="text-error" />
      <p className="body-1 text-error">{t("common.oopsThereWasAnError")}</p>
      <p className="body-2 text-slate-gray">{error && error.message}</p>
    </div>
  );
};

export default ErrorDisplay;
