import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useGeoTreeApi } from "../api/useGeoTreeApi";
import { useGlobalDispatch, useGlobalState } from "../data/global/hooks";
import { useClientRoutesAndLabels } from "../ui/routes";

const useHasGeoAccess = (client: string) => {
  const history = useHistory();
  const routes = useClientRoutesAndLabels(client);

  //Check users geo access
  const { hasGeoAccess } = useGlobalState();
  const { updateGeoAccess } = useGlobalDispatch();
  const { error } = useGeoTreeApi(client);
  if (error && error.statusText === "Forbidden" && hasGeoAccess) {
    updateGeoAccess(false);
  }

  useEffect(() => {
    if (!hasGeoAccess) {
      history.push(routes.noAccess.path);
    }
  }, [hasGeoAccess, history, routes.noAccess.path]);
};

export default useHasGeoAccess;
