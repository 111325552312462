import InfoOutlined from "@material-ui/icons/InfoOutlined";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useCCMessages, useHealthCareProvider } from "../../api/useHcpProfileApi";
import { DataWindow, useDataWindows } from "../../api/useMetaDataApi";
import TableHeader from "../../components/Headers/TableHeader";
import SimpleTable from "../../components/Table/SimpleTable";
import Tooltip from "../../components/Tooltip/Tooltip";
import { useHcpLandscapeState } from "../../data/hcpLandscape/hooks";
import { Tab, TabPanel, useTabs } from "../../components/Tabs";
import useQueryParams from "../../hooks/useQueryParams";
import AddToPrintQueueIcon from "../../components/Icons/AddToPrintQueueIcon";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import { useClientConfiguration } from "../../data/global/useProductConfiguration";

export enum CCTabNames {
  PRINT_MESSAGES = "print-messages",
  EMAIL_MESSAGES = "email-messages",
}

const CostAndCoverageMessages: React.FC<{ defaultTab: CCTabNames }> = ({ defaultTab }) => {
  const [t] = useTranslation();
  const { client: selectedClient, id } = useParams<{ client: string; id: string }>();
  const hcpQuery = useHealthCareProvider(selectedClient, id);
  const { data: dataWindows } = useDataWindows(selectedClient);
  const { filters } = useHcpLandscapeState();
  const [initTab] = useQueryParams("tab", defaultTab);
  const config = useClientConfiguration(selectedClient);
  const tabs = useTabs(initTab);
  const defaultDataWindow =
    (dataWindows || []).find((dataWindow) => dataWindow === DataWindow.twelveMonths) || DataWindow.twelveMonths;
  const timePeriod = filters.timePeriod || defaultDataWindow;
  const { data, isLoading } = useCCMessages(
    selectedClient,
    id,
    timePeriod,
    hcpQuery.data,
    tabs.currentTab === CCTabNames.PRINT_MESSAGES,
  );

  const hcpMessageLink = "hcpMessageLink" as const;
  const columns = [
    {
      label: t("hcpProfile.category"),
      align: "left",
      dataKey: "messageName",
      tdClasses: "sm:bg-light-blue",
      component: hcpMessageLink,
    },
    {
      label: t(`hcpProfile.repMessage.${selectedClient}`),
      align: "left",
      dataKey: "description",
    },
  ];

  const CCTable = () => <SimpleTable columns={columns} data={data} loading={isLoading} estimatedRows={4} />;

  const showTabs = config.print?.hcpPrintMessages && config.print.hcpEmailMessages;

  return (
    <div className="pb-6">
      <TableHeader title={t("hcpProfile.costAndCoverageMessages")}>
        <Tooltip
          tip={t(
            `hcpProfile.costAndCoverageMessagesTooltip${
              tabs.currentTab === CCTabNames.PRINT_MESSAGES ? "Print" : "Email"
            }.${selectedClient}`,
          )}
        >
          <InfoOutlined className="text-primary" />
        </Tooltip>
      </TableHeader>
      {showTabs && (
        <>
          <div className="flex sm:border-b border-rules">
            <Tab tabName={CCTabNames.PRINT_MESSAGES} {...tabs}>
              <AddToPrintQueueIcon className="mr-1" /> {t("hcpProfile.costAndCoveragePrintTab")}
            </Tab>
            <Tab tabName={CCTabNames.EMAIL_MESSAGES} {...tabs}>
              <MailOutlineIcon className="mr-1 stroke-current" /> {t("hcpProfile.costAndCoverageEmailTab")}
            </Tab>
          </div>
          <TabPanel tabName={CCTabNames.PRINT_MESSAGES} {...tabs}>
            <CCTable key="costAndCoveragePrintMessages" />
          </TabPanel>
          <TabPanel tabName={CCTabNames.EMAIL_MESSAGES} {...tabs}>
            <CCTable key="costAndCoverageEmailMessages" />
          </TabPanel>
        </>
      )}
      {!showTabs && <CCTable />}
    </div>
  );
};

export default CostAndCoverageMessages;
