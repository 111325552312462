import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { FRM_ROLE } from "../../../hooks/useGetRole";
import { useClientRoutesAndLabels } from "../../../ui/routes";
import { Column } from "../types";

interface Props {
  column: Column;
  data: {
    m_plan_name: string;
    m_plan_id: string;
    win_flag: boolean;
    pr_flag: boolean;
  };
  role: string | undefined;
}

const PlanIndicator: React.FC<Props> = ({ column, data, role }) => {
  const [t] = useTranslation();
  const { client: selectedClient, id } = useParams<{ client: string; id: string | undefined }>();
  const routes = useClientRoutesAndLabels(selectedClient);

  const PlanWin = () => <div title={t("table.planWin")} className="w-2 h-2 bg-primary rounded-sm"></div>;
  const PrintRestriction = () => (
    <div title={t("table.printRestriction")} className="w-2 h-2 bg-error rounded-sm"></div>
  );

  const path = id
    ? routes.client.dpp.payers.show.pinned.with({ client: selectedClient, id: data.m_plan_id, hcpID: id })
    : routes.client.dpp.payers.show.with({ client: selectedClient, id: data.m_plan_id });

  return (
    <div className="flex">
      <div className="w-4 flex flex-col space-y-1 justify-center">
        {data.win_flag && <PlanWin />}
        {data.pr_flag && <PrintRestriction />}
      </div>
      <div className="flex-grow">
        {role === FRM_ROLE ? (
          <span>{data.m_plan_name}</span>
        ) : (
          <Link className="font-bold text-primary hover:underline" to={path}>
            {data.m_plan_name}
          </Link>
        )}
      </div>
    </div>
  );
};

export default PlanIndicator;
