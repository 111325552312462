import FindInPageIcon from "@material-ui/icons/FindInPage";
import classNames from "classnames";
import { useSnackbar } from "notistack";
import React from "react";
import { useTranslation } from "react-i18next";
import AddToPrintQueueIcon from "../../components/Icons/AddToPrintQueueIcon";
import { useModal } from "../../components/Modal/useModal";
import SnackBar, { SnackVariant } from "../../components/SnackBar/SnackBar";
import { QueueItem } from "../../data/queue/types";
import { useQueueActions } from "../../data/queue/useQueueActions";
import { HCPSpecificMessage, Message } from "../../generate";
import MessagesSellSheetModal from "./MessagesSellSheetModal";

interface Props {
  client: string;
  hcp: HCPSpecificMessage;
  sellSheet: Message;
}

const MessagesSellSheetRow: React.FC<Props> = ({ client, hcp, sellSheet }) => {
  const [t] = useTranslation();
  const { setModal } = useModal();
  const { addUniqueQueueItemsToQueue } = useQueueActions();
  const { enqueueSnackbar } = useSnackbar();
  // const prefQuery = useGetPreferences();

  const addToPrintQueue = () => {
    if (!hcp.hcpName || !sellSheet.messageName) {
      enqueueSnackbar(<SnackBar message={t("errors.missingQueuData")} variant={SnackVariant.ERROR} />, {
        autoHideDuration: 3000,
      });
      return;
    }
    const showPI = true; //!prefQuery || !prefQuery.data || prefQuery.data.showPI === undefined ? true : prefQuery.data.showPI;
    const queueItem: QueueItem = {
      client,
      hcp: hcp.hcpName,
      prescID: hcp.prescID,
      displayName: sellSheet.messageName ?? "",
      baseDocName: sellSheet.messageName,
      includePI: showPI,
      isResource: false,
    };
    addUniqueQueueItemsToQueue([queueItem]);
  };

  const buttonClasses = classNames(
    "flex items-center justify-center h-10 w-10 text-primary rounded-full focus:outline-none focus:ring hover:bg-light-gray",
  );
  return (
    <div className="flex  flex-col-reverse sm:flex-row sm:items-center pt-1 hover:bg-light-blue -ml-3">
      <div className="pl-3 sm:pl-0 sm:w-56 flex space-x-1 border-b border-rules sm:border-none">
        <button
          className={buttonClasses}
          aria-label={t("specificMessages.downloadPrintPdf")}
          onClick={() => setModal(<MessagesSellSheetModal client={client} hcp={hcp} sellSheet={sellSheet} />)}
        >
          <FindInPageIcon />
        </button>

        <button
          onClick={() => addToPrintQueue()}
          aria-label={t("specificMessages.addToPrintQueue")}
          className={buttonClasses}
        >
          <AddToPrintQueueIcon />
        </button>
      </div>
      <div className="pl-6 sm:pl-3 py-2">
        <div className="text-xs sm:text-sm">{sellSheet.messageName}</div>
      </div>
    </div>
  );
};

export default MessagesSellSheetRow;
