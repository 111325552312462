import React from "react";
import { useTranslation } from "react-i18next";
import { Column } from "../types";

interface Props {
  column: Column;
  data: {
    m_plan_name: string;
    planWin: boolean;
    printRestriction: boolean;
  };
}

const PlanIndicator: React.FC<Props> = ({ column, data }) => {
  const [t] = useTranslation();
  const PlanWin = () => <div title={t("table.planWin")} className="w-2 h-2 bg-primary rounded-sm"></div>;
  const PrintRestriction = () => (
    <div title={t("table.printRestriction")} className="w-2 h-2 bg-error rounded-sm"></div>
  );
  return (
    <div className="flex">
      <div className="w-4 flex flex-col space-y-1 justify-center">
        {data.planWin && <PlanWin />}
        {data.printRestriction && <PrintRestriction />}
      </div>
      <div className="flex-grow">{data.m_plan_name}</div>
    </div>
  );
};

export default PlanIndicator;
