import { Auth0Provider } from "@developertown/oidc-provider";
import React from "react";
import { CustomWindow } from "./env";

const AuthenticationProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const runtime: CustomWindow = window;
  return (
    <Auth0Provider
      domain={runtime.env?.REACT_APP_AUTH0_DOMAIN || "not set"}
      audience={runtime.env?.REACT_APP_AUTH0_AUDIENCE || "not set"}
      clientId={runtime.env?.REACT_APP_AUTH0_CLIENT_ID || "not set"}
      redirectUri={window.location.origin}
    >
      {children}
    </Auth0Provider>
  );
};

export default AuthenticationProvider;
