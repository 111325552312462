import fileSaver from "file-saver";
import { useMutation, useQuery } from "react-query";
import { CoverageStatus, HealthcareProvider, Message, PrescribingHabits, SortDirection } from "../generate";
import { HCPApi, HCPHCPProfileCoverageStatusExcelGetRequest } from "../generate/apis/HCPApi";
import { call } from "./api";
import { DataWindow } from "./useMetaDataApi";

export const useHealthCareProvider = (client: string, id: string) => {
  const fetch = async () => {
    const { data } = await call(HCPApi).hCPHCPProfileHealthcareProviderGet({
      client,
      iD: id,
      timePeriod: DataWindow.twelveMonths, // TODO: not really needed since the data here is not time dependent
    });
    return data;
  };
  type ApiResponse = HealthcareProvider | undefined;
  return useQuery<ApiResponse, Error>(["hcp-healthcare-provider", client, id], fetch, {
    enabled: Boolean(client),
  });
};

export const usePrescribingHabits = (client: string, id: string, timePeriod: string, indication?: string) => {
  const fetch = async () => {
    const { data } = await call(HCPApi).hCPHCPProfilePrescribingHabitsGet({
      client,
      iD: id,
      timePeriod,
      indication,
    });
    return data;
  };
  type ApiResponse = PrescribingHabits[] | null | undefined;
  return useQuery<ApiResponse, Error>(["hcp-prescribing-habits", client, id, timePeriod, indication], fetch, {
    enabled: Boolean(client),
  });
};

export const useCCMessages = (
  client: string,
  id: string,
  timePeriod: string,
  hcp?: HealthcareProvider,
  isPrint = true,
) => {
  const fetch = async () => {
    const { data } = await call(HCPApi).hCPHCPProfileCCMessagesGet({
      client,
      iD: id,
      isPrint,
    });
    const formattedData = data?.map((message) => ({ ...message, hcp: hcp, isPrint }));
    return formattedData;
  };
  type ApiResponse = Message[] | null | undefined;
  return useQuery<ApiResponse, Error>(["hcp-cc-messages", client, id, hcp, isPrint], fetch, {
    enabled: !!hcp,
    select: (data) => {
      return (data || []).sort((a, b) => {
        const aMessage = a.messageName || "";
        const bMessage = b.messageName || "";
        return aMessage.localeCompare(bMessage);
      });
    },
  });
};

export const useCoverageStatus = (
  client: string,
  id: string,
  timePeriod: string,
  sortSortField?: string,
  sortSortDirection?: SortDirection,
  indication?: string,
) => {
  const fetch = async () => {
    const { data } = await call(HCPApi).hCPHCPProfileCoverageStatusGet({
      client,
      iD: id,
      timePeriod,
      sortSortField,
      sortSortDirection,
      indication,
    });
    return data;
  };
  type ApiResponse = CoverageStatus[] | null | undefined;
  return useQuery<ApiResponse, Error>(["hcp-coverage-status", client, id, timePeriod, indication], fetch, {
    enabled: Boolean(client),
  });
};

export const useHcpProfilePlanInfoExport = (
  columnKeys: string[],
  columnNames: string[],
  planID: string,
  client: string,
  timePeriod?: string,
  sortSortField?: string,
  sortSortDirection?: SortDirection,
  indication?: string,
) => {
  const requestParams: HCPHCPProfileCoverageStatusExcelGetRequest = {
    client,
    iD: planID,
    timePeriod,
    indication,
    sortSortField,
    sortSortDirection,
    columnKeys,
    columnNames,
  };
  return useMutation<void, Error>(async () => {
    const response = await call(HCPApi).hCPHCPProfileCoverageStatusExcelGetRaw({
      columnKeys,
      columnNames,
      ...requestParams,
    });
    const blob = await response.raw.blob();
    const file = new File([blob], "export.xlsx");
    fileSaver.saveAs(file);
  });
};
