import InfoOutlined from "@material-ui/icons/InfoOutlined";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useMetaData } from "../../api/useMetaDataApi";
import { usePayerLandscapeDetail, usePayerLandscapeDetailDetailExport } from "../../api/usePayerLandscapeDetailApi";
import Table from "../../components/Table/Table";
import { Column } from "../../components/Table/types";
import Tooltip from "../../components/Tooltip/Tooltip";
import { useClientDetailTabColumnConfiguration } from "../../data/global/useProductConfiguration";
import { usePayerLandscapeDispatch, usePayerLandscapeState } from "../../data/payerLandscape/hooks";
import { PlanFeed, SortDirection } from "../../generate";
import useGetRole, { FRM_ROLE } from "../../hooks/useGetRole";
import useHasFootnote from "../../hooks/useHasFootnote";
import PrintWinLegend from "./PrintWinLegend";
import useDetailColumns from "./useDetailColumns";

const frmExcluded = ["mkt_trx", "prod_trx", "mkt_share", "share_delta"];

const PayerLandscapeDetailTab: React.FC = () => {
  const { t, i18n } = useTranslation();
  const {
    detailSort,
    sharedFilters: { timePeriod },
  } = usePayerLandscapeState();
  const { updateDetailSort } = usePayerLandscapeDispatch();
  const { client: selectedClient } = useParams<{ client: string }>();
  const { data: role } = useGetRole(selectedClient);
  const isFRM = String(role) === FRM_ROLE;
  const useDefault = i18n.exists(`payerLandscape.coverageStatus.${selectedClient}`) ? false : true;
  const { detailColumns } = useDetailColumns(selectedClient, useDefault ? "default" : selectedClient);
  const roleColumns = isFRM ? detailColumns.filter((column) => !frmExcluded.includes(column.dataKey)) : detailColumns;
  const hasFootnote = useHasFootnote(selectedClient);

  const { dataKeys: allowedDataKeys } = useClientDetailTabColumnConfiguration(selectedClient);

  const columns: Column[] = useMemo(() => {
    return roleColumns.filter(({ dataKey }) => allowedDataKeys?.includes(dataKey as keyof PlanFeed));
  }, [roleColumns, allowedDataKeys]);
  const columnNames = columns.map((column) => t(column.label, column.translationParams));
  const columnKeys = columns.map((column) => column.dataKey);

  //QUERY DATA
  const detailQuery = usePayerLandscapeDetail(selectedClient);
  const exportTable = usePayerLandscapeDetailDetailExport(selectedClient, columnKeys, columnNames);
  const { data: metaData } = useMetaData(selectedClient, timePeriod || "");

  useEffect(() => {
    if (isFRM) {
      updateDetailSort({ field: "mkt_trx", direction: SortDirection.Descending });
    }
  }, [isFRM, updateDetailSort]);

  return (
    <>
      <div className="flex flex-row-reverse mb-2">
        {(i18n.exists(`payerLandscape.tooltip.${selectedClient}`) || isFRM) && (
          <Tooltip
            placement="bottom-end"
            tip={
              isFRM
                ? (t("payerLandscape.frmTooltip") as string)
                : (t(`payerLandscape.tooltip.${selectedClient}`) as string)
            }
          >
            <InfoOutlined className="text-primary" />
          </Tooltip>
        )}
      </div>
      <Table
        key="detailTable"
        columns={columns}
        query={detailQuery}
        sort={detailSort}
        stickyMargin={54}
        handleSort={(sort) => updateDetailSort(sort)}
        export={exportTable}
        legend={<PrintWinLegend />}
        footnote={t([
          `payerLandscape.detailedViewFootnote.${selectedClient}`,
          `payerLandscape.detailedViewFootnote.default`,
        ])}
        boldFootnote={selectedClient === "CIBINQO"}
        metaData={metaData}
        role={String(role)}
      />
    </>
  );
};

export default PayerLandscapeDetailTab;
