import { useTranslation as translate } from "react-i18next";
import { Route, route } from "static-route-paths";
import { useClientRouteOverrides } from "../data/global/useProductConfiguration";
import _ from "lodash";

const defaultRoutes = route({
  noAccess: route("no-access"),
  siteUnderMaintenance: route("maintenance"),
  client: route(":client", {
    dpp: route("dpp", {
      payers: route("payers", {
        show: route(":id", {
          providers: route("providers"),
          pinned: route(":hcpID", {
            providers: route("providers"),
          }),
        }),
      }),
      geo: route("geo", {
        show: route(":state"),
      }),
      hcps: route("hcps", {
        show: route(":id"),
      }),
    }),
    print: route("print", {
      messages: route("messages"),
      resources: route("resources"),
      queue: route("queue"),
      email: route("email"),
    }),
    profile: route("profile"),
    howTo: route("how-to"),
  }),
});

type RouteTree = {
  path?: string;
  label?: string;
  [key: string]: RouteTree | string | undefined;
};

type RouteWithLabel = Route & { label?: string };

type DefaultRoute = RouteWithLabel & {
  noAccess: RouteWithLabel & "no-access";
  siteUnderMaintenance: RouteWithLabel & "maintenance";
  client: RouteWithLabel & {
    dpp: RouteWithLabel & {
      payers: RouteWithLabel & {
        show: RouteWithLabel & {
          providers: RouteWithLabel & "providers";
          pinned: RouteWithLabel & {
            providers: RouteWithLabel & "providers";
          };
        };
      };
      geo: RouteWithLabel & {
        show: RouteWithLabel & ":id";
      };
      hcps: RouteWithLabel & {
        show: RouteWithLabel & ":id";
      };
    };
    print: RouteWithLabel & {
      messages: RouteWithLabel & "messages";
      resources: RouteWithLabel & "resources";
      queue: RouteWithLabel & "queue";
      email: RouteWithLabel & "email";
    };
    profile: RouteWithLabel & "profile";
    howTo: RouteWithLabel & "how-to";
  };
};

const convertRouteTreeToStaticRoutesWithLabels = (routeTree: RouteTree): RouteWithLabel => {
  const path = routeTree.path;
  const label = routeTree.label;

  const routeTreeCopy: { [key: string]: Route | RouteTree | string | undefined } = { ...routeTree };
  delete routeTreeCopy.path;
  delete routeTreeCopy.label;

  Object.keys(routeTreeCopy).forEach((key) => {
    routeTreeCopy[key] = convertRouteTreeToStaticRoutesWithLabels(routeTreeCopy[key] as RouteTree);
  });

  let routeWithLabel;

  if (path && !_.isEmpty(routeTreeCopy)) {
    routeWithLabel = route(path, routeTreeCopy);
  } else if (path) {
    routeWithLabel = route(path);
  } else {
    routeWithLabel = route(routeTreeCopy);
  }

  routeWithLabel = routeWithLabel as RouteWithLabel;

  routeWithLabel.label = label || "";

  return routeWithLabel;
};

const useClientRoutesAndLabels = (client?: string): DefaultRoute => {
  const { i18n } = translate();
  let [t] = translate();
  if (!client || client === ":client") {
    t = () => "";
  }
  const routeOverrides = useClientRouteOverrides(client);
  const DEFAULT_ROUTE_TREE: RouteTree = {
    noAccess: {
      path: "no-access",
    },
    siteUnderMaintenance: {
      path: "maintenance",
    },
    client: {
      path: ":client",
      dpp: {
        path: "dpp",
        label: t("common.payerPortal"),
        payers: {
          path: "payers",
          label: t("common.payerLandscape"),
          show: {
            path: ":id",
            label: t("common.planProfile"),
            providers: {
              path: "providers",
              label: t("common.healthcareProviders"),
            },
            pinned: {
              path: ":hcpID",
              providers: {
                path: "providers",
              },
            },
          },
        },
        geo: {
          path: "geo",
          label: t("common.geographicLandscape"),
          show: {
            path: ":state",
            label: t("common.geographicProfile"),
          },
        },
        hcps: {
          path: "hcps",
          label: i18n.exists(`common.hcpLandscape.${client}`)
            ? t(`common.hcpLandscape.${client}`)
            : t(`common.hcpLandscape.default`),
          show: {
            path: ":id",
            label: i18n.exists(`common.hcpProfile.${client}`)
              ? t(`common.hcpProfile.${client}`)
              : t(`common.hcpProfile.default`),
          },
        },
      },
      print: {
        path: "print",
        label: i18n.exists(`common.ccPortal.${client}`) ? t(`common.ccPortal.${client}`) : t(`common.ccPortal.default`),
        messages: {
          path: "messages",
          label: i18n.exists(`common.hcpPrintMessages.${client}`)
            ? t(`common.hcpPrintMessages.${client}`)
            : t(`common.hcpPrintMessages.default`),
        },
        resources: {
          path: "resources",
          label: t("common.resourceLibrary"),
        },
        queue: {
          path: "queue",
          label: t("common.printQueue"),
        },
        email: {
          path: "email",
          label: i18n.exists(`common.hcpPrintEmail.${client}`)
            ? t(`common.hcpPrintEmail.${client}`)
            : t(`common.hcpPrintEmail.default`),
        },
      },
      profile: {
        path: "profile",
        label: t("common.userProfile"),
      },
      howTo: {
        path: "how-to",
      },
    },
  };

  const clientRouteTree: RouteTree = _.merge(DEFAULT_ROUTE_TREE, routeOverrides);

  return convertRouteTreeToStaticRoutesWithLabels(clientRouteTree) as DefaultRoute;
};

export { defaultRoutes, useClientRoutesAndLabels };
