/* tslint:disable */
/* eslint-disable */
/**
 * Milliman.PullThroughPortal.WebAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface Territory
 */
export interface Territory {
  /**
   *
   * @type {string}
   * @memberof Territory
   */
  name?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof Territory
   */
  hasAccess?: boolean;
}

export function TerritoryFromJSON(json: any): Territory {
  return TerritoryFromJSONTyped(json, false);
}

export function TerritoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): Territory {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: !exists(json, "name") ? undefined : json["name"],
    hasAccess: !exists(json, "hasAccess") ? undefined : json["hasAccess"],
  };
}

export function TerritoryToJSON(value?: Territory | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    hasAccess: value.hasAccess,
  };
}
