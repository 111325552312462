import "@fontsource/open-sans/400.css";
import "@fontsource/open-sans/600.css";
import "@fontsource/open-sans/700.css";
import { CircularProgress } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import { CombinedClassKey, SnackbarOrigin, SnackbarProvider } from "notistack";
import React from "react";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import { createRoot } from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import AuthenticationProvider from "./authenticationProvider";
import { ModalProvider } from "./components/Modal/useModal";
import RenderCustomSnackBar from "./components/SnackBar/RenderCustomSnackBar";
import { PrintMessagesProvider } from "./data/messages/context";
import i18n from "./i18n";
import { configureStore } from "./store";
import "./styles/site.css";
import theme from "./styles/theme";
import App from "./ui/App";

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false, staleTime: 90000, refetchOnMount: false } },
});

const { store, persistor } = configureStore();

//Snackbar Settings
const snackBarPosition: SnackbarOrigin = {
  vertical: "top",
  horizontal: "right",
};
const snackBarClasses: Partial<Record<CombinedClassKey, string>> = {
  containerRoot: "mt-12",
};

const Loading: React.FC = () => {
  return (
    <div className="flex h-screen justify-center items-center">
      <CircularProgress size={80} />
    </div>
  );
};

const Root: React.FC = () => {
  return (
    <React.StrictMode>
      <React.Suspense fallback={<Loading />}>
        <I18nextProvider i18n={i18n}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Provider store={store}>
              <PersistGate loading={<Loading />} persistor={persistor}>
                <QueryClientProvider client={queryClient}>
                  <SnackbarProvider
                    maxSnack={4}
                    classes={snackBarClasses}
                    anchorOrigin={snackBarPosition}
                    content={(key, component) => <RenderCustomSnackBar id={key} Component={component} />}
                  >
                    <ModalProvider>
                      <AuthenticationProvider>
                        <PrintMessagesProvider>
                          <App />
                        </PrintMessagesProvider>
                      </AuthenticationProvider>
                    </ModalProvider>
                  </SnackbarProvider>
                  <ReactQueryDevtools initialIsOpen={false} />
                </QueryClientProvider>
              </PersistGate>
            </Provider>
          </ThemeProvider>
        </I18nextProvider>
      </React.Suspense>
    </React.StrictMode>
  );
};

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(<Root />);
