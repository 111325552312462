import React from "react";
import { useParams } from "react-router-dom";
import { HealthcareProvider, Message } from "../../../generate";
import MessagesSellSheetModal from "../../../pages/Messages/MessagesSellSheetModal";
import { useModal } from "../../Modal/useModal";
import { Column } from "../types";
import EmailSellSheetModal from "../../../pages/Email/EmailSellSheetModal";

interface Props {
  column: Column;
  data: Message & { hcp?: HealthcareProvider; isPrint: boolean };
}

const HcpMessageLink: React.FC<Props> = ({ column, data }) => {
  const { setModal } = useModal();
  const { client: selectedClient } = useParams<{ client: string }>();
  const openSellSheetModal = () => {
    if (!data.hcp) {
      return;
    }
    const hcpData = {
      hcpName: `${data.hcp.first_name === undefined ? "" : data.hcp.first_name} ${
        data.hcp.last_name === undefined ? "" : data.hcp.last_name
      }`,
      prescID: data.hcp.pfz_presc_id,
    };

    if (data.isPrint) {
      setModal(<MessagesSellSheetModal client={selectedClient} hcp={hcpData} sellSheet={data} />);
    } else {
      setModal(<EmailSellSheetModal client={selectedClient} hcp={hcpData} sellSheet={data} />);
    }
  };

  return (
    <button onClick={openSellSheetModal} className="font-bold text-primary hover:underline">
      {data.messageName}
    </button>
  );
};

export default HcpMessageLink;
