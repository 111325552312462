import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { DataWindow, useDataWindows, useMetaData } from "../../api/useMetaDataApi";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import PageHeader from "../../components/Headers/PageHeader";
import MinimalSelect from "../../components/Inputs/MinimalSelect";
import Select from "../../components/Inputs/Select";
import LastUpdate from "../../components/LastUpdate/LastUpdate";
import {
  useClientConfiguration,
  useClientHCPProfileConfiguration,
  useClientIndicationFiltersconfiguration,
} from "../../data/global/useProductConfiguration";
import { useHcpLandscapeDispatch, useHcpLandscapeState } from "../../data/hcpLandscape/hooks";
import CostAndCoverageMessages, { CCTabNames } from "./CostAndCoverageMessages";
import HCPProviderCard from "./HCPProviderCard";
import PlanInformation from "./PlanInformation";
import PrescribingHabits from "./PrescribingHabits";

const HCPProfile: React.FC = () => {
  const { t } = useTranslation();
  const { client: selectedClient } = useParams<{ client: string }>();
  const { data: metaData } = useMetaData(selectedClient);
  const {
    costAndCoverageMessages: showCostAndCoverageMessages,
    indicationFiltering: showIndicationFiltering,
    hideHabits,
  } = useClientHCPProfileConfiguration(selectedClient);
  const { print } = useClientConfiguration(selectedClient);
  const { filters } = useHcpLandscapeState();
  const { updateFilters } = useHcpLandscapeDispatch();
  const { data: dataWindows } = useDataWindows(selectedClient);
  const footnote = t([`hcpProfile.footnote.${selectedClient}`, `hcpProfile.footnote.default`]);
  const timePeriodOptions = (dataWindows || []).map((dataWindow) => {
    return {
      label: t(`payerLandscape.timePeriodOptions.${dataWindow}`),
      value: dataWindow,
    };
  });
  const defaultDataWindow =
    (dataWindows || []).find((dataWindow) => dataWindow === DataWindow.twelveMonths) || DataWindow.twelveMonths;
  const showTimePeriodSelection = (dataWindows || []).length > 1;
  const indicationOptions = useClientIndicationFiltersconfiguration(selectedClient);

  //IF INDICATION SET, BUT SHOULDNT BE, SET TO UNDEFINED
  useEffect(() => {
    if (!showIndicationFiltering) {
      updateFilters({ indication: undefined });
    }
  }, [updateFilters, indicationOptions, filters.indication, showIndicationFiltering]);

  //IF NO INDICATION SET, SET TO FIRST PRODUCT AVAILABLE
  useEffect(() => {
    if (
      indicationOptions &&
      showIndicationFiltering &&
      indicationOptions !== undefined &&
      indicationOptions.length > 0 &&
      filters.indication === undefined
    ) {
      updateFilters({ indication: indicationOptions[0].value });
    }
  }, [updateFilters, filters.indication, indicationOptions, showIndicationFiltering]);

  //Set the default timePeriod to last 12 months
  useEffect(() => {
    if (!filters.timePeriod && (dataWindows || []).length > 0) {
      updateFilters({ timePeriod: defaultDataWindow });
    }
  }, [dataWindows, defaultDataWindow, filters.timePeriod, updateFilters]);

  //Scroll to the top when changing routes
  const location = useLocation<{ isOnlyQueryChange?: boolean }>();
  useEffect(() => {
    if (location.state && location.state.isOnlyQueryChange) return;
    window.scrollTo(0, 0);
  }, [location.pathname, location.state]);

  const hasOnlyEmailMessages = print?.hcpEmailMessages && !print.hcpPrintMessages;

  return (
    <>
      <PageHeader title={t([`common.hcpProfile.${selectedClient}`, `common.hcpProfile.default`])} />
      <BreadCrumbs />

      <HCPProviderCard />
      <div className="flex flex-col lg:flex-row-reverse justify-between">
        <div className="lg:w-1/3">
          {showTimePeriodSelection ? (
            <Select
              value={filters.timePeriod}
              label={t("payerLandscape.timePeriod")}
              options={timePeriodOptions}
              handleChange={(value) => updateFilters({ timePeriod: value })}
            />
          ) : null}
        </div>
        {indicationOptions && showIndicationFiltering && (
          <MinimalSelect
            value={filters.indication}
            handleChange={(value) => updateFilters({ indication: value })}
            label={t("payerLandscape.planInfoFor")}
            options={indicationOptions}
          />
        )}
      </div>
      {!hideHabits && <PrescribingHabits indication={filters.indication || undefined} />}
      {showCostAndCoverageMessages && (
        <CostAndCoverageMessages
          defaultTab={hasOnlyEmailMessages ? CCTabNames.EMAIL_MESSAGES : CCTabNames.PRINT_MESSAGES}
        />
      )}
      <PlanInformation indication={filters.indication || undefined} />
      {footnote && <p className="text-xs text-slate-gray pt-2 whitespace-pre-line leading-6 mb-1">{footnote}</p>}
      {metaData && <LastUpdate className={"pb-6"} {...metaData} />}
    </>
  );
};

export default HCPProfile;
