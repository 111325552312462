/* eslint @typescript-eslint/no-var-requires: "off" */
import { createTheme } from "@material-ui/core/styles";
const colors = require("./colors");

const theme = createTheme({
  palette: {
    background: {
      default: colors.white,
    },
    common: {
      black: colors.black,
      white: colors.white,
    },
    primary: {
      main: colors.primary,
      contrastText: colors.white,
    },
    secondary: {
      main: colors.brand,
      contrastText: colors.white,
      dark: colors.brandDark,
    },
    error: {
      main: colors.error,
      contrastText: colors.white,
    },
  },
  overrides: {},
});

export default theme;
