import React from "react";
import { useTranslation } from "react-i18next";
import { ButtonColor } from "../../tenants/default";
import ModalHeader from "../Headers/ModalHeader";
import { useModal } from "./useModal";

interface Props {
  title: string;
  children: React.ReactNode;
  confirmButtonText: string;
  confirmButtonColor: ButtonColor;
  cancelButtonText?: string;
  onConfirm: () => void;
}

const ConfirmationModal: React.FC<Props> = ({
  title,
  children,
  confirmButtonText,
  cancelButtonText,
  confirmButtonColor,
  onConfirm,
}) => {
  const [t] = useTranslation();
  const { setModal } = useModal();

  return (
    <div className=" sm:max-w-sm">
      <ModalHeader title={title} />
      <div className="">{children}</div>

      <div className="flex flex-col sm:flex-row justify-end space-y-3 sm:space-y-0  sm:space-x-3  mt-3 sm:mt-6">
        <button onClick={() => setModal(undefined)} className="btn btn-rules">
          {cancelButtonText ? cancelButtonText : t("common.cancel")}
        </button>
        <button onClick={() => onConfirm()} className={`btn btn-${confirmButtonColor}`}>
          {confirmButtonText}
        </button>
      </div>
    </div>
  );
};

export default ConfirmationModal;
