import BusinessIcon from "@material-ui/icons/Business";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { DataWindow, useDataWindows, useMetaData } from "../../api/useMetaDataApi";
import {
  useAdditionalCoverageUrl,
  useMarketSummary,
  usePlanData,
  useTopHealthcareProviders,
} from "../../api/usePayerProfileApi";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import GeoSelect from "../../components/GeoSelect/GeoSelect";
import PageHeader from "../../components/Headers/PageHeader";
import MinimalSelect from "../../components/Inputs/MinimalSelect";
import Select from "../../components/Inputs/Select";
import LastUpdate from "../../components/LastUpdate/LastUpdate";
import { useModal } from "../../components/Modal/useModal";
import {
  useClientConfiguration,
  useClientIndicationFiltersconfiguration,
  useClientPlanProfileConfiguration,
  useClientPlanProfileMarketSummaryConfiguration,
} from "../../data/global/useProductConfiguration";
import { usePayerLandscapeDispatch, usePayerLandscapeState } from "../../data/payerLandscape/hooks";
import { useProducts } from "../../hooks/useProducts";
import MarketSummaryTable from "./MarketSummaryTable";
import PbmModal from "./PbmModal";
import PlanDataTable from "./PlanDataTable";
import TopHealthcareProviders from "./TopHealthcareProviders";

const PlanProfile: React.FC = () => {
  const { t } = useTranslation();
  const { setModal } = useModal();
  const { client: selectedClient, id, hcpID } = useParams<{ client: string; id: string; hcpID: string | undefined }>();
  const { data: metaData } = useMetaData(selectedClient);
  const { pharmacyBenefitManagers: showPharmacyBenefitManagers, indicationFiltering: showIndicationFiltering } =
    useClientPlanProfileConfiguration(selectedClient);
  const { dataKeys: allowedMarketSummaryDataKeys } = useClientPlanProfileMarketSummaryConfiguration(selectedClient);
  const { sharedFilters, detailFilters } = usePayerLandscapeState();
  const { updateDetailFilters, updateSharedFilters } = usePayerLandscapeDispatch();
  const { data: dataWindows } = useDataWindows(selectedClient);
  const timePeriodOptions = (dataWindows || []).map((dataWindow) => {
    return {
      label: t(`payerLandscape.timePeriodOptions.${dataWindow}`),
      value: dataWindow,
    };
  });
  const defaultDataWindow =
    (dataWindows || []).find((dataWindow) => dataWindow === DataWindow.twelveMonths) || DataWindow.twelveMonths;
  const showTimePeriodSelection = (dataWindows || []).length > 1;
  const planData = usePlanData(
    selectedClient,
    id,
    sharedFilters.timePeriod || defaultDataWindow,
    detailFilters.indication || undefined,
  );

  const { products: allowedPlanInfoProducts } = useClientConfiguration(selectedClient);
  const products = useProducts(selectedClient);
  const allProducts = products.data || [];
  const planInfoProducts = allProducts.filter((product) => allowedPlanInfoProducts?.includes(product));

  const topHCPStateInformation = {
    client: selectedClient,
    id: id,
    timePeriod: sharedFilters.timePeriod || defaultDataWindow,
    indication: detailFilters.indication || undefined,
  };

  const topHealthCareProviders = useTopHealthcareProviders(
    selectedClient,
    id,
    sharedFilters.timePeriod || defaultDataWindow,
    hcpID as string,
    detailFilters.indication || undefined,
  );
  const marketSummary = useMarketSummary(
    selectedClient,
    id,
    sharedFilters.timePeriod || defaultDataWindow,
    detailFilters.indication || undefined,
  );
  const footnote = t([`planProfile.footnote.${selectedClient}`, `planProfile.footnote.default`]);

  const indicationOptions = useClientIndicationFiltersconfiguration(selectedClient);

  const additionalCoverageUrl = useAdditionalCoverageUrl(
    selectedClient,
    id,
    detailFilters.indication ? detailFilters.indication : undefined,
  );

  //IF INDICATION SET, BUT SHOULDNT BE, SET TO UNDEFINED
  useEffect(() => {
    if (!showIndicationFiltering) {
      updateDetailFilters({ indication: undefined });
    }
  }, [updateDetailFilters, indicationOptions, detailFilters.indication, showIndicationFiltering]);

  //IF NO INDICATION SET, SET TO FIRST PRODUCT AVAILABLE
  useEffect(() => {
    if (
      indicationOptions &&
      showIndicationFiltering &&
      indicationOptions !== undefined &&
      indicationOptions.length > 0 &&
      detailFilters.indication === undefined
    ) {
      updateDetailFilters({ indication: indicationOptions[0].value });
    }
  }, [updateDetailFilters, indicationOptions, detailFilters.indication, showIndicationFiltering]);

  //IF NO PRODUCT SET, SET TO FIRST PRODUCT AVAILABLE
  useEffect(() => {
    if (planInfoProducts.length > 0 && detailFilters.product === "") {
      updateDetailFilters({ product: planInfoProducts[0] });
    }
  }, [updateDetailFilters, planInfoProducts, detailFilters.product]);

  //Set the default timePeriod to last 12 months
  useEffect(() => {
    if (!sharedFilters.timePeriod && (dataWindows || []).length > 0) {
      updateSharedFilters({ timePeriod: defaultDataWindow });
    }
  }, [dataWindows, defaultDataWindow, sharedFilters.timePeriod, updateSharedFilters]);

  return (
    <div>
      <PageHeader title={t("common.planProfile")}>
        {showPharmacyBenefitManagers && (
          <button onClick={() => setModal(<PbmModal />)} className="btn btn-primary w-full hidden">
            <span>{t("planProfile.viewPBM")}</span>
            <BusinessIcon />
          </button>
        )}
      </PageHeader>
      <BreadCrumbs />

      <div className="lg:flex lg:space-x-12 pb-4 lg:pb-0">
        <div className="mb-4 flex-row lg:w-1/3">
          <h2 className="text-brand header-3">{planData?.data?.planName ?? t("common.loading")}</h2>
          {additionalCoverageUrl.isLoading || additionalCoverageUrl.data === undefined ? (
            ""
          ) : (
            <a
              className="underline"
              href={additionalCoverageUrl.data ? additionalCoverageUrl.data : ""}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("planProfile.additionalInformation")}
            </a>
          )}
          {planData.data?.pbmVendor === undefined ? "" : planData.data?.pbmVendor}
        </div>

        <div className="flex flex-col lg:flex-row lg:w-2/3 lg:space-x-4">
          <div className="lg:w-3/12">
            {indicationOptions && showIndicationFiltering && (
              <MinimalSelect
                value={detailFilters.indication}
                handleChange={(value) => updateDetailFilters({ indication: value })}
                label={t("payerLandscape.indicationFor")}
                options={indicationOptions}
              />
            )}
          </div>
          <div className="lg:w-6/12">
            {showTimePeriodSelection ? (
              <Select
                value={sharedFilters.timePeriod}
                label={t("payerLandscape.timePeriod")}
                options={timePeriodOptions}
                handleChange={(value) => updateSharedFilters({ timePeriod: value })}
              />
            ) : null}
          </div>
          <div className="lg:w-6/12">
            <GeoSelect client={selectedClient} />
          </div>
        </div>
      </div>

      <div>
        <PlanDataTable client={selectedClient} loading={planData.isLoading} planData={planData.data} />
        <MarketSummaryTable
          client={selectedClient}
          loading={marketSummary.isLoading}
          marketData={marketSummary.data}
          displayKeys={allowedMarketSummaryDataKeys}
        />
        <TopHealthcareProviders
          channel={topHealthCareProviders.data ? (topHealthCareProviders.data[0].channel as string) : ""}
          loading={detailFilters.product === "" ? true : topHealthCareProviders.isLoading}
          hcpData={topHealthCareProviders.data}
          stateInformation={topHCPStateInformation}
        />
      </div>
      {footnote && <p className="text-xs text-slate-gray pt-2 whitespace-pre-line leading-6 mb-1">{footnote}</p>}
      {metaData && <LastUpdate className={"pb-6"} {...metaData} />}
    </div>
  );
};

export default PlanProfile;
