import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store/types";
import { clearAdvancedFilters, updateFilters, updateSort } from "./actions";
import { HcpLandscapeDispatch, HcpLandscapeState } from "./types";

export const useHcpLandscapeState = (): HcpLandscapeState =>
  useSelector(({ hcpLandscape }: AppState) => {
    return hcpLandscape;
  });

export const useHcpLandscapeDispatch = (): HcpLandscapeDispatch => {
  const dispatch = useDispatch();
  return useMemo(
    (): HcpLandscapeDispatch => ({
      updateFilters: (filters) => dispatch(updateFilters(filters)),
      clearAdvancedFilters: () => dispatch(clearAdvancedFilters()),
      updateSort: (sort) => dispatch(updateSort(sort)),
    }),
    [dispatch],
  );
};
