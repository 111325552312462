import React from "react";
import { useTranslation } from "react-i18next";

const NoResultsFound: React.FC = () => {
  const [t] = useTranslation();

  return (
    <div className="flex flex-col justify-center items-center w-full mt-20">
      <p className="body-1 text-brand">{t("common.noResultsFound")}</p>
      <p className="body-2 text-slate-gray mt-2">{t("common.adjustSearch")}</p>
    </div>
  );
};

export default NoResultsFound;
