import MailOutlineIcon from "@material-ui/icons/MailOutline";
import classNames from "classnames";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { pdfjs } from "react-pdf";
import { HCPSpecificMessage, Message, PrintDoc } from "../../generate";
import SellSheetModal from "../../components/SellSheetModal/SellSheetModal";
import { useDownloadEmailTemplate } from "../../api/useDownloadEmailTemplate";
import { useClientPrintConfiguration } from "../../data/global/useProductConfiguration";

pdfjs.GlobalWorkerOptions.workerSrc = require("pdfjs-dist/build/pdf.worker.entry.js");

interface Props {
  client: string;
  hcp: HCPSpecificMessage;
  sellSheet: Message;
}

const EmailSellSheetModal: React.FC<Props> = ({ client, hcp, sellSheet }) => {
  const [t] = useTranslation();
  const config = useClientPrintConfiguration(client);
  const [includePi, setIncludePi] = useState(config.defaultIsPIEmail);

  const printDoc: PrintDoc = {
    client,
    baseDocName: sellSheet.messageName,
    prescID: hcp.prescID,
    includePI: includePi,
    isResource: false,
  };

  const { isLoading: isDownloading, mutateAsync: downloadEmailTemplate } = useDownloadEmailTemplate(printDoc, hcp);

  const buttonClasses = classNames("btn btn-primary mb-6 md:mb-0 w-full", {
    "opacity-50 cursor-disabled": isDownloading,
  });

  return (
    <SellSheetModal
      client={client}
      hcp={hcp}
      sellSheet={sellSheet}
      showIsPI={config.useIsPIForEmailMessages}
      includePi={includePi}
      onIncludePiChange={(value) => setIncludePi(value)}
      isPrint={false}
    >
      <button disabled={isDownloading} className={buttonClasses} onClick={() => downloadEmailTemplate()}>
        <MailOutlineIcon />
        <span>{isDownloading ? t("loading") : t("specificMessages.downloadEmailTemplate")}</span>
      </button>
    </SellSheetModal>
  );
};

export default EmailSellSheetModal;
