/* tslint:disable */
/* eslint-disable */
/**
 * Milliman.PullThroughPortal.WebAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ComparisonFeed
 */
export interface ComparisonFeed {
  /**
   *
   * @type {string}
   * @memberof ComparisonFeed
   */
  m_plan_id?: string | null;
  /**
   *
   * @type {string}
   * @memberof ComparisonFeed
   */
  m_plan_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof ComparisonFeed
   */
  level?: string | null;
  /**
   *
   * @type {string}
   * @memberof ComparisonFeed
   */
  channel?: string | null;
  /**
   *
   * @type {string}
   * @memberof ComparisonFeed
   */
  geography?: string | null;
  /**
   *
   * @type {number}
   * @memberof ComparisonFeed
   */
  lives?: number | null;
  /**
   *
   * @type {string}
   * @memberof ComparisonFeed
   */
  client?: string | null;
  /**
   *
   * @type {string}
   * @memberof ComparisonFeed
   */
  prod1?: string | null;
  /**
   *
   * @type {string}
   * @memberof ComparisonFeed
   */
  prod1_status?: string | null;
  /**
   *
   * @type {string}
   * @memberof ComparisonFeed
   */
  prod2?: string | null;
  /**
   *
   * @type {string}
   * @memberof ComparisonFeed
   */
  prod2_status?: string | null;
  /**
   *
   * @type {string}
   * @memberof ComparisonFeed
   */
  prod3?: string | null;
  /**
   *
   * @type {string}
   * @memberof ComparisonFeed
   */
  prod3_status?: string | null;
  /**
   *
   * @type {string}
   * @memberof ComparisonFeed
   */
  prod4?: string | null;
  /**
   *
   * @type {string}
   * @memberof ComparisonFeed
   */
  prod4_status?: string | null;
  /**
   *
   * @type {string}
   * @memberof ComparisonFeed
   */
  prod5?: string | null;
  /**
   *
   * @type {string}
   * @memberof ComparisonFeed
   */
  prod5_status?: string | null;
  /**
   *
   * @type {string}
   * @memberof ComparisonFeed
   */
  prod6?: string | null;
  /**
   *
   * @type {string}
   * @memberof ComparisonFeed
   */
  prod6_status?: string | null;
  /**
   *
   * @type {string}
   * @memberof ComparisonFeed
   */
  win_flag?: string | null;
  /**
   *
   * @type {string}
   * @memberof ComparisonFeed
   */
  pr_flag?: string | null;
  /**
   *
   * @type {number}
   * @memberof ComparisonFeed
   */
  mkt_trx?: number | null;
  /**
   *
   * @type {string}
   * @memberof ComparisonFeed
   */
  indication?: string | null;
}

export function ComparisonFeedFromJSON(json: any): ComparisonFeed {
  return ComparisonFeedFromJSONTyped(json, false);
}

export function ComparisonFeedFromJSONTyped(json: any, ignoreDiscriminator: boolean): ComparisonFeed {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    m_plan_id: !exists(json, "m_plan_id") ? undefined : json["m_plan_id"],
    m_plan_name: !exists(json, "m_plan_name") ? undefined : json["m_plan_name"],
    level: !exists(json, "level") ? undefined : json["level"],
    channel: !exists(json, "channel") ? undefined : json["channel"],
    geography: !exists(json, "geography") ? undefined : json["geography"],
    lives: !exists(json, "lives") ? undefined : json["lives"],
    client: !exists(json, "client") ? undefined : json["client"],
    prod1: !exists(json, "prod1") ? undefined : json["prod1"],
    prod1_status: !exists(json, "prod1_status") ? undefined : json["prod1_status"],
    prod2: !exists(json, "prod2") ? undefined : json["prod2"],
    prod2_status: !exists(json, "prod2_status") ? undefined : json["prod2_status"],
    prod3: !exists(json, "prod3") ? undefined : json["prod3"],
    prod3_status: !exists(json, "prod3_status") ? undefined : json["prod3_status"],
    prod4: !exists(json, "prod4") ? undefined : json["prod4"],
    prod4_status: !exists(json, "prod4_status") ? undefined : json["prod4_status"],
    prod5: !exists(json, "prod5") ? undefined : json["prod5"],
    prod5_status: !exists(json, "prod5_status") ? undefined : json["prod5_status"],
    prod6: !exists(json, "prod6") ? undefined : json["prod6"],
    prod6_status: !exists(json, "prod6_status") ? undefined : json["prod6_status"],
    win_flag: !exists(json, "win_flag") ? undefined : json["win_flag"],
    pr_flag: !exists(json, "pr_flag") ? undefined : json["pr_flag"],
    mkt_trx: !exists(json, "mkt_trx") ? undefined : json["mkt_trx"],
    indication: !exists(json, "indication") ? undefined : json["indication"],
  };
}

export function ComparisonFeedToJSON(value?: ComparisonFeed | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    m_plan_id: value.m_plan_id,
    m_plan_name: value.m_plan_name,
    level: value.level,
    channel: value.channel,
    geography: value.geography,
    lives: value.lives,
    client: value.client,
    prod1: value.prod1,
    prod1_status: value.prod1_status,
    prod2: value.prod2,
    prod2_status: value.prod2_status,
    prod3: value.prod3,
    prod3_status: value.prod3_status,
    prod4: value.prod4,
    prod4_status: value.prod4_status,
    prod5: value.prod5,
    prod5_status: value.prod5_status,
    prod6: value.prod6,
    prod6_status: value.prod6_status,
    win_flag: value.win_flag,
    pr_flag: value.pr_flag,
    mkt_trx: value.mkt_trx,
    indication: value.indication,
  };
}
