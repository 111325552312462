import React from "react";

interface Props {
  title: string;
  children?: React.ReactNode;
}

const ModalHeader: React.FC<Props> = ({ title, children }) => {
  return (
    <div
      aria-label={title}
      className="border-b-2 border-rules md:flex justify-between items-center mb-4 pb-4 text-brand"
    >
      <h1 className="text-lg sm:text-2xl font-bold">{title}</h1>
      <div>{children}</div>
    </div>
  );
};

export default ModalHeader;
