import { saveAs } from "file-saver";
import { useMutation, UseMutationResult, useQuery } from "react-query";
import { AuditInfo, HCPSpecificMessage, PrintDocInfo } from "../generate";
import { PrintApi, PrintLogPrintDocPostRequest, PrintPrintDocPostRequest } from "../generate/apis";
import { call } from "./api";

export const usePrintDoc = (postData: PrintPrintDocPostRequest) => {
  const fetch = async () => {
    const { data } = await call(PrintApi).printPrintDocPost(postData);
    return data;
  };
  type ApiResponse = PrintDocInfo | undefined;
  return useQuery<ApiResponse, Error>(["print-doc", postData], fetch);
};

export const useDownloadDocument = (): UseMutationResult<
  AuditInfo,
  Error,
  PrintLogPrintDocPostRequest & { hcp: HCPSpecificMessage; url: string }
> => {
  const api = call(PrintApi);
  return useMutation<AuditInfo, Error, PrintLogPrintDocPostRequest & { hcp: HCPSpecificMessage; url: string }>(
    async (request: PrintLogPrintDocPostRequest & { hcp: HCPSpecificMessage; url: string }) => {
      const { url, hcp, ...postData } = request;
      const { data } = await api.printLogPrintDocPost(postData);
      const windowsInvalidFileNameChars = ['"', "<", ">", "|", "\0", ":", "*", "?", "\\", "/"];
      const unixInvalidFileNameChars = ["\0", "/"];
      const invalidCharacters = [...windowsInvalidFileNameChars, ...unixInvalidFileNameChars];
      const hcpName = hcp.hcpName?.trim();
      const fileName = hcpName
        ? `${postData.printDoc?.baseDocName}-${hcpName}.pdf`
        : `${postData.printDoc?.baseDocName}.pdf`;

      const validFileName = fileName
        .split("")
        .filter((c) => !invalidCharacters.includes(c))
        .join("");

      const response = await fetch(url);
      const blob = await response.blob();
      const file = new File([blob], validFileName, {
        type: "application/pdf",
      });

      saveAs(file, validFileName);
      return data!;
    },
  );
};
