import { CircularProgress } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, Redirect } from "react-router-dom";
import { usePayerLandscapeDispatch } from "../../data/payerLandscape/hooks";
import { useClientRoutesAndLabels } from "../../ui/routes";
import { useClientDropdown } from "../../api/useClientDropdown";

const Loading: React.FC = () => {
  return (
    <div className="flex h-screen justify-center items-center">
      <CircularProgress size={80} />
    </div>
  );
};

const PickAClient: React.FC = () => {
  const [t] = useTranslation();
  const { updateDetailFilters } = usePayerLandscapeDispatch();
  const { isLoading, data: clients, isError } = useClientDropdown();
  const routes = useClientRoutesAndLabels();

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <Redirect to={routes.noAccess.path} />;
  }

  if (clients?.length === 1) {
    return <Redirect to={routes.client.with({ client: clients[0] })} />;
  }

  return (
    <div className="w-3/4 mt-8 sm:-mt-6 px-4 flex flex-col max-h-screen">
      <div className="top-0 pt-8 pb-16">
        <h1 className="text-2xl sm:text-5xl font-semibold text-white text-center py-2">{t("pickAClient.title")}</h1>
        <h2 className="text-sm sm:text-2xl  text-white text-center">{t("pickAClient.subTitle")}</h2>
      </div>
      <div className="max-w-4xl mx-auto flex flex-wrap -mt-8">
        {clients?.map((client) => (
          <div key={client} className="w-full sm:w-1/2 px-4 py-4 h-40">
            <Link
              className={"bg-white shadow rounded h-full w-full flex justify-center items-center"}
              to={routes.client.with({ client: client })}
              onClick={() => {
                updateDetailFilters({ product: "" });
              }}
            >
              <img className="max-w-[200px]" alt={client} src={`/assets/logos/${client}.png`} />
            </Link>
          </div>
        ))}
        {clients?.length === 0 && (
          <div className="bg-white shadow max-w-4xl mx-auto mt-4 sm:mt-16">
            <div className="flex flex-col sm:flex-row space-y-10 sm:space-y-0 sm:space-x-10 px-12 py-12">
              <p>{t(`pickAClient.noProductsFound`)}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PickAClient;
