import actionCreatorFactory from "typescript-fsa";
import { GeoPayload } from "./types";

const createAction = actionCreatorFactory("GLOBAL");

export const updateSelectedGeo = createAction<GeoPayload>("UPDATE_SELECTED_GEO", (geoPayload: GeoPayload) => {
  return geoPayload;
});

export const updateRootGeo = createAction<GeoPayload>("UPDATE_ROOT_GEO", (geoPayload: GeoPayload) => {
  return geoPayload;
});

export const updateDefaultGeo = createAction<GeoPayload>("UPDATE_DEFAULT_GEO", (geoPayload: GeoPayload) => {
  return geoPayload;
});

export const updateGeoAccess = createAction<boolean>("UPDATE_GEO_ACCESS", (access: boolean) => {
  return access;
});

export const updateUserEmail = createAction<string>("UPDATE_USER_EMAIL", (email: string) => {
  return email;
});
