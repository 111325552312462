/* tslint:disable */
/* eslint-disable */
/**
 * Milliman.PullThroughPortal.WebAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import { PrintDoc, PrintDocFromJSON, PrintDocFromJSONTyped, PrintDocToJSON } from "./";

/**
 *
 * @export
 * @interface ZipPackageInput
 */
export interface ZipPackageInput {
  /**
   *
   * @type {Array<PrintDoc>}
   * @memberof ZipPackageInput
   */
  printDocs: Array<PrintDoc>;
  /**
   *
   * @type {string}
   * @memberof ZipPackageInput
   */
  client: string;
}

export function ZipPackageInputFromJSON(json: any): ZipPackageInput {
  return ZipPackageInputFromJSONTyped(json, false);
}

export function ZipPackageInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): ZipPackageInput {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    printDocs: (json["printDocs"] as Array<any>).map(PrintDocFromJSON),
    client: json["client"],
  };
}

export function ZipPackageInputToJSON(value?: ZipPackageInput | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    printDocs: (value.printDocs as Array<any>).map(PrintDocToJSON),
    client: value.client,
  };
}
