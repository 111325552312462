/* tslint:disable */
/* eslint-disable */
/**
 * Milliman.PullThroughPortal.WebAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface UserPreferences
 */
export interface UserPreferences {
  /**
   *
   * @type {string}
   * @memberof UserPreferences
   */
  email?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof UserPreferences
   */
  showPI?: boolean;
}

export function UserPreferencesFromJSON(json: any): UserPreferences {
  return UserPreferencesFromJSONTyped(json, false);
}

export function UserPreferencesFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserPreferences {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    email: !exists(json, "email") ? undefined : json["email"],
    showPI: !exists(json, "showPI") ? undefined : json["showPI"],
  };
}

export function UserPreferencesToJSON(value?: UserPreferences | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    email: value.email,
    showPI: value.showPI,
  };
}
