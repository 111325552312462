import { ClickAwayListener } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGeoTreeApi } from "../../api/useGeoTreeApi";
import { useGlobalDispatch, useGlobalState } from "../../data/global/hooks";
import { useClientDefaultGeographyLevelConfiguration } from "../../data/global/useProductConfiguration";
import { usePrintMessages } from "../../data/messages/context";
import GeoPopup from "./GeoPopup";
import { GeographyType, TreeData } from "./GeoTypes";

interface Props {
  client: string;
  requireTerritory?: boolean;
}

const getNamesFromGeoTree = (tree: TreeData[]): string[] => {
  const geoNames: string[] = [];

  tree.forEach((geo) => {
    geoNames.push(geo.name);
    geo.children && geoNames.push(...getNamesFromGeoTree(geo.children));
  });

  return geoNames;
};

const geoExistsInTree = (geo: string | null, tree: TreeData[], defaultGeographyLevel: GeographyType | null) => {
  if (geo === GeographyType.National) return defaultGeographyLevel === GeographyType.National;
  if (geo === null) return defaultGeographyLevel === null;

  const geoNames = getNamesFromGeoTree(tree);

  return geoNames.includes(geo);
};

const GeoSelect: React.FC<Props> = ({ client, requireTerritory }) => {
  const [t] = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const defaultGeographyLevel = useClientDefaultGeographyLevelConfiguration(client);
  const [, { setExpandedHCPProvider }] = usePrintMessages();
  const { data, isLoading } = useGeoTreeApi(client);
  const treeData = data === undefined ? [] : data;

  const { selectedGeoName, selectedGeoType, rootGeoName, rootGeoType, defaultGeoName, defaultGeoType } =
    useGlobalState();
  const { updateSelectedGeo } = useGlobalDispatch();
  const handleSelect = useCallback(
    (type: GeographyType | null, name: string | null) => {
      updateSelectedGeo({ type, name });
      setExpandedHCPProvider(null);
    },
    [setExpandedHCPProvider, updateSelectedGeo],
  );

  useEffect(() => {
    if (data && !geoExistsInTree(selectedGeoName, data, defaultGeographyLevel)) {
      if (defaultGeographyLevel === GeographyType.National && treeData[0].type === GeographyType.National) {
        handleSelect(GeographyType.National, GeographyType.National);
      } else if (defaultGeographyLevel === null) {
        handleSelect(null, null);
      } else {
        handleSelect(rootGeoType, rootGeoName);
      }
    }
  }, [selectedGeoName, data, handleSelect, defaultGeographyLevel, rootGeoType, rootGeoName]);

  const clearFilter = () => {
    updateSelectedGeo({ type: defaultGeoType, name: defaultGeoName });
    setExpandedHCPProvider(null);
    setIsOpen(false);
  };

  const displayClearButton = selectedGeoName !== rootGeoName || selectedGeoType !== rootGeoType;

  //If user only has access to one territory, display non-changable field
  if (treeData.length === 1 && treeData[0].children === undefined) {
    if (!selectedGeoName || !selectedGeoType) {
      updateSelectedGeo({ type: GeographyType.Territory, name: treeData[0].name });
      setExpandedHCPProvider(null);
    }
    return (
      <div className="flex-1">
        {selectedGeoType}
        <div className="mt-2 h-12 py-3 w-full font-bold">{selectedGeoName}</div>
      </div>
    );
  }

  return (
    <div className="flex-1 relative">
      <label htmlFor="geo-select" className="body-3">
        {t("common.geoLabel")}
      </label>
      {displayClearButton && (
        <button
          onClick={clearFilter}
          aria-label={t("common.closeDropdown")}
          className="float-right absolute right-0 mt-8 mr-1 hover:bg-light-blue rounded-full p-2 focus:outline-none focus:ring"
        >
          <ClearIcon />
        </button>
      )}
      <button
        id=""
        aria-label={t("common.openDropdown")}
        onClick={() => setIsOpen(!isOpen)}
        className="mt-2 input-container h-12 py-3 w-full text-left float-left block focus:border-b focus:border-primary leading-4 overflow-hidden"
      >
        {selectedGeoName}
      </button>
      {isOpen && (
        <ClickAwayListener onClickAway={() => setIsOpen(false)}>
          <div className="mt-2 w-full z-10 absolute bg-white shadow-lg border border-rules">
            <GeoPopup
              treeData={treeData}
              isLoading={isLoading}
              closePopup={() => setIsOpen(false)}
              handleSelect={handleSelect}
              requireTerritory={requireTerritory}
            />
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default GeoSelect;
