/* tslint:disable */
/* eslint-disable */
/**
 * Milliman.PullThroughPortal.WebAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import { Message, MessageFromJSON, MessageFromJSONTyped, MessageToJSON } from "./";

/**
 *
 * @export
 * @interface HCPSpecificMessage
 */
export interface HCPSpecificMessage {
  /**
   *
   * @type {string}
   * @memberof HCPSpecificMessage
   */
  prescID?: string | null;
  /**
   *
   * @type {string}
   * @memberof HCPSpecificMessage
   */
  hcpName?: string | null;
  /**
   *
   * @type {string}
   * @memberof HCPSpecificMessage
   */
  territory?: string | null;
  /**
   *
   * @type {Array<Message>}
   * @memberof HCPSpecificMessage
   */
  messages?: Array<Message> | null;
}

export function HCPSpecificMessageFromJSON(json: any): HCPSpecificMessage {
  return HCPSpecificMessageFromJSONTyped(json, false);
}

export function HCPSpecificMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): HCPSpecificMessage {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    prescID: !exists(json, "prescID") ? undefined : json["prescID"],
    hcpName: !exists(json, "hcpName") ? undefined : json["hcpName"],
    territory: !exists(json, "territory") ? undefined : json["territory"],
    messages: !exists(json, "messages")
      ? undefined
      : json["messages"] === null
      ? null
      : (json["messages"] as Array<any>).map(MessageFromJSON),
  };
}

export function HCPSpecificMessageToJSON(value?: HCPSpecificMessage | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    prescID: value.prescID,
    hcpName: value.hcpName,
    territory: value.territory,
    messages:
      value.messages === undefined
        ? undefined
        : value.messages === null
        ? null
        : (value.messages as Array<any>).map(MessageToJSON),
  };
}
