import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, NavLink, useParams, useRouteMatch } from "react-router-dom";
import { useClientDropdown } from "../../api/useClientDropdown";
import { usePayerLandscapeDispatch } from "../../data/payerLandscape/hooks";
import { useClientRoutesAndLabels } from "../../ui/routes";

interface Props {
  closeNav: () => void;
}

const ClientNavigation: React.FC<Props> = ({ closeNav }) => {
  const [t] = useTranslation();
  const { client: selectedClient } = useParams<{ client: string }>();
  const { updateDetailFilters } = usePayerLandscapeDispatch();
  const { data: clients } = useClientDropdown();
  const match = useRouteMatch<{ client: string }>({ path: "/:client/*" });
  const routes = useClientRoutesAndLabels(selectedClient);

  const onClientSelected = (client: string) => {
    updateDetailFilters({ product: "" });
    closeNav();
  };

  if (!clients || clients.length === 1) {
    return (
      <Link to={routes.path} className="hover:bg-light-blue pt-4 pb-3 px-3 mt-px focus:ring">
        {t("defaultLayout.appName", { product: selectedClient })}
      </Link>
    );
  }

  return (
    <div className="lg:flex flex-grow lg:space-x-3">
      <div className="nav-container relative">
        <NavLink
          onClick={closeNav}
          to={routes.path}
          exact
          activeClassName="active-nav"
          className="nav flex justify-between hover:bg-light-blue focus:ring"
        >
          {t("defaultLayout.appName", { product: selectedClient })}
          <span className="hidden ml-2 lg:inline">
            <KeyboardArrowDownIcon />
          </span>
        </NavLink>
        <ul className="sub-nav w-full">
          {match &&
            clients?.map((client) => (
              <li key={client} className="body-2">
                <NavLink
                  className="px-5 flex items-center"
                  onClick={() => onClientSelected(client)}
                  to={match.url.replace(match.params.client, client)}
                >
                  <div style={{ minWidth: "26px", minHeight: "26px" }}>
                    <img className="max-w-[26px]" alt={client} src={`/assets/icons/${client}.png`} />
                  </div>
                  <span className="ml-3">{t("defaultLayout.appName", { product: client })}</span>
                </NavLink>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default ClientNavigation;
