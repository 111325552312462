/* tslint:disable */
/* eslint-disable */
/**
 * Milliman.PullThroughPortal.WebAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  MetaDataLibraryIEnumerableControllerResponse,
  MetaDataLibraryIEnumerableControllerResponseFromJSON,
  MetaDataLibraryIEnumerableControllerResponseToJSON,
} from "../models";

export interface MetaDataGetRequest {
  client: string;
  apiVersion?: string;
}

/**
 *
 */
export class MetaApi extends runtime.BaseAPI {
  /**
   */
  async metaDataGetRaw(
    requestParameters: MetaDataGetRequest,
  ): Promise<runtime.ApiResponse<MetaDataLibraryIEnumerableControllerResponse>> {
    if (requestParameters.client === null || requestParameters.client === undefined) {
      throw new runtime.RequiredError(
        "client",
        "Required parameter requestParameters.client was null or undefined when calling metaDataGet.",
      );
    }

    const queryParameters: any = {};

    if (requestParameters.client !== undefined) {
      queryParameters["Client"] = requestParameters.client;
    }

    if (requestParameters.apiVersion !== undefined) {
      queryParameters["api-version"] = requestParameters.apiVersion;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/Meta/Data`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      MetaDataLibraryIEnumerableControllerResponseFromJSON(jsonValue),
    );
  }

  /**
   */
  async metaDataGet(requestParameters: MetaDataGetRequest): Promise<MetaDataLibraryIEnumerableControllerResponse> {
    const response = await this.metaDataGetRaw(requestParameters);
    return await response.value();
  }
}
