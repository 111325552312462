import React from "react";
import { Link, useParams } from "react-router-dom";
import { ReactComponent as PinIcon } from "../../../assets/PinIcon.svg";
import { TopHealthcareProviders } from "../../../generate";
import { useClientRoutesAndLabels } from "../../../ui/routes";

interface Props {
  data: TopHealthcareProviders;
}

const HealthCareProviderName: React.FC<Props> = ({ data }) => {
  const { client: selectedClient, hcpID } = useParams<{ client: string; hcpID: string | undefined }>();
  const routes = useClientRoutesAndLabels(selectedClient);
  const isPinned = hcpID === data.pfz_presc_id;

  return (
    <Link
      className="font-bold text-primary flex items-center"
      to={routes.client.dpp.hcps.show.with({ client: selectedClient, id: data.pfz_presc_id })}
    >
      {data.topHCPs}{" "}
      {isPinned && (
        <span className="pl-2">
          <PinIcon />
        </span>
      )}
    </Link>
  );
};

export default HealthCareProviderName;
