/* tslint:disable */
/* eslint-disable */
/**
 * Milliman.PullThroughPortal.WebAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  ErrorResponse,
  ErrorResponseFromJSON,
  ErrorResponseFromJSONTyped,
  ErrorResponseToJSON,
  GeographyTreeview,
  GeographyTreeviewFromJSON,
  GeographyTreeviewFromJSONTyped,
  GeographyTreeviewToJSON,
} from "./";

/**
 *
 * @export
 * @interface GeographyTreeviewControllerResponse
 */
export interface GeographyTreeviewControllerResponse {
  /**
   *
   * @type {GeographyTreeview}
   * @memberof GeographyTreeviewControllerResponse
   */
  data?: GeographyTreeview;
  /**
   *
   * @type {ErrorResponse}
   * @memberof GeographyTreeviewControllerResponse
   */
  error?: ErrorResponse;
}

export function GeographyTreeviewControllerResponseFromJSON(json: any): GeographyTreeviewControllerResponse {
  return GeographyTreeviewControllerResponseFromJSONTyped(json, false);
}

export function GeographyTreeviewControllerResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): GeographyTreeviewControllerResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: !exists(json, "data") ? undefined : GeographyTreeviewFromJSON(json["data"]),
    error: !exists(json, "error") ? undefined : ErrorResponseFromJSON(json["error"]),
  };
}

export function GeographyTreeviewControllerResponseToJSON(value?: GeographyTreeviewControllerResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: GeographyTreeviewToJSON(value.data),
    error: ErrorResponseToJSON(value.error),
  };
}
