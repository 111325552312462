import actionCreatorFactory from "typescript-fsa";
import { Sort } from "../../components/Table/types";
import { FiltersWithRangeTypes } from "./types";

const createAction = actionCreatorFactory("HCP_LANDSCAPE");

export const updateFilters = createAction<FiltersWithRangeTypes>("UPDATE_FILTERS", (filters: FiltersWithRangeTypes) => {
  return filters;
});

export const clearAdvancedFilters = createAction("CLEAR_ADVANCED_FILTERS", () => {
  return;
});

export const updateSort = createAction<Sort>("UPDATE_SORT", (sort: Sort) => {
  return sort;
});
