import React from "react";
import SkeletonText from "../../components/Skeletons/SkeletonText";

const ResourceCardSkeleton: React.FC = () => {
  return (
    <div role="presentation">
      <div className="h-48 bg-steel animate-pulse mb-px"></div>
      <div className="h-20 bg-light-gray animate-pulse my-px px-3 py-4 flex flex-col space-y-2">
        <SkeletonText align="left" />
        <SkeletonText align="left" />
        <SkeletonText align="left" />
      </div>
      <div className="flex divide-x-2 divide-white mt-px">
        <div className="h-10 w-12 bg-rules animate-pulse"></div>
        <div className="h-10 flex-grow bg-rules animate-pulse"></div>
      </div>
    </div>
  );
};

export default ResourceCardSkeleton;
