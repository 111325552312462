import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import React, { useImperativeHandle, useRef } from "react";
import { useTranslation } from "react-i18next";

type ForwardedRef = {
  focus: () => void;
};
interface Props {
  value?: string | null;
  label?: string;
  handleChange: (value: string) => void;
  placeholder?: string;
}

const SearchBox: React.ForwardRefRenderFunction<ForwardedRef, Props> = (props, ref) => {
  const { value, label, placeholder, handleChange } = props;
  const [t] = useTranslation();
  const searchRef = useRef<HTMLInputElement>(null);
  const searchValue = value === null ? undefined : value;

  useImperativeHandle(ref, () => ({
    focus() {
      setFocus();
    },
  }));

  const setFocus = () => {
    if (searchRef && searchRef.current) {
      searchRef.current.focus();
    }
  };

  const clearSearch = () => {
    handleChange("");
    setFocus();
  };

  return (
    <div>
      {label && <label className="py-1 block">{label}</label>}
      <div className="md:w-full input-container space-x-2 pt-1 flex flex-grow">
        <button className="h-10 w-6 focus:outline-none" onClick={setFocus} title={t("common.search")}>
          <SearchIcon className="text-steel" />
        </button>
        <input
          aria-label="Search"
          ref={searchRef}
          value={searchValue}
          onChange={(e) => handleChange(e.target.value)}
          className="py-2.5 w-full focus:border-b focus:border-primary"
          placeholder={placeholder || t("common.search")}
        />
        {value && (
          <button className="h-10 w-6" onClick={clearSearch} title={t("common.clearSearch")}>
            <ClearIcon className="text-steel" />
          </button>
        )}
      </div>
    </div>
  );
};

export default React.forwardRef(SearchBox);
