import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import classNames from "classnames";
import React, { useContext } from "react";
import Highlighted from "../Highlighted/Highlighted";
import GeoContext from "./GeoContext";
import { GeographyType, TreeData } from "./GeoTypes";
import { useClientRootGeographyLevelConfiguration } from "../../data/global/useProductConfiguration";
import { useParams } from "react-router-dom";
import { useGlobalDispatch } from "../../data/global/hooks";

export const sortByName = (a: TreeData, b: TreeData) => {
  return a.name.localeCompare(b.name);
};

const GeoTree: React.FC<
  TreeData & { selectedName: string | null; rootName?: string; rootGeography?: GeographyType }
> = ({ name, type, children, selectedName, rootName, rootGeography }) => {
  const {
    geoState: { expanded },
    search,
    toggleExpanded,
  } = useContext(GeoContext);
  const { updateDefaultGeo } = useGlobalDispatch();

  const { client: selectedClient } = useParams<{ client: string }>();
  const rootGeographyLevel = useClientRootGeographyLevelConfiguration(selectedClient);
  let childRootName = rootName;
  let childRootGeography = rootGeography;
  if (!rootName && rootGeographyLevel === type) {
    childRootName = name;
    childRootGeography = type;
  }

  const isExpanded = expanded.includes(name) || search;

  const buttonClasses = classNames("rounded p-1 block w-full text-left text-sm", { "ring-2": selectedName === name });
  const nameClasses = classNames({ "pl-6": children === undefined });

  const handleClick = () => {
    rootName && updateDefaultGeo({ name: rootName, type: rootGeographyLevel });
    toggleExpanded(name, type);
  };

  return (
    <>
      <button className={buttonClasses} onClick={() => handleClick()}>
        {isExpanded && children !== undefined && <ExpandMoreIcon />}
        {!isExpanded && children !== undefined && <ChevronRightIcon />}
        <span className={nameClasses}>
          <Highlighted text={name} highlight={search} />
        </span>
      </button>
      <div className="pl-2">
        {children !== undefined &&
          isExpanded &&
          children
            .sort(sortByName)
            .map((row) => (
              <GeoTree
                key={row.name}
                {...row}
                selectedName={selectedName}
                rootName={childRootName}
                rootGeography={childRootGeography}
              />
            ))}
      </div>
    </>
  );
};

export default GeoTree;
