import GetAppIcon from "@material-ui/icons/GetApp";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { pdfjs } from "react-pdf";
import { useModal } from "../../components/Modal/useModal";
import ScrollPdfPreview from "../../components/PdfPreview/ScrollPdfPreview";
// import { emailTemplate } from "../Email/emailTemplate.sample";
pdfjs.GlobalWorkerOptions.workerSrc = require("pdfjs-dist/build/pdf.worker.entry.js");

/* eslint-disable  @typescript-eslint/no-explicit-any */
interface Props {
  resource: any;
  client: string;
}

const ResourceModal: React.FC<Props> = ({ resource, client }) => {
  const [t] = useTranslation();
  const { setModal } = useModal();
  const rootRef = useRef<null | HTMLDivElement>(null);

  //Create and download email template
  // const downloadEmailTemplate = () => {
  //   if (!rootRef.current) {
  //     return;
  //   }
  //   //TODO: do api call to get emailTemplate content
  //   const element = document.createElement("a");
  //   element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(emailTemplate));
  //   element.setAttribute("download", "TestEmailTemplate.emltpl");
  //   element.style.display = "none";
  //   rootRef.current.appendChild(element);
  //   element.click();
  //   rootRef.current.removeChild(element);
  // };

  return (
    <div ref={rootRef} className="flex flex-col-reverse lg:flex-row -m-4 sm:-m-8">
      <ScrollPdfPreview url={resource.pdfUrl} isLoading={false} client={client} />
      <div className="lg:w-1/2 xl:w-5/12 p-6 flex flex-col justify-between min-w-max">
        <div>
          <h1 className="text-lg sm:text-2xl font-bold text-brand">{resource?.display_name}</h1>

          <div className="border border-steel bg-white my-6">
            <div className="bg-light-gray p-3">
              <h2 className="header-5-regular">{t("resourceLibrary.implementationGuide")}</h2>
            </div>
            <div className="p-3 body-2 text-slate-gray flex flex-col space-y-3 font-bold">
              <p>{resource.implementation_guide}</p>
            </div>
          </div>

          <span className="mb-4 flex flex-col sm:flex-row lg:flex-col xl:flex-row space-x-0 sm:space-x-2 lg:space-x-0 xl:space-x-2 lg:space-y-2 xl:space-y-0">
            <a
              className="btn btn-primary mb-6 md:mb-0 w-full"
              href={resource.pdfUrl!}
              target="_blank"
              rel="noreferrer"
              download
            >
              <GetAppIcon />
              <span>{t("specificMessages.downloadPrintPdf")}</span>
            </a>
            {/* <button className="btn btn-primary mb-6 md:mb-0 min-w-56" onClick={downloadEmailTemplate}>
              <MailOutlineIcon />
              <span>{t("specificMessages.downloadEmailTemplate")}</span>
            </button> */}
          </span>

          {/* <p className="text-slate-gray body-3">{t("resourceLibrary.expirationDate")}: 10/2022</p> */}
        </div>
        <div className="flex justify-end">
          <button onClick={() => setModal(undefined)} className="btn btn-lg flex-grow sm:flex-grow-0 sm:w-32 btn-rules">
            {t("common.done")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ResourceModal;
