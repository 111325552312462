import FindInPageIcon from "@material-ui/icons/FindInPage";
import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { useModal } from "../../components/Modal/useModal";
import { HCPSpecificMessage, Message, PrintDoc } from "../../generate";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import EmailSellSheetModal from "./EmailSellSheetModal";
import { useDownloadEmailTemplate } from "../../api/useDownloadEmailTemplate";

interface Props {
  client: string;
  hcp: HCPSpecificMessage;
  sellSheet: Message;
}

const EmailSellSheetRow: React.FC<Props> = ({ client, hcp, sellSheet }) => {
  const [t] = useTranslation();
  const { setModal } = useModal();

  const printDoc: PrintDoc = {
    client,
    baseDocName: sellSheet.messageName,
    prescID: hcp.prescID,
    includePI: true,
    isResource: false,
  };

  const { isLoading: isDownloading, mutateAsync: downloadEmailTemplate } = useDownloadEmailTemplate(printDoc, hcp);

  const buttonClasses = classNames(
    "flex items-center justify-center h-10 w-10 text-primary rounded-full focus:outline-none focus:ring hover:bg-light-gray",
  );
  return (
    <div className="flex  flex-col-reverse sm:flex-row sm:items-center pt-1 hover:bg-light-blue -ml-3">
      <div className="pl-3 sm:pl-0 sm:w-56 flex space-x-1 border-b border-rules sm:border-none">
        <button
          className={buttonClasses}
          aria-label={t("specificMessages.downloadPrintPdf")}
          onClick={() => setModal(<EmailSellSheetModal client={client} hcp={hcp} sellSheet={sellSheet} />)}
        >
          <FindInPageIcon />
        </button>
        <button disabled={isDownloading} className={buttonClasses} onClick={() => downloadEmailTemplate()}>
          <MailOutlineIcon />
        </button>
      </div>
      <div className="pl-6 sm:pl-3 py-2">
        <div className="text-xs sm:text-sm">{sellSheet.messageName}</div>
      </div>
    </div>
  );
};

export default EmailSellSheetRow;
