import { Action } from "typescript-fsa";

export interface QueueItem {
  client: string;
  hcp?: string | null;
  prescID?: string | null;
  displayName: string;
  baseDocName?: string | null;
  includePI: boolean;
  isResource: boolean;
}

export enum PrintJobStatus {
  Idle = "idle",
  Start = "start",
  Processing = "processing",
  Finished = "finished",
  Error = "error",
}

export type PrintJob = {
  status: PrintJobStatus;
  jobID?: string;
  packageID?: string;
  downloadURL?: string;
  errorMessage?: string | null;
  printDocs: QueueItem[];
};

export interface QueueState {
  queue: QueueItem[];
  printJob: PrintJob;
}

export interface QueueDispatch {
  addQueueItems: (queueItems: QueueItem[]) => Action<QueueItem[]>;
  updateQueueItem: (queueItem: QueueItem) => Action<QueueItem>;
  removeQueueItems: (queueItems: QueueItem[]) => Action<QueueItem[]>;
  updatePrintJob: (printJob: Partial<PrintJob>) => Action<Partial<PrintJob>>;
}
