import { isEqual, uniqWith } from "lodash";
import { useSnackbar } from "notistack";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import SnackBar, { SnackVariant } from "../../components/SnackBar/SnackBar";
import { stringifyQueueItem } from "../../utils/stringifyQueueItem";
import { useQueueDispatch, useQueueState } from "./hooks";
import { QueueItem } from "./types";

export const useQueueActions = () => {
  const [t] = useTranslation();
  const { addQueueItems } = useQueueDispatch();
  const { queue } = useQueueState();
  const { enqueueSnackbar } = useSnackbar();

  const addUniqueQueueItemsToQueue = useCallback(
    (queueItems: QueueItem[]) => {
      const uniqueItems = uniqWith(queueItems, isEqual);

      //Sort queue items into already in queue, or not yet in queue
      const itemsAlreadyInQueue: QueueItem[] = [];
      const itemsNotInQueue: QueueItem[] = [];
      uniqueItems.forEach((qItem) => {
        const qItemString = stringifyQueueItem(qItem);
        const inQueue = queue.some((item) => {
          return stringifyQueueItem(item) === qItemString;
        });
        if (inQueue) {
          itemsAlreadyInQueue.push(qItem);
        } else {
          itemsNotInQueue.push(qItem);
        }
      });

      //If we had any duplicates, show an error saying we could not add them again
      if (itemsAlreadyInQueue.length) {
        enqueueSnackbar(
          <SnackBar
            message={t("common.itemInQueueAlready", { count: itemsAlreadyInQueue.length })}
            variant={SnackVariant.ERROR}
          />,
          {
            autoHideDuration: 3000,
          },
        );
      }

      //Add unique items to queue
      if (itemsNotInQueue.length) {
        addQueueItems(itemsNotInQueue);
        enqueueSnackbar(
          <SnackBar
            message={t("common.addDocsWithCount", { count: itemsNotInQueue.length })}
            variant={SnackVariant.SUCCESS}
          />,
          {
            autoHideDuration: 3000,
          },
        );
      }
    },
    [t, queue, addQueueItems, enqueueSnackbar],
  );

  return { addUniqueQueueItemsToQueue };
};
