import classNames from "classnames";
import React from "react";
import { TabProps } from "./useTabs";

const Tab: React.FC<TabProps> = ({ children, changeTab, tabName, currentTab }) => {
  const isActive = tabName === currentTab;
  const buttonClasses = classNames(
    "mb-6 lg:mb-0 focus:outline-none py-3 px-6 border-t-3 body-1 flex items-center flex-1 lg:flex-none",
    { "border-white text-brand bg-light-gray shadow-inner lg:shadow-none lg:bg-white": !isActive },
    { "bg-brand text-white lg:text-brand lg:bg-rules shadow-lg lg:shadow-none lg:border-brand": isActive },
  );

  return (
    <button className={buttonClasses} onClick={(e) => changeTab(tabName)}>
      {children}
    </button>
  );
};

export default Tab;
