import { CircularProgress } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import NoResultsFound from "../NoResults/NoResultsFound";
import SearchBox from "../SearchBox/SearchBox";
import GeoContext from "./GeoContext";
import GeoTree, { sortByName } from "./GeoTree";
import { GeographyType, TreeData } from "./GeoTypes";
import { useParams } from "react-router-dom";

interface Props {
  treeData: TreeData[];
  isLoading: boolean;
  closePopup: () => void;
  handleSelect: (type: GeographyType | null, name: string | null) => void;
  requireTerritory?: boolean;
}

const GeoPopup: React.FC<Props> = ({ treeData, isLoading, closePopup, handleSelect, requireTerritory }) => {
  const [t] = useTranslation();
  const [search, setSearch] = useState("");
  const { client: selectedClient } = useParams<{ client: string }>();

  type SearchHandle = React.ElementRef<typeof SearchBox>;
  const searchRef = useRef<SearchHandle>(null);

  useEffect(() => {
    if (searchRef.current) {
      searchRef.current.focus();
    }
  }, []);

  const filterTreeData = (treeData: TreeData[]): TreeData[] => {
    if (!search) return treeData;
    return treeData.flatMap((item) => {
      //If there are children, filter those recursively
      const filteredChildren = item.children ? filterTreeData(item.children) : undefined;

      //Check the current item to see if it is a match
      const match = item.name.toLowerCase().includes(search.toLowerCase());

      //if any of the children or the current item have a match, include in the returned map, otherwise leave out
      if (filteredChildren && filteredChildren.length > 0) {
        return [{ ...item, children: filteredChildren }];
      }
      if (match) {
        return [item];
      }
      return [];
    });
  };

  const filteredTreeData = filterTreeData(treeData);

  const [selectedName, setSelectedName] = useState(filteredTreeData.length === 1 ? filteredTreeData[0].name : null);
  const [selectedType, setSelectedType] = useState<GeographyType | null>(
    filteredTreeData.length === 1 ? filteredTreeData[0].type : null,
  );

  const [geoState, setGeoState] = useState({
    expanded: [""],
  });

  const toggleExpanded = (name: string, type: GeographyType) => {
    setSelectedName(name);
    setSelectedType(type);
    setGeoState((state) => ({
      expanded: state.expanded.includes(name)
        ? state.expanded.filter((geo) => geo !== name)
        : [...state.expanded, name],
    }));
    if (requireTerritory && type === GeographyType.Territory) {
      handleSelect(type, name);
      closePopup();
    }
  };

  const filter = () => {
    handleSelect(selectedType, selectedName);
    closePopup();
  };

  const validSelection = selectedName && (selectedType === GeographyType.Territory || !requireTerritory);

  if (isLoading) {
    return (
      <div className="flex justify-center py-12">
        <CircularProgress size={80} />
      </div>
    );
  }

  return (
    <>
      <div className=" w-full">
        <SearchBox ref={searchRef} value={search} handleChange={(value) => setSearch(value)} />
        {filteredTreeData.length === 0 && (
          <div className="py-6">
            <NoResultsFound />
          </div>
        )}
        <GeoContext.Provider value={{ geoState, search, toggleExpanded }}>
          <div className="max-h-72 overflow-y-auto p-1">
            {filteredTreeData.sort(sortByName).map((row: TreeData) => (
              <GeoTree key={row.name} {...row} selectedName={selectedName} />
            ))}
          </div>
        </GeoContext.Provider>
        <div className="flex flex-col p-2 space-y-2 border-t border-rules">
          {validSelection && (
            <button onClick={() => filter()} className="btn btn-primary flex-1">
              {t("common.filterTo", { name: selectedName })}
            </button>
          )}
          {!validSelection && (
            <button disabled className="btn btn-primary flex-1">
              {requireTerritory ? t(`common.selectATerritory.${selectedClient}`) : t("common.selectAnItem")}
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default GeoPopup;
