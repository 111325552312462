import InfoOutlined from "@material-ui/icons/InfoOutlined";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { DataWindow, useMetaData } from "../../api/useMetaDataApi";
import {
  usePayerLandscapeComparison,
  usePayerLandscapeComparisonFetchExport,
  usePayerLandscapeComparisonProducts,
} from "../../api/usePayerLandscapeComparisonApi";
import Table from "../../components/Table/Table";
import { Column } from "../../components/Table/types";
import Tooltip from "../../components/Tooltip/Tooltip";
import { useClientConfiguration } from "../../data/global/useProductConfiguration";
import { usePayerLandscapeDispatch, usePayerLandscapeState } from "../../data/payerLandscape/hooks";
import useGetRole, { BIOSIM_ROLE, FRM_ROLE } from "../../hooks/useGetRole";
import PrintWinLegend from "./PrintWinLegend";

const PayerLandscapeComparisonTab: React.FC = () => {
  const { comparisonSort } = usePayerLandscapeState();
  const { updateComparisonSort } = usePayerLandscapeDispatch();
  const { client: selectedClient } = useParams<{ client: string }>();
  const { isLoading: isLoadingProducts, data: products } = usePayerLandscapeComparisonProducts(selectedClient);
  const { data: role } = useGetRole(selectedClient);
  const { products: allowedPlanInfoProducts } = useClientConfiguration(selectedClient);
  const { t, i18n } = useTranslation();
  const isFRM = String(role) === FRM_ROLE;
  const isBiosim = String(role) === BIOSIM_ROLE;
  const timePeriod = DataWindow.twelveMonths;
  const compareQuery = usePayerLandscapeComparison(selectedClient, !isLoadingProducts);

  const { data: metaData } = useMetaData(selectedClient, timePeriod || "");

  const productColumns = (products || [])
    .filter((product) => allowedPlanInfoProducts?.includes((product.name || "").toUpperCase()))
    .map(({ key, name }) => ({
      label: (name || "").toUpperCase(),
      subLabel: `payerLandscape.coverageStatus.${selectedClient}`,
      align: "left",
      width: 160,
      dataKey: `${key}_status`,
    }));
  const PlanIndicator = "planIndicator" as const;

  const mrkTrxColumn = {
    label: timePeriod ? `payerLandscape.marketTrx.${timePeriod}.${selectedClient}` : "Loading...",
    align: "right",
    dataKey: "mkt_trx",
    width: 160,
    format: {
      thousandSeparator: true,
    },
  };

  const comparisonColumns: Column[] = [
    {
      label: "payerLandscape.planName",
      align: "left",
      dataKey: "m_plan_name",
      component: PlanIndicator,
    },
    {
      label: "payerLandscape.lives",
      align: "right",
      width: 160,
      dataKey: "lives",
      format: {
        thousandSeparator: true,
      },
    },
    ...(!isFRM ? [mrkTrxColumn] : []),
    ...productColumns,
  ];

  const columnNames = comparisonColumns.map((column) => t(column.label, column.translationParams));
  const columnKeys = comparisonColumns.map((column) => column.dataKey);

  const exportTable = usePayerLandscapeComparisonFetchExport(selectedClient, columnKeys, columnNames);

  return (
    <>
      <div className="flex flex-row-reverse mb-2">
        {(i18n.exists(`payerLandscape.tooltip.${selectedClient}`) || isFRM) && (
          <Tooltip
            placement="bottom-end"
            tip={
              isFRM
                ? (t("payerLandscape.frmTooltip") as string)
                : (t(`payerLandscape.tooltip.${selectedClient}`) as string)
            }
          >
            <InfoOutlined className="text-primary" />
          </Tooltip>
        )}
      </div>
      <Table
        columns={comparisonColumns}
        query={compareQuery}
        sort={comparisonSort}
        handleSort={(sort) => updateComparisonSort(sort)}
        stickyMargin={54}
        legend={<PrintWinLegend />}
        footnote={t(`payerLandscape.productComparisonFootnote.${selectedClient}`)}
        boldFootnote={selectedClient === "CIBINQO"}
        metaData={metaData}
        export={!isBiosim ? exportTable : undefined}
      />
    </>
  );
};

export default PayerLandscapeComparisonTab;
