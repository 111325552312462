import { Column } from "../../components/Table/types";
import { useClientPayerLandscapeColumnsConfiguration } from "../../data/global/useProductConfiguration";
import { usePayerLandscapeState } from "../../data/payerLandscape/hooks";
import { PlanFeed } from "../../generate";
import useGetRole, { FRM_ROLE } from "../../hooks/useGetRole";
import useHideCompStatusColumnsAsFrm from "../../hooks/useHideCompStatusColumnsAsFrm";

const PlanIndicator = "planIndicator" as const;
const useDetailColumns = (client: string, defaultKey: string) => {
  const {
    sharedFilters: { timePeriod },
    detailFilters: { product },
  } = usePayerLandscapeState();
  const { data: role } = useGetRole(client);
  const isFrm = String(role) === FRM_ROLE;
  const hideCompStatusColumnsAsFrm = useHideCompStatusColumnsAsFrm(client, isFrm);
  const visibleColumns = useClientPayerLandscapeColumnsConfiguration(client, hideCompStatusColumnsAsFrm);

  const detailColumns: Column<keyof PlanFeed>[] = [
    {
      label: "payerLandscape.planName",
      align: "left",
      dataKey: "m_plan_name",
      component: PlanIndicator,
    },
    {
      label: "payerLandscape.pbmVendor",
      align: "left",
      dataKey: "pbm_vendor",
      width: 160,
    },
    {
      label: "payerLandscape.lives",
      align: "right",
      dataKey: "lives",
      width: 150,
      format: {
        thousandSeparator: true,
      },
    },
    {
      label: `payerLandscape.coverageStatus.${defaultKey}`,
      align: "left",
      dataKey: "cc_status",
      width: 160,
    },
    {
      label: `payerLandscape.relativeStatus.${defaultKey}`,
      align: "left",
      dataKey: "rel_status",
      width: 160,
    },
    {
      label: `payerLandscape.compColumns.${defaultKey}.compCoverageStatus`,
      align: "left",
      dataKey: "comp_cc_status",
      width: 160,
    },
    {
      label: `payerLandscape.compColumns.${defaultKey}.compRelativeStatus`,
      align: "left",
      dataKey: "comp_rel_status",
      width: 160,
    },
    {
      label: timePeriod ? `payerLandscape.marketTrx.${timePeriod}.${defaultKey}` : "Loading...",
      align: "right",
      dataKey: "mkt_trx",
      width: 160,
      format: {
        thousandSeparator: true,
      },
    },
    {
      label: timePeriod ? `payerLandscape.productTRX.${timePeriod}.${defaultKey}` : "Loading...",
      translationParams: { product: product! },
      align: "right",
      dataKey: "prod_trx",
      width: 130,
      format: {
        thousandSeparator: true,
      },
    },

    {
      label: timePeriod ? `payerLandscape.marketShare.${timePeriod}.${client}` : "Loading...",
      translationParams: { product: product! },
      align: "right",
      dataKey: "mkt_share",
      width: 160,
      format: {
        suffix: "%",
      },
    },
    {
      label: "payerLandscape.monthlyChange",
      align: "right",
      dataKey: "share_delta",
      width: 160,
      format: {
        suffix: "%",
      },
    },
    {
      label: timePeriod ? `payerLandscape.compProdTrx.${timePeriod}.${defaultKey}` : "Loading...",
      align: "right",
      dataKey: "comp_prod_trx",
      width: 160,
      format: {
        thousandSeparator: true,
      },
    },
  ];
  return { detailColumns: detailColumns.filter((column) => visibleColumns && visibleColumns.includes(column.dataKey)) };
};

export default useDetailColumns;
