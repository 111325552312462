import React from "react";
import { useLocation, useParams, useRouteMatch as routeMatch } from "react-router";
import { Link } from "react-router-dom";
import { useClientRoutesAndLabels } from "../../ui/routes";

interface Breadcrumb {
  path: string;
  label?: string;
  url?: string;
  isExact?: boolean;
  notLinkable?: boolean;
}

const BreadCrumbs: React.FC = () => {
  const { client: selectedClient } = useParams<{ client: string }>();
  const routes = useClientRoutesAndLabels(selectedClient);
  const location = useLocation();
  if (location === undefined) {
    return null;
  }

  //Translate paths into nice names for use in the breadcrumb trail
  //Note that the order here matters
  const breadcrumbNameMap: Breadcrumb[] = [
    { path: routes.client.dpp.path, label: routes.client.dpp.label, notLinkable: true },
    { path: routes.client.dpp.payers.path, label: routes.client.dpp.payers.label },
    { path: routes.client.dpp.payers.show.path, label: routes.client.dpp.payers.show.label },
    { path: routes.client.dpp.payers.show.providers.path, label: routes.client.dpp.payers.show.providers.label },
    { path: routes.client.dpp.payers.show.pinned.providers.path, label: routes.client.dpp.payers.show.providers.label },
    { path: routes.client.dpp.geo.path, label: routes.client.dpp.geo.label },
    { path: routes.client.dpp.geo.show.path, label: routes.client.dpp.geo.show.label },
    { path: routes.client.dpp.hcps.path, label: routes.client.dpp.hcps.label },
    { path: routes.client.dpp.hcps.show.path, label: routes.client.dpp.hcps.show.label },
    { path: routes.client.print.path, label: routes.client.print.label },
    { path: routes.client.print.messages.path, label: routes.client.print.messages.label },
    { path: routes.client.print.email.path, label: routes.client.print.email.label },
    { path: routes.client.print.resources.path, label: routes.client.print.resources.label },
    { path: routes.client.print.queue.path, label: routes.client.print.queue.label },
    { path: routes.client.profile.path, label: routes.client.profile.label },
  ];

  /* Check if the current path matches each section of our breadcrumbMap,
  and if it does, grab the translated friendly name and add it to an array */
  const generateBreadcrumbArray: () => Breadcrumb[] = () => {
    const breadCrumbArray: Breadcrumb[] = [];
    breadcrumbNameMap.forEach((item) => {
      const match = routeMatch(item.path);
      if (match) {
        breadCrumbArray.push({ ...match, label: item.label, notLinkable: item.notLinkable });
      }
    });
    return breadCrumbArray;
  };

  const breadcrumbsArray = generateBreadcrumbArray();

  return (
    <ul className="hidden md:flex space-x-4 pb-6 breadcrumb-container">
      {breadcrumbsArray.map((breadcrumb) => {
        const activeClass = breadcrumb.isExact ? "text-brand underline" : "";
        return (
          <li key={breadcrumb.path}>
            {breadcrumb.notLinkable ? (
              <span className={`text-slate-gray focus:ring-2 body-2 ${activeClass}`}>{breadcrumb.label}</span>
            ) : (
              <Link className={`text-slate-gray focus:ring-2 body-2 ${activeClass}`} to={breadcrumb.url!}>
                {breadcrumb.label}
              </Link>
            )}
          </li>
        );
      })}
    </ul>
  );
};

export default BreadCrumbs;
