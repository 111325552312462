import { useMutation, UseMutationResult } from "react-query";
import { PrintApi } from "../generate";
import { call } from "./api";
import { HCPSpecificMessage, PrintDoc } from "../generate";
import { saveAs } from "file-saver";

const DEFAULT_EMAIL_TEMPLATE_EXTENSION = "eml";

export const useDownloadEmailTemplate = (
  printDoc: PrintDoc,
  hcp: HCPSpecificMessage,
): UseMutationResult<string, Error, void> => {
  const api = call(PrintApi);
  return useMutation<string, Error, void>(async () => {
    const { data } = await api.printEmailTemplatePost({ printDoc });
    const hcpName = (hcp.hcpName || "").replace(/\s+/g, "-");
    const fileName = `${printDoc?.baseDocName}-${hcpName}.${DEFAULT_EMAIL_TEMPLATE_EXTENSION}`;
    const response = await fetch(data!);
    const blob = await response.blob();
    const file = new File([blob], fileName, {
      type: `application/${DEFAULT_EMAIL_TEMPLATE_EXTENSION}`,
    });

    saveAs(file, fileName);
    return data!;
  });
};
