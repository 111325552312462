/* tslint:disable */
/* eslint-disable */
/**
 * Milliman.PullThroughPortal.WebAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface HealthcareProvider
 */
export interface HealthcareProvider {
  /**
   *
   * @type {string}
   * @memberof HealthcareProvider
   */
  pfz_presc_id?: string | null;
  /**
   *
   * @type {string}
   * @memberof HealthcareProvider
   */
  first_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof HealthcareProvider
   */
  last_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof HealthcareProvider
   */
  address?: string | null;
  /**
   *
   * @type {string}
   * @memberof HealthcareProvider
   */
  city?: string | null;
  /**
   *
   * @type {string}
   * @memberof HealthcareProvider
   */
  state?: string | null;
  /**
   *
   * @type {string}
   * @memberof HealthcareProvider
   */
  zip?: string | null;
}

export function HealthcareProviderFromJSON(json: any): HealthcareProvider {
  return HealthcareProviderFromJSONTyped(json, false);
}

export function HealthcareProviderFromJSONTyped(json: any, ignoreDiscriminator: boolean): HealthcareProvider {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    pfz_presc_id: !exists(json, "pfz_presc_id") ? undefined : json["pfz_presc_id"],
    first_name: !exists(json, "first_name") ? undefined : json["first_name"],
    last_name: !exists(json, "last_name") ? undefined : json["last_name"],
    address: !exists(json, "address") ? undefined : json["address"],
    city: !exists(json, "city") ? undefined : json["city"],
    state: !exists(json, "state") ? undefined : json["state"],
    zip: !exists(json, "zip") ? undefined : json["zip"],
  };
}

export function HealthcareProviderToJSON(value?: HealthcareProvider | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    pfz_presc_id: value.pfz_presc_id,
    first_name: value.first_name,
    last_name: value.last_name,
    address: value.address,
    city: value.city,
    state: value.state,
    zip: value.zip,
  };
}
