import actionCreatorFactory from "typescript-fsa";
import { PrintJob, QueueItem } from "./types";

const createAction = actionCreatorFactory("QUEUE");

export const addQueueItems = createAction<QueueItem[]>("ADD_QUEUE_ITEMS", (queueItems: QueueItem[]) => {
  return queueItems;
});

export const updateQueueItem = createAction<QueueItem>("UPDATE_QUEUE_ITEM", (queueItem: QueueItem) => {
  return queueItem;
});

export const removeQueueItems = createAction<QueueItem[]>("REMOVE_QUEUE_ITEMS", (queueItems: QueueItem[]) => {
  return queueItems;
});

export const updatePrintJob = createAction<Partial<PrintJob>>("UPDATE_PRINT_JOB", (printJob: Partial<PrintJob>) => {
  return printJob;
});
