import React, { forwardRef } from "react";

const RenderCustomSnackBar = forwardRef<HTMLDivElement, { id: string | number; Component: React.ReactNode }>(
  ({ id, Component }, ref) => {
    //Clone element, so child component has access to the id prop for snack dismissal
    const SnackBar = React.cloneElement(Component as React.ReactElement, { id });
    return <div ref={ref}>{SnackBar}</div>;
  },
);

export default RenderCustomSnackBar;
