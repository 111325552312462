import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store/types";
import { updateDefaultGeo, updateGeoAccess, updateRootGeo, updateSelectedGeo, updateUserEmail } from "./actions";
import { GlobalDispatch, GlobalState } from "./types";

export const useGlobalState = (): GlobalState => {
  return useSelector(({ global }: AppState) => {
    return global;
  });
};

export const useGlobalDispatch = (): GlobalDispatch => {
  const dispatch = useDispatch();
  return useMemo(
    (): GlobalDispatch => ({
      updateSelectedGeo: (geoPayload) => dispatch(updateSelectedGeo(geoPayload)),
      updateRootGeo: (geoPayload) => dispatch(updateRootGeo(geoPayload)),
      updateDefaultGeo: (geoPayload) => dispatch(updateDefaultGeo(geoPayload)),
      updateGeoAccess: (access) => dispatch(updateGeoAccess(access)),
      updateUserEmail: (email) => dispatch(updateUserEmail(email)),
    }),
    [dispatch],
  );
};
