/* tslint:disable */
/* eslint-disable */
/**
 * Milliman.PullThroughPortal.WebAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface MetaDataLibrary
 */
export interface MetaDataLibrary {
  /**
   *
   * @type {string}
   * @memberof MetaDataLibrary
   */
  client?: string | null;
  /**
   *
   * @type {string}
   * @memberof MetaDataLibrary
   */
  time_period?: string | null;
  /**
   *
   * @type {string}
   * @memberof MetaDataLibrary
   */
  ccstartdate?: string | null;
  /**
   *
   * @type {string}
   * @memberof MetaDataLibrary
   */
  ccenddate?: string | null;
  /**
   *
   * @type {string}
   * @memberof MetaDataLibrary
   */
  trxstartdate?: string | null;
  /**
   *
   * @type {string}
   * @memberof MetaDataLibrary
   */
  trxenddate?: string | null;
}

export function MetaDataLibraryFromJSON(json: any): MetaDataLibrary {
  return MetaDataLibraryFromJSONTyped(json, false);
}

export function MetaDataLibraryFromJSONTyped(json: any, ignoreDiscriminator: boolean): MetaDataLibrary {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    client: !exists(json, "client") ? undefined : json["client"],
    time_period: !exists(json, "time_period") ? undefined : json["time_period"],
    ccstartdate: !exists(json, "ccstartdate") ? undefined : json["ccstartdate"],
    ccenddate: !exists(json, "ccenddate") ? undefined : json["ccenddate"],
    trxstartdate: !exists(json, "trxstartdate") ? undefined : json["trxstartdate"],
    trxenddate: !exists(json, "trxenddate") ? undefined : json["trxenddate"],
  };
}

export function MetaDataLibraryToJSON(value?: MetaDataLibrary | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    client: value.client,
    time_period: value.time_period,
    ccstartdate: value.ccstartdate,
    ccenddate: value.ccenddate,
    trxstartdate: value.trxstartdate,
    trxenddate: value.trxenddate,
  };
}
