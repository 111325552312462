/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation, useQuery } from "react-query";
import { PrintJobStatus } from "../data/queue/types";
import { JobStatus, ZipPackageInfoControllerResponse } from "../generate";
import { PrintApi, PrintZippedPrintDocsPostRequest } from "../generate/apis";
import { call } from "./api";

export const usePrintZippedDocs = () => {
  return useMutation<ZipPackageInfoControllerResponse, any, PrintZippedPrintDocsPostRequest>(async (postData) => {
    const data = await call(PrintApi).printZippedPrintDocsPost(postData);
    return data;
  });
};

export const usePrintZippedJobStatus = (jobID: string | undefined, status: PrintJobStatus) => {
  const fetch = async () => {
    if (!jobID) {
      return undefined;
    }
    const { data } = await call(PrintApi).printZippedJobStatusJobIDGet({ jobID });
    return data;
  };
  type ApiResponse = JobStatus | undefined;
  return useQuery<ApiResponse, Error>(["print-zipped-job-status", jobID, status], fetch, {
    enabled: status === PrintJobStatus.Processing && !!jobID,
    refetchInterval: 3000,
  });
};

export const usePrintZippedJobDownload = (packageID: string | undefined, status: PrintJobStatus) => {
  const fetch = async () => {
    if (!packageID) {
      return null;
    }
    const { data } = await call(PrintApi).printZippedPrintDocsPackageIDGet({ packageID });
    return data;
  };
  type ApiResponse = string | null | undefined;
  return useQuery<ApiResponse, Error>(["print-zipped-job-download", packageID, status], fetch, {
    enabled: status === PrintJobStatus.Finished && !!packageID,
  });
};
