import { Checkbox } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ConfirmationModal from "../../components/Modal/ConfirmationModal";
import { useModal } from "../../components/Modal/useModal";
import SnackBar, { SnackVariant } from "../../components/SnackBar/SnackBar";
import { useQueueDispatch } from "../../data/queue/hooks";
import { QueueItem } from "../../data/queue/types";
import { useClientRoutesAndLabels } from "../../ui/routes";

interface Props {
  client: string;
  queueItem: QueueItem;
}

const QueueRow: React.FC<Props> = ({ client, queueItem }) => {
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { removeQueueItems } = useQueueDispatch();
  const { setModal } = useModal();
  const { updateQueueItem } = useQueueDispatch();
  const routes = useClientRoutesAndLabels(client);

  const remove = () => {
    setModal(undefined);
    removeQueueItems([queueItem]);
    enqueueSnackbar(<SnackBar message={t("queue.removedWithCount", { count: 1 })} variant={SnackVariant.SUCCESS} />, {
      autoHideDuration: 3000,
    });
  };
  const showRemoveModal = () => {
    setModal(
      <ConfirmationModal
        title="Remove Item"
        confirmButtonText="Remove Item"
        onConfirm={remove}
        confirmButtonColor="error"
      >
        <div>
          <p className="text-xl py-3">
            {t("queue.removeOneWithHcpAndMessage", { message: queueItem.baseDocName, hcp: queueItem.hcp })}
          </p>
          <p className="text-sm text-slate-gray">{t("queue.removeNoUndo")}</p>
        </div>
      </ConfirmationModal>,
    );
  };

  const updatePI = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateQueueItem({ ...queueItem, includePI: e.target.checked });
  };

  return (
    <div className="flex items-center border-b border-rules even:bg-light-gray px-5">
      <div className="w-5/12 py-5">
        <p className="font-bold">
          {i18n.exists(`queue.hcp.${client}`) ? t(`queue.hcp.${client}`) : t(`queue.hcp.default`)}
          {queueItem.prescID ? (
            <Link
              className="text-primary hover:underline"
              to={routes.client.dpp.hcps.show.with({ client, id: queueItem.prescID })}
            >
              {queueItem.hcp}
            </Link>
          ) : (
            queueItem.hcp
          )}
        </p>
        <p>
          {t("queue.message")} {queueItem.baseDocName}
        </p>
      </div>
      <div className="w-3/12 pl-2">
        <Checkbox color="primary" checked={queueItem.includePI} onChange={updatePI} />
      </div>
      <div className="flex-grow flex justify-end">
        <button
          onClick={showRemoveModal}
          className="text-error font-bold hover:bg-rules p-3 rounded hover:shadow-inner"
        >
          {t("queue.remove")}
        </button>
      </div>
    </div>
  );
};

export default QueueRow;
