import { CircularProgress } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import InfoIcon from "@material-ui/icons/Info";
import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, Redirect, useParams } from "react-router-dom";
import { useClientDropdown } from "../../api/useClientDropdown";
import {
  useClientConfiguration,
  useClientHCPLandscapeConfiguration,
  useClientPayerLandscapeConfiguration,
  useClientPlanProfileConfiguration,
  useClientPrintConfiguration,
} from "../../data/global/useProductConfiguration";
import useGetRole, { FRM_ROLE, GENERAL_ROLE } from "../../hooks/useGetRole";
import { useClientRoutesAndLabels } from "../../ui/routes";

const Loading: React.FC = () => {
  return (
    <div className="flex h-screen justify-center items-center">
      <CircularProgress size={80} />
    </div>
  );
};

const PickAPortal: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { isLoading, data: clients } = useClientDropdown();
  const { client: selectedClient } = useParams<{ client: string }>();
  const isValidClient = clients?.some((client) => client === selectedClient);
  const { isLoading: isLoadingRole, data: role, isFetched } = useGetRole(selectedClient);
  const isFRM = String(role) === FRM_ROLE;
  const isGeneral = !isFetched || String(role) === GENERAL_ROLE;
  const printConfig = useClientPrintConfiguration(selectedClient);
  const planProfileConfig = useClientPlanProfileConfiguration(selectedClient);
  const planProfileVisible = planProfileConfig.hiddenForGeneral && isGeneral;
  const HCPLandscapeConfig = useClientHCPLandscapeConfiguration(selectedClient);
  const payerLandscapeConfig = useClientPayerLandscapeConfiguration(selectedClient);
  const buttonClasses = classNames("btn-primary p-2 flex justify-between ");
  const footnote = t([`portal.footnote.${selectedClient}`, `portal.footnote.default`]);
  const showPrintPortal =
    (printConfig.hcpPrintMessages || printConfig.hcpEmailMessages || printConfig.resourceLibrary) &&
    (!isFRM || printConfig.printEnabledForFRM);
  const routes = useClientRoutesAndLabels(selectedClient);
  const { howToVideo } = useClientConfiguration(selectedClient);

  const showHCPLandscape = (HCPLandscapeConfig.enabled === true || HCPLandscapeConfig.enabled === undefined) && !isFRM;
  const showPayerLandscape = payerLandscapeConfig.enabled === true || payerLandscapeConfig.enabled === undefined;

  if (isLoading || isLoadingRole) {
    return <Loading />;
  }

  if (clients?.length === 0) {
    return <Redirect to={routes.noAccess.path} />;
  }

  if (!selectedClient || !isValidClient) {
    return <Redirect to={routes.path} />;
  }

  return (
    <div className="mt-8 sm:-mt-6 px-4">
      <h1 className="text-2xl sm:text-5xl font-semibold text-white text-center py-2">
        {t("portal.pickAPortal", { product: selectedClient })}
      </h1>
      <h2 className="text-sm sm:text-2xl  text-white text-center">{t("portal.subTitle")}</h2>
      <div className="bg-white shadow max-w-4xl mx-auto mt-4 sm:mt-16">
        <div
          className={classNames("flex flex-col sm:flex-row space-y-10 sm:space-y-0 sm:space-x-10 px-12 pt-12", {
            "pb-6": footnote,
            "pb-12": !footnote,
          })}
        >
          {showPayerLandscape && !planProfileVisible && (
            <div className={classNames({ "w-1/2": showPrintPortal })}>
              <h3 className="font-semibold text-xl">{t("portal.payerDynamicPortal", { product: selectedClient })}</h3>
              {isFRM ? (
                <p className="h-24">{t("portal.payerPortalDescriptionFrm")}</p>
              ) : (
                <p className="h-24">
                  {t([`portal.payerPortalDescription.${selectedClient}`, `portal.payerPortalDescription.default`])}
                </p>
              )}
              <div className="flex flex-col space-y-1">
                {showPayerLandscape && (
                  <Link className={buttonClasses} to={routes.client.dpp.payers.with({ client: selectedClient })}>
                    {routes.client.dpp.payers.label}
                    <ChevronRightIcon />
                  </Link>
                )}
                {showHCPLandscape && (
                  <Link className={buttonClasses} to={routes.client.dpp.hcps.with({ client: selectedClient })}>
                    {routes.client.dpp.hcps.label}
                    <ChevronRightIcon />
                  </Link>
                )}
              </div>
            </div>
          )}
          {showPrintPortal && (
            <div className={showPayerLandscape && !planProfileVisible ? "w-1/2" : "w-full"}>
              <h3 className="font-semibold text-xl">
                {i18n.exists(`portal.costAndCoveragePortal.${selectedClient}`)
                  ? t(`portal.costAndCoveragePortal.${selectedClient}`)
                  : t("portal.costAndCoveragePortal.default", { product: selectedClient })}
              </h3>
              <p className="h-24">
                {i18n.exists(`portal.costAndCoverageDescription.${selectedClient}`)
                  ? t(`portal.costAndCoverageDescription.${selectedClient}`)
                  : t(`portal.costAndCoverageDescription.default`)}
              </p>
              <div className="flex flex-col space-y-1">
                {printConfig.hcpPrintMessages && (
                  <Link className={buttonClasses} to={routes.client.print.messages.with({ client: selectedClient })}>
                    {routes.client.print.messages.label}
                    <ChevronRightIcon />
                  </Link>
                )}
                {printConfig.hcpEmailMessages && (
                  <Link className={buttonClasses} to={routes.client.print.email.with({ client: selectedClient })}>
                    {routes.client.print.email.label}
                    <ChevronRightIcon />
                  </Link>
                )}
                {printConfig.resourceLibrary && (
                  <Link className={buttonClasses} to={routes.client.print.resources.with({ client: selectedClient })}>
                    {routes.client.print.resources.label}
                    <ChevronRightIcon />
                  </Link>
                )}
              </div>
            </div>
          )}
        </div>
        {howToVideo && (
          <p className="text-xs text-slate-gray whitespace-pre-line leading-6 px-12 pb-6">
            <Link className={buttonClasses} to={routes.client.howTo.with({ client: selectedClient })}>
              <div>
                <InfoIcon className="mr-2" />
                {t("howTo.linkText")}
              </div>
              <ChevronRightIcon />
            </Link>
          </p>
        )}
        {footnote && <p className="text-xs text-slate-gray whitespace-pre-line leading-6 px-12 pb-6">{footnote}</p>}
      </div>
    </div>
  );
};

export default PickAPortal;
