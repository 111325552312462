/* tslint:disable */
/* eslint-disable */
/**
 * Milliman.PullThroughPortal.WebAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  ErrorResponse,
  ErrorResponseFromJSON,
  ErrorResponseFromJSONTyped,
  ErrorResponseToJSON,
  UserPreferences,
  UserPreferencesFromJSON,
  UserPreferencesFromJSONTyped,
  UserPreferencesToJSON,
} from "./";

/**
 *
 * @export
 * @interface UserPreferencesControllerResponse
 */
export interface UserPreferencesControllerResponse {
  /**
   *
   * @type {UserPreferences}
   * @memberof UserPreferencesControllerResponse
   */
  data?: UserPreferences;
  /**
   *
   * @type {ErrorResponse}
   * @memberof UserPreferencesControllerResponse
   */
  error?: ErrorResponse;
}

export function UserPreferencesControllerResponseFromJSON(json: any): UserPreferencesControllerResponse {
  return UserPreferencesControllerResponseFromJSONTyped(json, false);
}

export function UserPreferencesControllerResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserPreferencesControllerResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: !exists(json, "data") ? undefined : UserPreferencesFromJSON(json["data"]),
    error: !exists(json, "error") ? undefined : ErrorResponseFromJSON(json["error"]),
  };
}

export function UserPreferencesControllerResponseToJSON(value?: UserPreferencesControllerResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: UserPreferencesToJSON(value.data),
    error: ErrorResponseToJSON(value.error),
  };
}
