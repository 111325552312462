/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import { useAuth0 } from "@developertown/oidc-provider";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { GeographyType, TreeData } from "../components/GeoSelect/GeoTypes";
import { useGlobalDispatch, useGlobalState } from "../data/global/hooks";
import { District, Region, Territory } from "../generate";
import { PermissionsApi } from "../generate/apis";
import { call } from "./api";
import {
  useClientDefaultGeographyLevelConfiguration,
  useClientRootGeographyLevelConfiguration,
} from "../data/global/useProductConfiguration";
import { GeoPayload } from "../data/global/types";

const getDefaultNationalGeo = (defaultGeoLevel: string | null, type: GeographyType | null, firstGeoName: string) => {
  const defaultGeo: GeoPayload = { type, name: null };
  if (defaultGeoLevel) {
    defaultGeo.name = defaultGeoLevel === GeographyType.National ? GeographyType.National : firstGeoName;
  }
  return defaultGeo;
};

export const useGeoTreeApi = (client: string) => {
  const Auth0 = useAuth0();
  const user = Auth0.user;
  const { selectedGeoName, selectedGeoType, userEmail } = useGlobalState();
  const { updateRootGeo, updateSelectedGeo, updateUserEmail } = useGlobalDispatch();

  const defaultGeographyLevel = useClientDefaultGeographyLevelConfiguration(client);
  const defaultRootGeographyLevel = useClientRootGeographyLevelConfiguration(client);

  const userHasChanged = user?.email !== userEmail;

  //Update user email so we can track if the user changes
  useEffect(() => {
    if (userHasChanged) {
      updateUserEmail(user?.email ?? "");
    }
  }, [userEmail, user, userHasChanged]);

  const fetch = async () => {
    const { data } = await call(PermissionsApi).permissionsGeographyTreeviewGet({ client });
    const nothingSelected = !selectedGeoName || !selectedGeoType;

    const filterTree = (treeData: TreeData[]) => {
      //Check for only territory access
      if (
        treeData.length === 1 &&
        treeData[0].children?.length === 1 &&
        treeData[0].children[0].children?.length === 1
      ) {
        updateRootGeo({ type: GeographyType.Territory, name: treeData[0].children[0].children[0].name });
        updateSelectedGeo({ type: GeographyType.Territory, name: treeData[0].children[0].children[0].name });
        return treeData[0].children[0].children;
      }

      //Check for district access
      if (treeData.length === 1 && treeData[0].children?.length === 1 && treeData[0].children[0].children) {
        updateRootGeo({ type: GeographyType.District, name: treeData[0].children[0].name });
        updateSelectedGeo({ type: GeographyType.District, name: treeData[0].children[0].name });
        return treeData[0].children[0].children;
      }

      //Check for region access
      if (treeData.length === 1 && treeData[0].children) {
        updateRootGeo({ type: GeographyType.Region, name: treeData[0].name });
        if (nothingSelected || userHasChanged) {
          updateSelectedGeo({ type: GeographyType.Region, name: treeData[0].name });
        }
        return treeData[0].children;
      }

      //Return all access
      updateRootGeo(getDefaultNationalGeo(defaultRootGeographyLevel, defaultRootGeographyLevel, treeData[0].name));
      if (nothingSelected || userHasChanged) {
        updateSelectedGeo(getDefaultNationalGeo(defaultGeographyLevel, defaultGeographyLevel, treeData[0].name));
      }
      return treeData;
    };

    const treeData =
      data === undefined || data === null || data.regions === null || data.regions === undefined
        ? []
        : filterTree(
            data.regions.map(
              (region: Region): TreeData => ({
                name: region.name || "",
                type: GeographyType.Region,
                hasAccess: region.hasAccess!,
                children: (region.districts || []).map(
                  (district: District): TreeData => ({
                    name: district.name || "",
                    type: GeographyType.District,
                    hasAccess: district.hasAccess!,
                    children: (district.territories || []).map(
                      (territory: Territory): TreeData => ({
                        name: territory.name || "",
                        type: GeographyType.Territory,
                        hasAccess: territory.hasAccess!,
                      }),
                    ),
                  }),
                ),
              }),
            ),
          );

    return treeData;
  };

  return useQuery<TreeData[], any>(["geo-tree", client], fetch, {
    retry: false,
    enabled: Boolean(client),
  });
};
