import React from "react";
import { useTranslation } from "react-i18next";

const PrintWinLegend: React.FC = () => {
  const [t] = useTranslation();

  return (
    <div className="flex space-x-4">
      <div className="flex space-x-2 items-center">
        <div className="w-3 h-3 bg-primary rounded-sm"></div>
        <span>{t("table.planWin")}</span>
      </div>
      <div className="flex space-x-2 items-center">
        <div className="w-3 h-3 bg-error rounded-sm"></div>
        <span>{t("table.printRestriction")}</span>
      </div>
    </div>
  );
};

export default PrintWinLegend;
