/* tslint:disable */
/* eslint-disable */
/**
 * Milliman.PullThroughPortal.WebAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface PlanFeed
 */
export interface PlanFeed {
  /**
   *
   * @type {string}
   * @memberof PlanFeed
   */
  m_plan_id?: string | null;
  /**
   *
   * @type {string}
   * @memberof PlanFeed
   */
  m_plan_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof PlanFeed
   */
  pbm_vendor?: string | null;
  /**
   *
   * @type {string}
   * @memberof PlanFeed
   */
  product?: string | null;
  /**
   *
   * @type {string}
   * @memberof PlanFeed
   */
  client_flag?: string | null;
  /**
   *
   * @type {string}
   * @memberof PlanFeed
   */
  client?: string | null;
  /**
   *
   * @type {string}
   * @memberof PlanFeed
   */
  channel?: string | null;
  /**
   *
   * @type {number}
   * @memberof PlanFeed
   */
  lives?: number | null;
  /**
   *
   * @type {string}
   * @memberof PlanFeed
   */
  level?: string | null;
  /**
   *
   * @type {string}
   * @memberof PlanFeed
   */
  geography?: string | null;
  /**
   *
   * @type {string}
   * @memberof PlanFeed
   */
  cc_status?: string | null;
  /**
   *
   * @type {string}
   * @memberof PlanFeed
   */
  rel_status?: string | null;
  /**
   *
   * @type {string}
   * @memberof PlanFeed
   */
  hcp_count?: string | null;
  /**
   *
   * @type {number}
   * @memberof PlanFeed
   */
  prod_trx?: number | null;
  /**
   *
   * @type {number}
   * @memberof PlanFeed
   */
  mkt_trx?: number | null;
  /**
   *
   * @type {number}
   * @memberof PlanFeed
   */
  mkt_share?: number | null;
  /**
   *
   * @type {number}
   * @memberof PlanFeed
   */
  share_delta?: number | null;
  /**
   *
   * @type {string}
   * @memberof PlanFeed
   */
  pct_bob?: string | null;
  /**
   *
   * @type {string}
   * @memberof PlanFeed
   */
  timePeriod?: string | null;
  /**
   *
   * @type {string}
   * @memberof PlanFeed
   */
  indication?: string | null;
  /**
   *
   * @type {number}
   * @memberof PlanFeed
   */
  comp_prod_trx?: number | null;
  /**
   *
   * @type {string}
   * @memberof PlanFeed
   */
  comp_cc_status?: string | null;
  /**
   *
   * @type {string}
   * @memberof PlanFeed
   */
  comp_rel_status?: string | null;
  /**
   *
   * @type {string}
   * @memberof PlanFeed
   */
  win_flag?: string | null;
  /**
   *
   * @type {string}
   * @memberof PlanFeed
   */
  pr_flag?: string | null;
}

export function PlanFeedFromJSON(json: any): PlanFeed {
  return PlanFeedFromJSONTyped(json, false);
}

export function PlanFeedFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlanFeed {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    m_plan_id: !exists(json, "m_plan_id") ? undefined : json["m_plan_id"],
    m_plan_name: !exists(json, "m_plan_name") ? undefined : json["m_plan_name"],
    pbm_vendor: !exists(json, "pbm_vendor") ? undefined : json["pbm_vendor"],
    product: !exists(json, "product") ? undefined : json["product"],
    client_flag: !exists(json, "client_flag") ? undefined : json["client_flag"],
    client: !exists(json, "client") ? undefined : json["client"],
    channel: !exists(json, "channel") ? undefined : json["channel"],
    lives: !exists(json, "lives") ? undefined : json["lives"],
    level: !exists(json, "level") ? undefined : json["level"],
    geography: !exists(json, "geography") ? undefined : json["geography"],
    cc_status: !exists(json, "cc_status") ? undefined : json["cc_status"],
    rel_status: !exists(json, "rel_status") ? undefined : json["rel_status"],
    hcp_count: !exists(json, "hcp_count") ? undefined : json["hcp_count"],
    prod_trx: !exists(json, "prod_trx") ? undefined : json["prod_trx"],
    mkt_trx: !exists(json, "mkt_trx") ? undefined : json["mkt_trx"],
    mkt_share: !exists(json, "mkt_share") ? undefined : json["mkt_share"],
    share_delta: !exists(json, "share_delta") ? undefined : json["share_delta"],
    pct_bob: !exists(json, "pct_bob") ? undefined : json["pct_bob"],
    timePeriod: !exists(json, "timePeriod") ? undefined : json["timePeriod"],
    indication: !exists(json, "indication") ? undefined : json["indication"],
    comp_prod_trx: !exists(json, "comp_prod_trx") ? undefined : json["comp_prod_trx"],
    comp_cc_status: !exists(json, "comp_cc_status") ? undefined : json["comp_cc_status"],
    comp_rel_status: !exists(json, "comp_rel_status") ? undefined : json["comp_rel_status"],
    win_flag: !exists(json, "win_flag") ? undefined : json["win_flag"],
    pr_flag: !exists(json, "pr_flag") ? undefined : json["pr_flag"],
  };
}

export function PlanFeedToJSON(value?: PlanFeed | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    m_plan_id: value.m_plan_id,
    m_plan_name: value.m_plan_name,
    pbm_vendor: value.pbm_vendor,
    product: value.product,
    client_flag: value.client_flag,
    client: value.client,
    channel: value.channel,
    lives: value.lives,
    level: value.level,
    geography: value.geography,
    cc_status: value.cc_status,
    rel_status: value.rel_status,
    hcp_count: value.hcp_count,
    prod_trx: value.prod_trx,
    mkt_trx: value.mkt_trx,
    mkt_share: value.mkt_share,
    share_delta: value.share_delta,
    pct_bob: value.pct_bob,
    timePeriod: value.timePeriod,
    indication: value.indication,
    comp_prod_trx: value.comp_prod_trx,
    comp_cc_status: value.comp_cc_status,
    comp_rel_status: value.comp_rel_status,
    win_flag: value.win_flag,
    pr_flag: value.pr_flag,
  };
}
