/* tslint:disable */
/* eslint-disable */
/**
 * Milliman.PullThroughPortal.WebAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface AuditInfo
 */
export interface AuditInfo {
  /**
   *
   * @type {string}
   * @memberof AuditInfo
   */
  name?: string | null;
  /**
   *
   * @type {string}
   * @memberof AuditInfo
   */
  email?: string | null;
  /**
   *
   * @type {string}
   * @memberof AuditInfo
   */
  document?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof AuditInfo
   */
  includesPI?: boolean;
  /**
   *
   * @type {string}
   * @memberof AuditInfo
   */
  client?: string | null;
  /**
   *
   * @type {string}
   * @memberof AuditInfo
   */
  downloadFormat?: string | null;
}

export function AuditInfoFromJSON(json: any): AuditInfo {
  return AuditInfoFromJSONTyped(json, false);
}

export function AuditInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuditInfo {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: !exists(json, "name") ? undefined : json["name"],
    email: !exists(json, "email") ? undefined : json["email"],
    document: !exists(json, "document") ? undefined : json["document"],
    includesPI: !exists(json, "includesPI") ? undefined : json["includesPI"],
    client: !exists(json, "client") ? undefined : json["client"],
    downloadFormat: !exists(json, "downloadFormat") ? undefined : json["downloadFormat"],
  };
}

export function AuditInfoToJSON(value?: AuditInfo | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    email: value.email,
    document: value.document,
    includesPI: value.includesPI,
    client: value.client,
    downloadFormat: value.downloadFormat,
  };
}
