/* tslint:disable */
/* eslint-disable */
/**
 * Milliman.PullThroughPortal.WebAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface Message
 */
export interface Message {
  /**
   *
   * @type {string}
   * @memberof Message
   */
  messageName?: string | null;
  /**
   *
   * @type {string}
   * @memberof Message
   */
  description?: string | null;
  /**
   *
   * @type {string}
   * @memberof Message
   */
  implementationGuide?: string | null;
  /**
   *
   * @type {string}
   * @memberof Message
   */
  enablePI?: string | null;
}

export function MessageFromJSON(json: any): Message {
  return MessageFromJSONTyped(json, false);
}

export function MessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): Message {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    messageName: !exists(json, "messageName") ? undefined : json["messageName"],
    description: !exists(json, "description") ? undefined : json["description"],
    implementationGuide: !exists(json, "implementationGuide") ? undefined : json["implementationGuide"],
    enablePI: !exists(json, "enablePI") ? undefined : json["enablePI"],
  };
}

export function MessageToJSON(value?: Message | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    messageName: value.messageName,
    description: value.description,
    implementationGuide: value.implementationGuide,
    enablePI: value.enablePI,
  };
}
