import KeyboardArrowDownOutlinedIcon from "@material-ui/icons/KeyboardArrowDownOutlined";
import React from "react";
import { useTranslation } from "react-i18next";

interface Props extends Omit<React.SelectHTMLAttributes<HTMLSelectElement>, "value"> {
  label: string;
  value?: string | null;
  handleChange: (value: string) => void;
  options: Option[];
  minWidth?: number;
}

interface Option {
  value: string;
  label: string;
  disabled?: boolean;
}

const MinimalSelect: React.FC<Props> = ({ value, label, options, handleChange, minWidth, ...selectProps }) => {
  const [t] = useTranslation();
  const hasMultipleOptions = options.length > 1;
  const selectValue = value === null ? undefined : value;
  return (
    <div style={{ minWidth: minWidth }} className="md:inline-block">
      <label htmlFor={label} className="body-3">
        {label}
      </label>
      <div className="ring-primary focus-within:ring-2 mt-1 mb-2 pr-3">
        <select
          disabled={!hasMultipleOptions}
          value={selectValue}
          aria-label={label}
          id={label}
          className="w-full cursor-pointer py-1 pr-6 focus:border-b focus:border-primary font-bold text-brand text-lg opacity-100"
          onBlur={(e) => handleChange(e.target.value)}
          onChange={(e) => handleChange(e.target.value)}
          {...selectProps}
        >
          {options.map((option) => (
            <option key={option.value} value={option.value} disabled={option.disabled}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
      {hasMultipleOptions && (
        <button tabIndex={-1} className="float-right -mt-10 mr-2 text-brand" title={t("common.selectDropdownArrow")}>
          <KeyboardArrowDownOutlinedIcon />
        </button>
      )}
    </div>
  );
};

export default MinimalSelect;
