module.exports = {
  black: "#000000",
  white: "#ffffff",
  brand: "#004877",
  brandDark: "#00263F",
  primary: "#0275ce", //Is  "#0080E2" in the mockups, needed to be a tad bit darker for contrast issues
  primaryHover: "#0067B5",
  secondary: "#65777d", // Is "#727A7D" in the mockups, needed to be a tad bit darker for contrast issues
  secondaryHover: "#39414D",
  error: "#A52040",
  errorHover: "#841A33",
};
