import { useSnackbar } from "notistack";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { usePrintZippedDocs } from "../api/usePrintZippedDocs";
import SnackBar, { SnackVariant } from "../components/SnackBar/SnackBar";
import { useQueueDispatch, useQueueState } from "../data/queue/hooks";
import { PrintJobStatus, QueueItem } from "../data/queue/types";

const useDownloadZippedDocs = (client: string) => {
  const [t] = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { queue, printJob } = useQueueState();
  const { updatePrintJob, removeQueueItems, addQueueItems } = useQueueDispatch();
  const printZippedDocs = usePrintZippedDocs();

  const downloadAll = useCallback(() => {
    const postData = { zipPackageInput: { client, printDocs: queue } };
    removeQueueItems(queue);
    printZippedDocs.mutate(postData, {
      onSuccess: (data) => {
        if (data && data.data && data.data.jobID && data.data.packageID) {
          updatePrintJob({
            status: PrintJobStatus.Processing,
            printDocs: [],
            errorMessage: "",
            jobID: data.data.jobID,
            packageID: data.data.packageID,
            downloadURL: undefined,
          });
        }
      },
      onError: async (error, variables) => {
        //Revert clearing the queue
        const queueItems =
          variables && variables.zipPackageInput && variables.zipPackageInput.printDocs
            ? (variables.zipPackageInput.printDocs as QueueItem[])
            : [];
        addQueueItems(queueItems);

        //Get and display error message
        const response = await error.json();
        const message = response.error.message;
        updatePrintJob({ status: PrintJobStatus.Error, errorMessage: message });
        enqueueSnackbar(<SnackBar message={message} variant={SnackVariant.ERROR} />);
      },
    });

    enqueueSnackbar(<SnackBar message={t("queue.docsAreProcessing")} variant={SnackVariant.SUCCESS} />, {
      autoHideDuration: 3000,
    });
  }, [client, queue, removeQueueItems, printZippedDocs, enqueueSnackbar, t, updatePrintJob, addQueueItems]);

  useEffect(() => {
    if (printJob && printJob.status === PrintJobStatus.Start) {
      updatePrintJob({ status: PrintJobStatus.Processing });
      downloadAll();
    }
  }, [printJob, downloadAll, updatePrintJob]);
};

export default useDownloadZippedDocs;
