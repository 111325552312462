import { useQuery } from "react-query";
import { call } from "../api/api";
import { PermissionsApi } from "../generate";

export const FRM_ROLE = "frm";
export const GENERAL_ROLE = "general";
export const BIOSIM_ROLE = "biosfield";

const useGetRole = (client: string) => {
  const fetch = async () => {
    const { data } = await call(PermissionsApi).permissionsRoleGet({ client });

    return data;
  };

  return useQuery<Promise<string | undefined | null>>(["user-role", client], fetch, {
    retry: true,
    enabled: Boolean(client),
  });
};

export default useGetRole;
