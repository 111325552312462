import InfoOutlined from "@material-ui/icons/InfoOutlined";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useHcpLandscapeApi, useHcpLandscapeDropdowns, useHcpLandscapeExport } from "../../api/useHcpLandscapeApi";
import { DataWindow, useDataWindows, useMetaData } from "../../api/useMetaDataApi";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import GeoSelect from "../../components/GeoSelect/GeoSelect";
import PageHeader from "../../components/Headers/PageHeader";
import MinimalSelect from "../../components/Inputs/MinimalSelect";
import Select from "../../components/Inputs/Select";
import { useModal } from "../../components/Modal/useModal";
import SearchBox from "../../components/SearchBox/SearchBox";
import Table from "../../components/Table/Table";
import Tooltip from "../../components/Tooltip/Tooltip";
import {
  useClientHCPLandscapeConfiguration,
  useClientIndicationFiltersconfiguration,
} from "../../data/global/useProductConfiguration";
import { useHcpLandscapeDispatch, useHcpLandscapeState } from "../../data/hcpLandscape/hooks";
import useGetRole from "../../hooks/useGetRole";
import useHasFootnote from "../../hooks/useHasFootnote";
import { arrayToOptions } from "../../utils/arrayToOptions";
import DetailFiltersModal from "./HCPLandscapeFiltersModal";
import useTableColumns from "./useTableColumns";

const HCPLandscape: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { setModal } = useModal();
  const { client: selectedClient } = useParams<{ client: string }>();
  const useDefault = i18n.exists(`hcpLandscape.hcps.${selectedClient}`) ? false : true;
  const { columns } = useTableColumns(useDefault ? "default" : selectedClient);
  const columnNames = columns.map((column) => t(column.label, column.translationParams));
  const columnKeys = columns.map((column) => column.dataKey);
  const [search, setSearch] = useState<string>("");
  const { updateSort, updateFilters } = useHcpLandscapeDispatch();
  const { filters, advancedFilterCount, sort } = useHcpLandscapeState();
  const hcpQuery = useHcpLandscapeApi(selectedClient);
  const exportTable = useHcpLandscapeExport(selectedClient, columnKeys, columnNames);
  const dropdownQuery = useHcpLandscapeDropdowns(selectedClient);
  const dropdowns = dropdownQuery.data;
  const beneOptions = arrayToOptions(dropdowns?.benificiaryType, false, t("common.allChannels"));
  const { data: metaData } = useMetaData(selectedClient, filters.timePeriod || "");
  const { data: dataWindows } = useDataWindows(selectedClient);
  const { data: role } = useGetRole(selectedClient);
  const hasFootnote = useHasFootnote(selectedClient);
  const { indicationFiltering: showIndicationFiltering } = useClientHCPLandscapeConfiguration(selectedClient);
  const indicationOptions = useClientIndicationFiltersconfiguration(selectedClient);

  const timePeriodOptions = (dataWindows || []).map((dataWindow) => {
    return {
      label: t(`payerLandscape.timePeriodOptions.${dataWindow}`),
      value: dataWindow,
    };
  });
  const defaultDataWindow =
    (dataWindows || []).find((dataWindow) => dataWindow === DataWindow.twelveMonths) || DataWindow.twelveMonths;
  const showTimePeriodSelection = (dataWindows || []).length > 1;

  //IF INDICATION SET, BUT SHOULDNT BE, SET TO UNDEFINED
  useEffect(() => {
    if (!showIndicationFiltering) {
      updateFilters({ indication: undefined });
    }
  }, [updateFilters, indicationOptions, filters.indication, showIndicationFiltering]);

  //IF BENETYPE IS INVALID, SET TO FIRST VALID OPTION
  useEffect(() => {
    if (!dropdowns?.benificiaryType) {
      return;
    }
    if (!dropdowns?.benificiaryType?.includes(filters.benificiaryType || "") && dropdowns?.benificiaryType.length > 0) {
      updateFilters({
        benificiaryType: dropdowns?.benificiaryType[0],
      });
    }
  }, [updateFilters, beneOptions, filters.benificiaryType, dropdowns?.benificiaryType]);

  //IF NO INDICATION SET, SET TO FIRST PRODUCT AVAILABLE
  useEffect(() => {
    if (
      indicationOptions &&
      showIndicationFiltering &&
      indicationOptions !== undefined &&
      indicationOptions.length > 0 &&
      filters.indication === undefined
    ) {
      updateFilters({ indication: indicationOptions[0].value });
    }
  }, [updateFilters, indicationOptions, filters.indication, showIndicationFiltering]);

  //Set the default beneficiaryType to first option (Commerical)
  useEffect(() => {
    if (!filters.benificiaryType && beneOptions.length > 1) {
      updateFilters({ benificiaryType: beneOptions[0].value });
    }
  }, [beneOptions, filters.benificiaryType, updateFilters]);

  //Set the default timePeriod to last 12 months
  useEffect(() => {
    if (!filters.timePeriod && (dataWindows || []).length > 0) {
      updateFilters({ timePeriod: defaultDataWindow });
    }
  }, [dataWindows, defaultDataWindow, filters.timePeriod, updateFilters]);

  const openFilterModal = () => {
    setModal(<DetailFiltersModal client={selectedClient} />);
  };

  //Debounce search and update genericFilter
  useEffect(() => {
    const timeoutAmount = search === "" ? 0 : 400;
    const timeoutId = setTimeout(() => {
      updateFilters({ genericFilter: search });
    }, timeoutAmount);

    //If a new keystroke happens before timeout, clear the timeout
    return () => {
      clearTimeout(timeoutId);
    };
  }, [search, updateFilters]);

  return (
    <>
      <PageHeader
        title={
          i18n.exists(`common.hcpLandscape.${selectedClient}`)
            ? t(`common.hcpLandscape.${selectedClient}`)
            : t(`common.hcpLandscape.default`)
        }
      >
        <SearchBox value={search} handleChange={setSearch} />
      </PageHeader>

      <BreadCrumbs />

      <div className="pb-4">
        <div className="md:flex md:space-x-10  justify-end">
          <div className={indicationOptions && showIndicationFiltering ? "lg:w-2/12" : ""}>
            {indicationOptions && showIndicationFiltering && (
              <MinimalSelect
                value={filters.indication}
                handleChange={(value) => updateFilters({ indication: value })}
                label={t("payerLandscape.planInfoFor")}
                options={indicationOptions}
              />
            )}
          </div>
          <div className="w-full xl:w-10/12 md:flex md:space-x-10">
            {showTimePeriodSelection ? (
              <Select
                value={filters.timePeriod}
                label={t("payerLandscape.timePeriod")}
                options={timePeriodOptions}
                handleChange={(value) => updateFilters({ timePeriod: value })}
              />
            ) : null}
            <GeoSelect client={selectedClient} />
            <Select
              value={filters.benificiaryType}
              handleChange={(value) => updateFilters({ benificiaryType: value })}
              label={t("payerLandscape.beneType")}
              options={beneOptions}
            />
          </div>
        </div>
        <div className="flex  md:justify-end">
          <button
            onClick={openFilterModal}
            className="text-primary w-full md:w-auto font-bold py-3 px-2 md:py-1 md:ml-0 md:-mr-2 rounded"
          >
            {t("common.advancedFilterWithCount", { count: advancedFilterCount })}
          </button>
        </div>
      </div>

      <div className="flex flex-row-reverse mb-2">
        <Tooltip
          placement="bottom-end"
          tip={t([
            `hcpLandscape.tooltip.${filters.timePeriod}.${selectedClient}`,
            `hcpLandscape.tooltip.${filters.timePeriod}.default`,
          ])}
        >
          <InfoOutlined className="text-primary" />
        </Tooltip>
      </div>

      <Table
        columns={columns}
        query={hcpQuery}
        sort={sort}
        handleSort={(sort) => updateSort(sort)}
        export={exportTable}
        stickyMargin={54}
        metaData={metaData}
        role={String(role)}
        footnote={hasFootnote ? t([`hcpLandscape.footnote.${selectedClient}`, `hcpLandscape.footnote.default`]) : false}
      />
    </>
  );
};

export default HCPLandscape;
