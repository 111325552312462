import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import globalReducer from "../data/global/reducer";
import hcpLandscapeReducer from "../data/hcpLandscape/reducer";
import hcpSpecificMessagingReducer from "../data/hcpSpecificMessaging/reducer";
import payerLandscapeReducer from "../data/payerLandscape/reducer";
import queueReducer from "../data/queue/reducer";
import userReducer from "../data/user/reducer";
import { AppState } from "./types";

const queuePersistConfig = {
  key: "queue",
  storage,
  whitelist: ["queue", "printJob"],
};

const globalPersistConfig = {
  key: "global",
  storage,
  whitelist: ["userEmail"],
};

const hcpLandscapePersistConfig = {
  key: "hcpLandscape",
  storage,
  whitelist: ["filters"],
};

const payerLandscapePersistConfig = {
  key: "payerLandscape",
  storage,
  whitelist: ["sharedFilters"],
};

const rootReducer = combineReducers<AppState>({
  queue: persistReducer(queuePersistConfig, queueReducer),
  payerLandscape: persistReducer(payerLandscapePersistConfig, payerLandscapeReducer),
  hcpLandscape: persistReducer(hcpLandscapePersistConfig, hcpLandscapeReducer),
  hcpSpecificMessaging: hcpSpecificMessagingReducer,
  user: userReducer,
  global: persistReducer(globalPersistConfig, globalReducer),
});

export default rootReducer;
