/* tslint:disable */
/* eslint-disable */
/**
 * Milliman.PullThroughPortal.WebAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface CoverageStatus
 */
export interface CoverageStatus {
  /**
   *
   * @type {string}
   * @memberof CoverageStatus
   */
  m_plan_id?: string | null;
  /**
   *
   * @type {string}
   * @memberof CoverageStatus
   */
  m_plan_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof CoverageStatus
   */
  channel?: string | null;
  /**
   *
   * @type {number}
   * @memberof CoverageStatus
   */
  bob_pct_ovrl?: number;
  /**
   *
   * @type {number}
   * @memberof CoverageStatus
   */
  bob_pct_chnl?: number;
  /**
   *
   * @type {string}
   * @memberof CoverageStatus
   */
  rel_status?: string | null;
  /**
   *
   * @type {string}
   * @memberof CoverageStatus
   */
  cc_status?: string | null;
  /**
   *
   * @type {number}
   * @memberof CoverageStatus
   */
  prod_trx?: number;
  /**
   *
   * @type {number}
   * @memberof CoverageStatus
   */
  mkt_trx?: number;
  /**
   *
   * @type {number}
   * @memberof CoverageStatus
   */
  prod_share?: number;
  /**
   *
   * @type {number}
   * @memberof CoverageStatus
   */
  comp_prod_trx?: number;
}

export function CoverageStatusFromJSON(json: any): CoverageStatus {
  return CoverageStatusFromJSONTyped(json, false);
}

export function CoverageStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): CoverageStatus {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    m_plan_id: !exists(json, "m_plan_id") ? undefined : json["m_plan_id"],
    m_plan_name: !exists(json, "m_plan_name") ? undefined : json["m_plan_name"],
    channel: !exists(json, "channel") ? undefined : json["channel"],
    bob_pct_ovrl: !exists(json, "bob_pct_ovrl") ? undefined : json["bob_pct_ovrl"],
    bob_pct_chnl: !exists(json, "bob_pct_chnl") ? undefined : json["bob_pct_chnl"],
    rel_status: !exists(json, "rel_status") ? undefined : json["rel_status"],
    cc_status: !exists(json, "cc_status") ? undefined : json["cc_status"],
    prod_trx: !exists(json, "prod_trx") ? undefined : json["prod_trx"],
    mkt_trx: !exists(json, "mkt_trx") ? undefined : json["mkt_trx"],
    prod_share: !exists(json, "prod_share") ? undefined : json["prod_share"],
    comp_prod_trx: !exists(json, "comp_prod_trx") ? undefined : json["comp_prod_trx"],
  };
}

export function CoverageStatusToJSON(value?: CoverageStatus | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    m_plan_id: value.m_plan_id,
    m_plan_name: value.m_plan_name,
    channel: value.channel,
    bob_pct_ovrl: value.bob_pct_ovrl,
    bob_pct_chnl: value.bob_pct_chnl,
    rel_status: value.rel_status,
    cc_status: value.cc_status,
    prod_trx: value.prod_trx,
    mkt_trx: value.mkt_trx,
    prod_share: value.prod_share,
    comp_prod_trx: value.comp_prod_trx,
  };
}
