/* tslint:disable */
/* eslint-disable */
/**
 * Milliman.PullThroughPortal.WebAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ZipPackageInfo
 */
export interface ZipPackageInfo {
  /**
   *
   * @type {string}
   * @memberof ZipPackageInfo
   */
  jobID?: string | null;
  /**
   *
   * @type {string}
   * @memberof ZipPackageInfo
   */
  packageID?: string | null;
}

export function ZipPackageInfoFromJSON(json: any): ZipPackageInfo {
  return ZipPackageInfoFromJSONTyped(json, false);
}

export function ZipPackageInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ZipPackageInfo {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    jobID: !exists(json, "jobID") ? undefined : json["jobID"],
    packageID: !exists(json, "packageID") ? undefined : json["packageID"],
  };
}

export function ZipPackageInfoToJSON(value?: ZipPackageInfo | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    jobID: value.jobID,
    packageID: value.packageID,
  };
}
