/* eslint-disable  @typescript-eslint/no-explicit-any */
import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as ExportIcon } from "../../assets/ExportIcon.svg";
import ErrorDisplayTableRow from "../ErrorDisplay/ErrorDisplayTableRow";
import NoResultsTableRow from "../NoResults/NoResultsTableRow";
import SkeletonTableBody from "../Skeletons/SkeletonTableBody";
import ColumnFilterButton from "./ColumnSortButton";
import Components from "./Components";
import MobileSorter from "./MobileSorter";
import { Column, Sort } from "./types";

const headerHeight = 530;
const rowHeight = 44;
const calculatedTableHeight = window.innerHeight - headerHeight;
const tableHeight = calculatedTableHeight < 200 ? 200 : calculatedTableHeight;
const rowCount = Math.ceil(tableHeight / rowHeight) - 1;

interface Props {
  columns: Column[];
  data?: Data;
  loading: boolean;
  disableFooter?: boolean;
  error?: null | Error;
  sort: Sort;
  handleSort: (value: Sort) => void;
}

interface Data {
  data: any[];
  error: Error | null;
}

const Table: React.FC<Props> = ({ columns, data, loading, disableFooter, error, sort, handleSort }) => {
  const [t] = useTranslation();
  const [minimizeTable, setMinimizeTable] = useState(false);
  const [newMinHasBeenSet, setNewMinHasBeenSet] = useState(false);
  const [minWindowBreak, setMinWindowBreak] = useState(640);
  const tableContainerRef = useRef<HTMLDivElement | null>(null);
  const tableRef = useRef<HTMLTableElement | null>(null);
  // Determine if table should be minimized on resize
  useEffect(() => {
    const handleResize = () => {
      if (tableRef.current && tableContainerRef.current) {
        //If table size is bigger than container, set what the new min window size is
        if (tableContainerRef.current.clientWidth < tableRef.current.clientWidth && !newMinHasBeenSet) {
          setMinWindowBreak(tableRef.current.clientWidth);
          setNewMinHasBeenSet(true);
        }

        //check to see if we are under the min window width to minimize table
        const minimize = tableContainerRef.current.clientWidth < minWindowBreak;
        setMinimizeTable(minimize);
      }
    };

    // Add event listener
    window.addEventListener("resize", handleResize);

    //Call handler right away so state gets updated on init
    handleResize();

    //Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, [tableContainerRef, tableRef, minimizeTable, minWindowBreak, newMinHasBeenSet]);

  //Render table Headers
  const TableHeaders = () => (
    <>
      {columns.map((column, index) => {
        return (
          <th style={{ width: column.width }} key={index} className={`sticky-table-header  sm:text-${column.align}`}>
            <ColumnFilterButton column={column} sort={sort} handleSort={handleSort} />
          </th>
        );
      })}
    </>
  );

  //Render tables TDs
  const Cells = (row: any) => (
    <>
      {columns.map((column) => (
        <td key={column.dataKey} data-label={column.label} className={`sm:text-${column.align}`}>
          {Components(column, row)}
        </td>
      ))}
    </>
  );

  //Render tables TRs
  const TableRows = () => (
    <tbody>
      {data !== undefined &&
        data.data.map((row, index) => {
          return (
            <tr key={index}>
              <Cells row={row} />
            </tr>
          );
        })}
    </tbody>
  );

  //Determine what to render in the table body
  const TableBody = () => {
    if (loading) {
      return <SkeletonTableBody columns={columns} rowCount={rowCount} />;
    }
    if (error) {
      return <ErrorDisplayTableRow columnCount={columns.length} height={tableHeight} error={error} />;
    }
    if (data === undefined) {
      return <NoResultsTableRow columnCount={columns.length} height={tableHeight} />;
    }
    return <TableRows />;
  };

  const TableFooter = () => (
    <div className="fixed w-full bottom-0 pb-6 bg-white left-0 px-6">
      <div className="lg:px-6 lg:container">
        <div className="bg-rules flex items-center justify-between">
          <div className="pl-3">{loading && <span>{t("common.loading")}</span>}</div>
          <button
            className="px-4 space-x-3 hover:bg-light-blue disabled:opacity-25 disabled:cursor-not-allowed flex items-center border-l border-white"
            disabled={data === undefined || data.data.length === 0 || loading}
          >
            <p className="">{t("table.exportTable")}</p>
            <div className="pt-3 pb-4">
              <ExportIcon />
            </div>
          </button>
        </div>
      </div>
    </div>
  );

  const tableClasses = classNames("w-full", {
    "minimize-table": minimizeTable,
  });

  return (
    <div className="lg:container">
      <div ref={tableContainerRef} className="pb-6">
        {minimizeTable && <MobileSorter columns={columns} sort={sort} handleSort={handleSort} />}
        <table ref={tableRef} className={tableClasses}>
          <thead className="bg-rules">
            <tr>
              <TableHeaders />
            </tr>
          </thead>
          <TableBody />
        </table>
      </div>
      {!disableFooter && <TableFooter />}
    </div>
  );
};

export default Table;
