import { reducerWithInitialState } from "typescript-fsa-reducers";
import { updateGenericFilter } from "./actions";
import { HcpSpecificMessagingState } from "./types";

export const initialState: HcpSpecificMessagingState = {
  filters: {
    genericFilter: "",
  },
};

const reducer = reducerWithInitialState(initialState).case(updateGenericFilter, (state, genericFilter) => ({
  ...state,
  filters: {
    ...state.filters,
    genericFilter,
  },
}));

export default reducer;
