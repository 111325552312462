/* tslint:disable */
/* eslint-disable */
/**
 * Milliman.PullThroughPortal.WebAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import { Territory, TerritoryFromJSON, TerritoryFromJSONTyped, TerritoryToJSON } from "./";

/**
 *
 * @export
 * @interface District
 */
export interface District {
  /**
   *
   * @type {string}
   * @memberof District
   */
  name?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof District
   */
  hasAccess?: boolean;
  /**
   *
   * @type {Array<Territory>}
   * @memberof District
   */
  territories?: Array<Territory> | null;
}

export function DistrictFromJSON(json: any): District {
  return DistrictFromJSONTyped(json, false);
}

export function DistrictFromJSONTyped(json: any, ignoreDiscriminator: boolean): District {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: !exists(json, "name") ? undefined : json["name"],
    hasAccess: !exists(json, "hasAccess") ? undefined : json["hasAccess"],
    territories: !exists(json, "territories")
      ? undefined
      : json["territories"] === null
      ? null
      : (json["territories"] as Array<any>).map(TerritoryFromJSON),
  };
}

export function DistrictToJSON(value?: District | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    hasAccess: value.hasAccess,
    territories:
      value.territories === undefined
        ? undefined
        : value.territories === null
        ? null
        : (value.territories as Array<any>).map(TerritoryToJSON),
  };
}
