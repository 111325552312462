import PrintOutlinedIcon from "@material-ui/icons/PrintOutlined";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { useResourceLibrary } from "../../api/useResourceLibrary";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import PageHeader from "../../components/Headers/PageHeader";
import SearchBox from "../../components/SearchBox/SearchBox";
import { useQueueState } from "../../data/queue/hooks";
import useFuse from "../../hooks/useFuse";
import { useClientRoutesAndLabels } from "../../ui/routes";
import ResourceGrid from "./ResourceGrid";

const Resources: React.FC = () => {
  const [t] = useTranslation();
  const resourceQuery = useResourceLibrary();
  const { client: selectedClient } = useParams<{ client: string }>();
  const routes = useClientRoutesAndLabels(selectedClient);

  //Configure fuse search
  const options = {
    returnFullListOnNoSearch: true,
    includeMatches: true,
    threshold: 0.3,
    keys: ["display_name", "pdf_name"],
  };
  const [search, setSearch, filteredResources] = useFuse(resourceQuery.data ?? [], options);

  const { queue } = useQueueState();
  return (
    <>
      <PageHeader title={t("common.resourceLibrary")}>
        <div className="flex flex-col sm:flex-row space-y-3 sm:space-y-0 sm:space-x-3">
          <SearchBox value={search} handleChange={setSearch} />
          <Link to={routes.client.print.queue.with({ client: selectedClient })} className="btn btn-primary">
            <PrintOutlinedIcon />
            <span>{t("messages.viewPrintQueueWithCount", { count: queue.length })}</span>
          </Link>
        </div>
      </PageHeader>

      <BreadCrumbs />

      <ResourceGrid client={selectedClient} query={resourceQuery} filteredData={filteredResources} />
    </>
  );
};

export default Resources;
export {};
