/* tslint:disable */
/* eslint-disable */
/**
 * Milliman.PullThroughPortal.WebAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  ErrorResponse,
  ErrorResponseFromJSON,
  ErrorResponseFromJSONTyped,
  ErrorResponseToJSON,
  MetaDataLibrary,
  MetaDataLibraryFromJSON,
  MetaDataLibraryFromJSONTyped,
  MetaDataLibraryToJSON,
} from "./";

/**
 *
 * @export
 * @interface MetaDataLibraryIEnumerableControllerResponse
 */
export interface MetaDataLibraryIEnumerableControllerResponse {
  /**
   *
   * @type {Array<MetaDataLibrary>}
   * @memberof MetaDataLibraryIEnumerableControllerResponse
   */
  data?: Array<MetaDataLibrary> | null;
  /**
   *
   * @type {ErrorResponse}
   * @memberof MetaDataLibraryIEnumerableControllerResponse
   */
  error?: ErrorResponse;
}

export function MetaDataLibraryIEnumerableControllerResponseFromJSON(
  json: any,
): MetaDataLibraryIEnumerableControllerResponse {
  return MetaDataLibraryIEnumerableControllerResponseFromJSONTyped(json, false);
}

export function MetaDataLibraryIEnumerableControllerResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): MetaDataLibraryIEnumerableControllerResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: !exists(json, "data")
      ? undefined
      : json["data"] === null
      ? null
      : (json["data"] as Array<any>).map(MetaDataLibraryFromJSON),
    error: !exists(json, "error") ? undefined : ErrorResponseFromJSON(json["error"]),
  };
}

export function MetaDataLibraryIEnumerableControllerResponseToJSON(
  value?: MetaDataLibraryIEnumerableControllerResponse | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data:
      value.data === undefined
        ? undefined
        : value.data === null
        ? null
        : (value.data as Array<any>).map(MetaDataLibraryToJSON),
    error: ErrorResponseToJSON(value.error),
  };
}
