import { CircularProgress } from "@material-ui/core";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import CloseIcon from "@material-ui/icons/Close";
import InfoIcon from "@material-ui/icons/Info";
import MenuIcon from "@material-ui/icons/Menu";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import PrintOutlinedIcon from "@material-ui/icons/PrintOutlined";
import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, Redirect, useParams } from "react-router-dom";
import { useClientDropdown } from "../api/useClientDropdown";
import ClientNavigation from "../components/ClientNavigation/ClientNavigation";
import DownloadIndicator from "../components/DownloadIndicator/DownloadIndicator";
import { useModal } from "../components/Modal/useModal";
import Navigation from "../components/Navigation/Navigation";
import { useClientConfiguration, useClientPrintConfiguration } from "../data/global/useProductConfiguration";
import { useQueueState } from "../data/queue/hooks";
import useGetRole, { FRM_ROLE } from "../hooks/useGetRole";
import useHasGeoAccess from "../hooks/useHasGeoAccess";
import useHasHcpAccessAsFrm from "../hooks/useHasHcpAccessAsFrm";
import useWindowSize, { LayoutSize } from "../hooks/useWindowSize";
import { useClientRoutesAndLabels } from "../ui/routes";
const Loading: React.FC = () => {
  return (
    <div className="flex h-screen justify-center items-center">
      <CircularProgress size={80} />
    </div>
  );
};

const DefaultLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [t] = useTranslation();
  const { setScrollRef } = useModal();
  const layoutSize = useWindowSize();

  const { isLoading, data: clients } = useClientDropdown();
  const { client: selectedClient } = useParams<{ client: string }>();
  const { data: role, isFetched } = useGetRole(selectedClient);
  const routes = useClientRoutesAndLabels(selectedClient);
  const isFRM = !isFetched || String(role) === FRM_ROLE;
  const isValidClient = clients?.some((client) => client === selectedClient);
  const printConfig = useClientPrintConfiguration(selectedClient);
  const { howToVideo } = useClientConfiguration(selectedClient);
  useHasGeoAccess(selectedClient);
  const hasHcpAccessAsFrm = useHasHcpAccessAsFrm(selectedClient, isFRM);
  //track if nav is expanded when in mobile view
  const [expanded, setExpanded] = useState(false);
  const closeNav = () => {
    setExpanded(false);
  };

  //setup mainContent ref so bg doesn't scroll when modal is open
  const mainContentRef = useRef<null | HTMLDivElement>(null);
  useEffect(() => {
    setScrollRef(mainContentRef);
  }, [setScrollRef, mainContentRef]);

  const { queue } = useQueueState();

  const [isNearTop, setIsNearTop] = useState(true);
  const scrollTop = () => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  };

  const handleScroll = () => {
    setIsNearTop(window.scrollY <= 200);
  };

  useEffect(() => {
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const expandableNavClasses = classNames(
    "overflow-hidden duration-500 flex-grow lg:flex justify-between items-center",
    {
      "max-h-96": expanded,
    },
    {
      "max-h-0 lg:max-h-16": !expanded,
    },
  );

  //Prevent background from scrolling if modal is open, and prevent screenshift by scrollbar
  const appBarClasses = classNames("text-white bg-slate-gray lg:flex justify-between items-center fixed w-full z-30");
  const bodyClasses = classNames("px-6 lg:container pt-14");

  if (isLoading) {
    return <Loading />;
  }

  if (clients?.length === 0) {
    return <Redirect to={routes.noAccess.path} />;
  }

  if (!selectedClient || !isValidClient) {
    return <Redirect to={routes.path} />;
  }

  return (
    <>
      <nav className={appBarClasses}>
        <div className="px-4 header-5-regular flex justify-between items-center">
          <ClientNavigation closeNav={closeNav} />
          <div className="flex items-center">
            {layoutSize === LayoutSize.Mobile && (
              <div className="text-white lg:hidden">
                <DownloadIndicator />
              </div>
            )}
            <button className="block lg:hidden p-2 -mr-3" onClick={() => setExpanded(!expanded)}>
              {!expanded && <MenuIcon fontSize="large" />}
              {expanded && <CloseIcon fontSize="large" />}
            </button>
          </div>
        </div>
        {(!isFRM || hasHcpAccessAsFrm) && <div className="border-b lg:border-r lg:h-6 border-secondary"></div>}
        <div className={expandableNavClasses}>
          {(!isFRM || hasHcpAccessAsFrm) && <Navigation closeNav={closeNav} hasHcpAccessAsFrm={hasHcpAccessAsFrm} />}
          {layoutSize === LayoutSize.Desktop && (
            <div className="text-white hidden lg:block">
              <DownloadIndicator />
            </div>
          )}
          {!isFRM && <div className="border-b lg:border-r lg:h-6 border-secondary"></div>}
          <div className="flex lg:px-4 pb-1 lg:pb-0 space-x-2">
            {(printConfig.hcpPrintMessages || printConfig.resourceLibrary) &&
              (!isFRM || printConfig.printEnabledForFRM) && (
                <NavLink
                  onClick={closeNav}
                  to={routes.client.print.queue.with({ client: selectedClient })}
                  title={t("common.printQueue")}
                  className="nav px-3"
                  activeClassName="active-nav"
                >
                  <PrintOutlinedIcon />
                  {queue.length > 0 && <div className="queue-notification">{queue.length}</div>}
                </NavLink>
              )}
            {howToVideo && (
              <NavLink
                onClick={closeNav}
                to={routes.client.howTo.with({ client: selectedClient })}
                title={t("howTo.navIconTitle")}
                className="nav px-3"
                activeClassName="active-nav"
              >
                <InfoIcon />
              </NavLink>
            )}
            <NavLink
              onClick={closeNav}
              to={routes.client.profile.with({ client: selectedClient })}
              title={t("common.userProfile")}
              className="nav px-3"
              activeClassName="active-nav"
            >
              <PersonOutlineIcon />
            </NavLink>
          </div>
        </div>
      </nav>
      <main ref={mainContentRef} id="main-content" className="w-full">
        <div className={bodyClasses}>{children}</div>
      </main>
      {!isNearTop && (
        <button
          onClick={scrollTop}
          className="hidden xl:block bg-primary fixed right-0 bottom-0 mr-6 mb-6 text-white rounded-full p-2"
        >
          <ArrowUpwardIcon />
        </button>
      )}
    </>
  );
};

export default DefaultLayout;
