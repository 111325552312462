import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import React from "react";

const BankCheck: React.FC<SvgIconProps> = ({ titleAccess, ...props }) => {
  return (
    <SvgIcon {...props} width="29" height="26" viewBox="0 0 29 26" aria-label={titleAccess} titleAccess={titleAccess}>
      <path
        d="M19.7917 9.33333H5.20837C3.48442 9.33333 2.08337 10.7344 2.08337 12.4583V18.7083H6.25004V22.875H18.75V18.7083H22.9167V12.4583C22.9167 10.7344 21.5157 9.33333 19.7917 9.33333ZM16.6667 20.7917H8.33337V15.5833H16.6667V20.7917ZM19.7917 13.5C19.2136 13.5 18.75 13.0365 18.75 12.4583C18.75 11.8802 19.2136 11.4167 19.7917 11.4167C20.3698 11.4167 20.8334 11.8802 20.8334 12.4583C20.8334 13.0365 20.3698 13.5 19.7917 13.5ZM18.75 4.125H6.25004V8.29167H18.75V4.125Z"
        stroke="none"
      />
      <path
        d="M26.9167 5.41683H24.4167V7.91683H23.5834V5.41683H21.0834V4.5835H23.5834V2.0835H24.4167V4.5835H26.9167V5.41683Z"
        stroke="none"
      />
    </SvgIcon>
  );
};

export default BankCheck;
