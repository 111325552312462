import React from "react";
import { useTranslation } from "react-i18next";

const NoGeographySelection: React.FC = () => {
  const [t] = useTranslation();

  return (
    <div className="flex flex-col justify-center items-center w-full mt-20">
      <p className="body-1 text-brand">{t("common.noGeographySelection")}</p>
      <p className="body-2 text-slate-gray mt-2">{t("common.adjustGeographySelection")}</p>
    </div>
  );
};

export default NoGeographySelection;
