import React from "react";
import { TabProps } from "./useTabs";

const TabPanel: React.FC<TabProps> = ({ children, changeTab, currentTab, tabName, ...other }) => {
  return (
    <div role="tabpanel" hidden={currentTab !== tabName} aria-label={tabName} {...other}>
      {currentTab === tabName && <div>{children}</div>}
    </div>
  );
};

export default TabPanel;
