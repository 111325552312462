/* tslint:disable */
/* eslint-disable */
/**
 * Milliman.PullThroughPortal.WebAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface PrescribingHabits
 */
export interface PrescribingHabits {
  /**
   *
   * @type {string}
   * @memberof PrescribingHabits
   */
  product?: string | null;
  /**
   *
   * @type {string}
   * @memberof PrescribingHabits
   */
  commercialTrx?: string | null;
  /**
   *
   * @type {number}
   * @memberof PrescribingHabits
   */
  commercialMarketShare?: number;
  /**
   *
   * @type {string}
   * @memberof PrescribingHabits
   */
  medicaidTrx?: string | null;
  /**
   *
   * @type {number}
   * @memberof PrescribingHabits
   */
  medicaidMarketShare?: number;
  /**
   *
   * @type {string}
   * @memberof PrescribingHabits
   */
  medicareTrx?: string | null;
  /**
   *
   * @type {number}
   * @memberof PrescribingHabits
   */
  medicareMarketShare?: number;
  /**
   *
   * @type {string}
   * @memberof PrescribingHabits
   */
  allChannelsTrx?: string | null;
  /**
   *
   * @type {number}
   * @memberof PrescribingHabits
   */
  allChannelsMarketShare?: number;
}

export function PrescribingHabitsFromJSON(json: any): PrescribingHabits {
  return PrescribingHabitsFromJSONTyped(json, false);
}

export function PrescribingHabitsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PrescribingHabits {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    product: !exists(json, "product") ? undefined : json["product"],
    commercialTrx: !exists(json, "commercialTrx") ? undefined : json["commercialTrx"],
    commercialMarketShare: !exists(json, "commercialMarketShare") ? undefined : json["commercialMarketShare"],
    medicaidTrx: !exists(json, "medicaidTrx") ? undefined : json["medicaidTrx"],
    medicaidMarketShare: !exists(json, "medicaidMarketShare") ? undefined : json["medicaidMarketShare"],
    medicareTrx: !exists(json, "medicareTrx") ? undefined : json["medicareTrx"],
    medicareMarketShare: !exists(json, "medicareMarketShare") ? undefined : json["medicareMarketShare"],
    allChannelsTrx: !exists(json, "allChannelsTrx") ? undefined : json["allChannelsTrx"],
    allChannelsMarketShare: !exists(json, "allChannelsMarketShare") ? undefined : json["allChannelsMarketShare"],
  };
}

export function PrescribingHabitsToJSON(value?: PrescribingHabits | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    product: value.product,
    commercialTrx: value.commercialTrx,
    commercialMarketShare: value.commercialMarketShare,
    medicaidTrx: value.medicaidTrx,
    medicaidMarketShare: value.medicaidMarketShare,
    medicareTrx: value.medicareTrx,
    medicareMarketShare: value.medicareMarketShare,
    allChannelsTrx: value.allChannelsTrx,
    allChannelsMarketShare: value.allChannelsMarketShare,
  };
}
