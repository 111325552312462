/* eslint-disable react-hooks/exhaustive-deps */
import VisibilityIcon from "@material-ui/icons/Visibility";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { DataWindow, useDataWindows } from "../../api/useMetaDataApi";
import { usePayerLandscapeDetailDropdowns } from "../../api/usePayerLandscapeDetailApi";
import { ReactComponent as CompareIcon } from "../../assets/CompareIcon.svg";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import GeoSelect from "../../components/GeoSelect/GeoSelect";
import PageHeader from "../../components/Headers/PageHeader";
import MinimalSelect from "../../components/Inputs/MinimalSelect";
import Select from "../../components/Inputs/Select";
import { useModal } from "../../components/Modal/useModal";
import SearchBox from "../../components/SearchBox/SearchBox";
import { Tab, TabPanel, useTabs } from "../../components/Tabs";
import {
  useClientConfiguration,
  useClientIndicationFiltersconfiguration,
  useClientPayerLandscapeConfiguration,
} from "../../data/global/useProductConfiguration";
import { usePayerLandscapeDispatch, usePayerLandscapeState } from "../../data/payerLandscape/hooks";
import useGetRole, { FRM_ROLE } from "../../hooks/useGetRole";
import { useProducts } from "../../hooks/useProducts";
import useQueryParams from "../../hooks/useQueryParams";
import { arrayToOptions } from "../../utils/arrayToOptions";
import DetailFiltersModal from "./DetailFiltersModal";
import PayerLandscapeComparsionTab from "./PayerLandscapeComparisonTab";
import PayerLandscapeDetailTab from "./PayerLandscapeDetailTab";
import ProductComparisonFiltersModal from "./ProductComparisonFiltersModal";

enum TabNames {
  DETAIL_VIEW = "detailed-view",
  PRODUCT_COMPARISON = "product-comparison",
}

const PayerLandscape: React.FC = () => {
  const [t] = useTranslation();
  const { setModal } = useModal();
  const [initTab] = useQueryParams("tab", TabNames.DETAIL_VIEW);
  const tabs = useTabs(initTab);

  const { currentTab } = tabs;
  const { sharedFilters, detailFilters, detailFilterCount, comparisonFilterCount } = usePayerLandscapeState();

  const { updateSharedFilters, updateDetailFilters, updateComparisonFilters } = usePayerLandscapeDispatch();
  const { client: selectedClient } = useParams<{ client: string }>();
  const { productComparison: showproductComparison, indicationFiltering: showIndicationFiltering } =
    useClientPayerLandscapeConfiguration(selectedClient);
  const { products: allowedPlanInfoProducts } = useClientConfiguration(selectedClient);
  const { data: role } = useGetRole(selectedClient);
  const isFRM = String(role) === FRM_ROLE;
  const [search, setSearch] = useState<string>("");

  const dropdownQuery = usePayerLandscapeDetailDropdowns(selectedClient);
  const products = useProducts(selectedClient);
  const dropdowns = dropdownQuery.data;
  const allProducts = products.data || [];
  const planInfoProducts = allProducts.filter((product) => allowedPlanInfoProducts?.includes(product));
  const productOptions = arrayToOptions(planInfoProducts, false);
  const beneOptions = arrayToOptions(dropdowns?.benificiaryType, false, t("common.allChannels"));
  const advancedFilterCount = currentTab === TabNames.DETAIL_VIEW ? detailFilterCount : comparisonFilterCount;
  const { data: dataWindows } = useDataWindows(selectedClient);
  const timePeriodOptions = (dataWindows || []).map((dataWindow) => {
    return {
      label: t(`payerLandscape.timePeriodOptions.${dataWindow}`),
      value: dataWindow,
    };
  });
  const defaultDataWindow =
    (dataWindows || []).find((dataWindow) => dataWindow === DataWindow.twelveMonths) || DataWindow.twelveMonths;
  const showTimePeriodSelection = (dataWindows || []).length > 1 && currentTab === TabNames.DETAIL_VIEW;

  const indicationOptions = useClientIndicationFiltersconfiguration(selectedClient);

  //IF INDICATION SET, BUT SHOULDNT BE, SET TO UNDEFINED
  useEffect(() => {
    if (!showIndicationFiltering) {
      updateDetailFilters({ indication: undefined });
    }
  }, [updateDetailFilters, indicationOptions, detailFilters.indication, showIndicationFiltering]);

  //IF NO INDICATION SET, SET TO FIRST PRODUCT AVAILABLE
  useEffect(() => {
    if (
      indicationOptions &&
      showIndicationFiltering &&
      indicationOptions !== undefined &&
      indicationOptions.length > 0 &&
      detailFilters.indication === undefined
    ) {
      updateDetailFilters({ indication: indicationOptions[0].value });
    }
  }, [updateDetailFilters, indicationOptions, detailFilters.indication]);

  //IF NO PRODUCT SET, SET TO FIRST PRODUCT AVAILABLE
  useEffect(() => {
    if (planInfoProducts.length > 0 && detailFilters.product === "") {
      updateDetailFilters({ product: planInfoProducts[0] });
    }
  }, [updateDetailFilters, planInfoProducts, detailFilters.product]);

  //Set the default beneficiaryType to first option (Commerical)
  useEffect(() => {
    if (!sharedFilters.beneficiaryType && beneOptions.length > 1) {
      updateSharedFilters({ beneficiaryType: beneOptions[0].value });
    }
  }, [dropdowns?.benificiaryType]);

  //Set the default timePeriod to last 12 months
  useEffect(() => {
    if (!sharedFilters.timePeriod && (dataWindows || []).length > 0) {
      updateSharedFilters({ timePeriod: defaultDataWindow });
    }
  }, [dataWindows, defaultDataWindow]);

  //IF BENETYPE IS INVALID, SET TO FIRST VALID OPTION
  useEffect(() => {
    if (!dropdowns?.benificiaryType) {
      return;
    }
    if (
      !dropdowns?.benificiaryType?.includes(sharedFilters.beneficiaryType || "") &&
      dropdowns?.benificiaryType.length > 0
    ) {
      updateSharedFilters({
        beneficiaryType: dropdowns?.benificiaryType[0],
      });
    }
  }, [updateSharedFilters, beneOptions, sharedFilters.beneficiaryType, dropdowns?.benificiaryType]);

  //Debounce search and update filters
  useEffect(() => {
    const timeoutAmount = search === "" ? 0 : 400;
    const timeoutId = setTimeout(() => {
      updateSharedFilters({ planName: search });
    }, timeoutAmount);

    //If a new keystroke happens before timeout, clear the timeout
    return () => {
      clearTimeout(timeoutId);
    };
  }, [search, updateDetailFilters, updateComparisonFilters]);

  useEffect(() => {
    const newSearch = sharedFilters.planName ? sharedFilters.planName : "";
    setSearch(newSearch);
  }, [sharedFilters.planName]);

  const openFilterModal = () => {
    if (currentTab === TabNames.DETAIL_VIEW) {
      setModal(<DetailFiltersModal client={selectedClient} product={detailFilters.product} />);
    }
    if (currentTab === TabNames.PRODUCT_COMPARISON) {
      setModal(<ProductComparisonFiltersModal client={selectedClient} />);
    }
  };

  return (
    <>
      <PageHeader title={t("common.payerLandscape")}>
        <SearchBox value={search} handleChange={setSearch} />
      </PageHeader>

      <BreadCrumbs />

      <div>
        <div className="md:flex md:space-x-10  justify-between">
          <div
            className={
              currentTab === TabNames.DETAIL_VIEW && indicationOptions && showIndicationFiltering
                ? "flex justify-evenly lg:w-4/12"
                : "flex justify-evenly lg:w-4/12"
            }
          >
            {currentTab === TabNames.DETAIL_VIEW && (
              <MinimalSelect
                value={detailFilters.product}
                handleChange={(value) => updateDetailFilters({ product: value })}
                label={t("payerLandscape.planInfoFor")}
                options={productOptions}
              />
            )}
            {indicationOptions && showIndicationFiltering && (
              <MinimalSelect
                value={detailFilters.indication}
                handleChange={(value) => updateDetailFilters({ indication: value })}
                label={t("payerLandscape.indicationFor")}
                options={indicationOptions}
              />
            )}
          </div>

          {!isFRM && (
            <div className={`${!isFRM ? "lg:w-3/12" : "lg:w-6/12"} flex-grow`}>
              {showTimePeriodSelection ? (
                <Select
                  value={sharedFilters.timePeriod}
                  label={t("payerLandscape.timePeriod")}
                  options={timePeriodOptions}
                  handleChange={(value) => updateSharedFilters({ timePeriod: value })}
                />
              ) : null}
            </div>
          )}
          <div className={`${!isFRM ? "lg:w-7/12" : "lg:w-6/12"} flex-grow lg:flex-grow-0 md:flex md:space-x-10`}>
            <GeoSelect client={selectedClient} />
            <Select
              value={sharedFilters.beneficiaryType}
              handleChange={(value) => updateSharedFilters({ beneficiaryType: value })}
              label={t("payerLandscape.beneType")}
              options={beneOptions}
            />
          </div>
        </div>
        <div className="flex mb-4 md:justify-end">
          <button
            onClick={openFilterModal}
            className="text-primary w-full md:w-auto font-bold py-3 px-2 md:py-1 md:ml-0 md:-mr-2 rounded"
          >
            {t("common.advancedFilterWithCount", { count: advancedFilterCount })}
          </button>
        </div>

        {showproductComparison && !isFRM ? (
          <div className="flex sm:border-b border-rules">
            <Tab tabName={TabNames.DETAIL_VIEW} {...tabs}>
              <VisibilityIcon className="mr-1" /> {t("payerLandscape.detailedView")}
            </Tab>
            <Tab tabName={TabNames.PRODUCT_COMPARISON} {...tabs}>
              <CompareIcon className="mr-1 stroke-current" /> {t("payerLandscape.productComparison")}
            </Tab>
          </div>
        ) : null}
      </div>

      <TabPanel tabName={TabNames.DETAIL_VIEW} {...tabs}>
        <PayerLandscapeDetailTab key="payerLandscapeDetailTab" />
      </TabPanel>
      <TabPanel tabName={TabNames.PRODUCT_COMPARISON} {...tabs}>
        <PayerLandscapeComparsionTab key="payerLandscapeComparisonTab" />
      </TabPanel>
    </>
  );
};

export default PayerLandscape;
