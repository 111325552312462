import classNames from "classnames";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";
import { Range } from "./RangeInput";

interface Props {
  range: Range;
  setRange: (range: Range) => void;
}
const MinMaxInputs: React.FC<Props> = ({ range, setRange, ...inputProps }) => {
  const [t] = useTranslation();

  //Set focus on mount
  const inputRef = useRef<null | HTMLInputElement>(null);
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  const inputClasses = classNames(
    "w-full my-1 px-2 ml-2 focus:bg-white focus:shadow-inner border-light-gray border focus:border-rules  rounded-lg",
  );
  //Update the min or max when using between rangeType
  const updateMinMax = (newValue: { min?: number | null; max?: number | null }) => {
    if (Number.isNaN(newValue.min)) {
      newValue.min = undefined;
    }
    if (Number.isNaN(newValue.max)) {
      newValue.max = undefined;
    }
    setRange({ ...range, ...newValue });
  };

  return (
    <>
      <NumberFormat
        getInputRef={inputRef}
        value={range.min}
        aria-label={t("ranges.minAmount")}
        onValueChange={({ value }) => updateMinMax({ min: parseFloat(value) })}
        {...inputProps}
        type="text"
        className={inputClasses}
        placeholder={t("ranges.min")}
      />
      <span className="p-3.5 text-slate-gray text-sm">to</span>
      <NumberFormat
        value={range.max}
        aria-label={t("ranges.maxAmount")}
        onValueChange={({ value }) => updateMinMax({ max: parseFloat(value) })}
        {...inputProps}
        type="text"
        className={inputClasses}
        placeholder={t("ranges.max")}
      />
    </>
  );
};

export default MinMaxInputs;
