import { reducerWithInitialState } from "typescript-fsa-reducers";
import { updateGeoAccess } from "./actions";
import { GeoPayload, UserState } from "./types";

export const initialState: UserState = {
  hasDataAccess: true,
  regions: [],
  districts: [],
  territories: [],
};

//If user has no regions, districts, or territories, they do not have access to the data in the system
const doesUserHaveDataAccess = (geoPayload: GeoPayload) => {
  return geoPayload.regions.length !== 0 || geoPayload.districts.length !== 0 || geoPayload.territories.length !== 0;
};

const reducer = reducerWithInitialState(initialState).case(updateGeoAccess, (state, geoPayload) => ({
  ...state,
  hasDataAccess: doesUserHaveDataAccess(geoPayload),
  ...geoPayload,
}));

export default reducer;
