import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { UseInfiniteQueryResult } from "react-query";
import { usePrintHCPSpecificMessagingApi } from "../../api/usePrintHCPSpecificMessagingApi";
import ErrorDisplayTableRow from "../../components/ErrorDisplay/ErrorDisplayTableRow";
import NoResultsTableRow from "../../components/NoResults/NoResultsTableRow";
import SkeletonTableBody from "../../components/Skeletons/SkeletonTableBody";
import { usePrintMessages } from "../../data/messages/context";
import { HCPSpecificMessage } from "../../generate";
import HcpRow from "./HcpRow";

interface TableProps {
  client: string;
  query: UseInfiniteQueryResult<HCPSpecificMessage[] | null | undefined, Error>;
  selectedHcps: HCPSpecificMessage[];
  toggleHcp: (hcp: HCPSpecificMessage, isSelected: boolean) => void;
  isPrint: boolean;
}

//Determine what to render in the table body
const TableBody: React.FC<TableProps> = ({ client, query, selectedHcps, toggleHcp, isPrint }) => {
  const [{ expandedHCPProvider }, { setExpandedHCPProvider }] = usePrintMessages();
  if (query.isLoading) {
    return (
      <table>
        <SkeletonTableBody rowCount={8} columnCount={1} />
      </table>
    );
  }
  if (query.error) {
    return (
      <table>
        <ErrorDisplayTableRow columnCount={1} height={300} error={query.error} />;
      </table>
    );
  }

  return (
    <>
      {query.data &&
        query.data.pages &&
        query.data.pages.map((page: HCPSpecificMessage[] | null | undefined, pageIndex: number) => {
          if (!page) {
            return null;
          }
          return page.map((hcp, rowIndex) => (
            <HcpRow
              key={hcp.prescID}
              client={client}
              index={rowIndex}
              expandedRow={expandedHCPProvider}
              setExpandedRow={setExpandedHCPProvider}
              hcp={hcp}
              selectedHcps={selectedHcps}
              toggleHcp={toggleHcp}
              isPrint={isPrint}
            />
          ));
        })}
    </>
  );
};

const HcpList: React.FC<{ client: string; isPrint?: boolean; footnote?: string }> = ({
  client,
  isPrint = true,
  footnote,
}) => {
  const [{ selectedHCPSpecificMessages }, { setHCPSpecificMessages }] = usePrintMessages();
  const query = usePrintHCPSpecificMessagingApi(client, isPrint);

  const { t, i18n } = useTranslation();
  const loadMoreRef = useRef<HTMLButtonElement | null>(null);

  //Toggle whether the HCP is selected or not
  const toggleHcp = (hcp: HCPSpecificMessage, isSelected: boolean) => {
    if (!isSelected) {
      setHCPSpecificMessages((hcps) => [...hcps, hcp]);
    } else {
      setHCPSpecificMessages((selectedHcps) =>
        selectedHcps.filter((selectedHcp) => selectedHcp.prescID !== hcp.prescID),
      );
    }
  };

  const rect = loadMoreRef.current?.getBoundingClientRect();
  if (rect && rect?.bottom <= window.innerHeight + 1000 && query.hasNextPage) {
    query.fetchNextPage();
  }

  const noResults = query.data && query.data.pages && query.data.pages === null;

  return (
    <div className="pb-6">
      <div className="bg-rules px-3 py-4 flex">
        <div className="w-10 sm:w-24"></div>
        <div className="flex-grow pl-2">
          {i18n.exists(`specificMessages.hcps.${client}`)
            ? t(`specificMessages.hcps.${client}`)
            : t(`specificMessages.hcps.default`)}
        </div>
      </div>
      {noResults ? (
        <table>
          <NoResultsTableRow columnCount={1} height={300} />;
        </table>
      ) : (
        <TableBody
          client={client}
          query={query}
          selectedHcps={selectedHCPSpecificMessages}
          toggleHcp={toggleHcp}
          isPrint={isPrint}
        />
      )}
      {query.data && (
        <div className="pt-10 pb-10 flex items-center justify-center w-full">
          <button
            ref={loadMoreRef}
            onClick={() => query.fetchNextPage()}
            disabled={!query.hasNextPage || query.isFetchingNextPage}
          >
            {query.isFetchingNextPage
              ? t("common.loadingMore")
              : query.hasNextPage
              ? t("common.loadMore")
              : !query.isError && !noResults
              ? t("common.nothingMoreToLoad")
              : null}
          </button>
        </div>
      )}
      {footnote && <p className="text-xs text-slate-gray whitespace-pre-line leading-6 px-12 pb-12">{footnote}</p>}
    </div>
  );
};

export default HcpList;
