export enum GeographyType {
  National = "National",
  Region = "Region",
  District = "District",
  Territory = "Territory",
}

export interface TreeData {
  name: string;
  hasAccess: boolean;
  type: GeographyType;
  children?: TreeData[];
}
