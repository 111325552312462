/* tslint:disable */
/* eslint-disable */
/**
 * Milliman.PullThroughPortal.WebAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface PrintDoc
 */
export interface PrintDoc {
  /**
   *
   * @type {string}
   * @memberof PrintDoc
   */
  client: string;
  /**
   *
   * @type {string}
   * @memberof PrintDoc
   */
  baseDocName?: string | null;
  /**
   *
   * @type {string}
   * @memberof PrintDoc
   */
  prescID?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof PrintDoc
   */
  isPrint?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PrintDoc
   */
  includePI?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PrintDoc
   */
  isResource?: boolean;
}

export function PrintDocFromJSON(json: any): PrintDoc {
  return PrintDocFromJSONTyped(json, false);
}

export function PrintDocFromJSONTyped(json: any, ignoreDiscriminator: boolean): PrintDoc {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    client: json["client"],
    baseDocName: !exists(json, "baseDocName") ? undefined : json["baseDocName"],
    prescID: !exists(json, "prescID") ? undefined : json["prescID"],
    isPrint: !exists(json, "isPrint") ? undefined : json["isPrint"],
    includePI: !exists(json, "includePI") ? undefined : json["includePI"],
    isResource: !exists(json, "isResource") ? undefined : json["isResource"],
  };
}

export function PrintDocToJSON(value?: PrintDoc | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    client: value.client,
    baseDocName: value.baseDocName,
    prescID: value.prescID,
    isPrint: value.isPrint,
    includePI: value.includePI,
    isResource: value.isResource,
  };
}
