import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { UseQueryResult } from "react-query";
import { ResourceLibraryApiResponse } from "../../api/useResourceLibrary";
import ErrorDisplay from "../../components/ErrorDisplay/ErrorDisplay";
import NoResultsFound from "../../components/NoResults/NoResultsFound";
import { ResourceLibrary } from "../../generate";
import ResourceCard from "./ResourceCard";
import ResourceCardSkeleton from "./ResourceCardSkeleton";

interface FuseItem {
  item: ResourceLibrary;
  refIndex?: number;
  score?: number;
}

interface Props {
  client: string;
  query: UseQueryResult<ResourceLibraryApiResponse, Error>;
  filteredData: FuseItem[];
}

const ResourceGrid: React.FC<Props> = ({ client, query, filteredData }) => {
  const [t] = useTranslation();
  const noResults = filteredData && filteredData.length === 0;

  const gridClasses = classNames(
    "w-full pt-6 grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-x-12 gap-y-16 pb-6",
  );

  //Loading State
  if (query.isLoading) {
    const array = Array.from({ length: 10 }, (x, i) => i);
    return (
      <div>
        <div aria-busy="true" aria-live="polite" className={gridClasses}>
          {array.map((i) => (
            <ResourceCardSkeleton key={i} />
          ))}
        </div>
        <div className="text-center">{t("common.loading")}</div>
      </div>
    );
  }

  //Error State
  if (query.isError) {
    return (
      <div className="py-12">
        <ErrorDisplay error={query.error} />
      </div>
    );
  }

  //No results state
  if (noResults) {
    return (
      <div className="py-12">
        <NoResultsFound />
      </div>
    );
  }

  //Normal State
  return (
    <div className={gridClasses}>
      {filteredData &&
        filteredData.map((resource, rowIndex) => (
          <div key={rowIndex}>
            <ResourceCard key={resource.item.pdf_name} client={client} resource={resource.item} />
          </div>
        ))}
    </div>
  );
};

export default ResourceGrid;
export {};
