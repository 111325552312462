import React from "react";

interface Props {
  label: string;
  value: string;
}

const UserInfo: React.FC<Props> = ({ label, value }) => {
  return (
    <div>
      <p className="text-xs text-slate-gray">{label}</p>
      <p className="bg-light-gray py-2.5 px-3 mt-1 h-10">{value}</p>
    </div>
  );
};

export default UserInfo;
