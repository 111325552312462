import React from "react";
import { useTranslation } from "react-i18next";

const HowTo: React.FC = () => {
  const [t] = useTranslation();
  return (
    <div className="text-white text-center">
      <h1 className="text-3xl font-bold mb-8">{t("siteUnderMaintenance.header")}</h1>
      <p>{t("siteUnderMaintenance.description")}</p>
    </div>
  );
};

export default HowTo;
