import { reducerWithInitialState } from "typescript-fsa-reducers";
import { RangeType } from "../../components/Inputs/RangeInput";
import { SortDirection } from "../../generate";
import { booleanArrayToCount } from "../../utils/booleanArrayToCount";
import { updateSharedFilters } from "../payerLandscape/actions";
import { clearAdvancedFilters, updateFilters, updateSort } from "./actions";
import { FiltersWithRangeTypes, HcpLandscapeState } from "./types";

const filters = {
  genericFilter: "",
  region: "",
  timePeriod: "",
};

const advancedFilters = {
  name: "",
  marketTrxMin: undefined,
  marketTrxMax: undefined,
  marketRangeType: RangeType.GE,
  trxMin: undefined,
  trxMax: undefined,
  trxRangeType: RangeType.GE,
  favorableAccessPercentMin: undefined,
  favorableAccessPercentMax: undefined,
  favorableAccessPercentRangeType: RangeType.GE,
  favorableAccessChangePercentMin: undefined,
  favorableAccessChangePercentMax: undefined,
  favorableAccessChangePercentRangeType: RangeType.GE,
  overallAccessPercentMin: undefined,
  overallAccessPercentMax: undefined,
  overallAccessPercentRangeType: RangeType.GE,
  productShareMin: undefined,
  productShareMax: undefined,
  productShareRangeType: RangeType.GE,
};

const allFilters: FiltersWithRangeTypes = { ...filters, ...advancedFilters };

export const initialState: HcpLandscapeState = {
  filters: allFilters,
  advancedFilterCount: 0,
  sort: { field: "full_name", direction: SortDirection.Ascending },
};

const getAdvancedFilterCount = (f: FiltersWithRangeTypes) => {
  return booleanArrayToCount([
    f.name,
    f.marketTrxMin || f.marketTrxMax,
    f.trxMin || f.trxMax,
    f.favorableAccessPercentMin || f.favorableAccessPercentMax,
    f.favorableAccessChangePercentMin || f.favorableAccessChangePercentMax,
    f.overallAccessPercentMin || f.overallAccessPercentMax,
    f.productShareMin || f.productShareMax,
  ]);
};

const reducer = reducerWithInitialState(initialState)
  .case(updateSharedFilters, (state, { timePeriod }) => ({
    ...state,
    filters: { ...state.filters, timePeriod },
    advancedFilterCount: getAdvancedFilterCount({ ...state.filters, timePeriod }),
  }))
  .case(updateFilters, (state, filters) => ({
    ...state,
    filters: { ...state.filters, ...filters },
    advancedFilterCount: getAdvancedFilterCount({ ...state.filters, ...filters }),
  }))
  .case(clearAdvancedFilters, (state) => {
    //Clear filters but reserve the rangeTypes
    return {
      ...state,
      filters: {
        ...state.filters,
        ...advancedFilters,
        marketRangeType: state.filters.marketRangeType,
        trxRangeType: state.filters.trxRangeType,
        favorableAccessPercentRangeType: state.filters.favorableAccessPercentRangeType,
        favorableAccessChangePercentRangeType: state.filters.favorableAccessChangePercentRangeType,
        overallAccessPercentRangeType: state.filters.overallAccessPercentRangeType,
        productShareRangeType: state.filters.productShareRangeType,
      },
      advancedFilterCount: 0,
    };
  })
  .case(updateSort, (state, sort) => {
    return { ...state, sort };
  });

export default reducer;
