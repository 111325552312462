/* eslint-disable @typescript-eslint/no-empty-function */
import React from "react";
import { GeographyType } from "./GeoTypes";

interface GeoState {
  expanded: string[];
}

interface Props {
  geoState: GeoState;
  search: string;
  toggleExpanded: (name: string, type: GeographyType) => void;
}

const GeoContext = React.createContext<Props>({
  geoState: { expanded: [""] },
  search: "",
  toggleExpanded: () => {},
});

export default GeoContext;
